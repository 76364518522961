import React, { Component } from 'react'
import images from '../ImagesUrl/imagesUri';


export default class MultipleMatches extends Component {
    
    render() {
        let { matchData } = this.props;
        if(!matchData) {
            return null;
        }

        return (
            <>
            <div className="col-md-8 m-p-0">
                <div className="row">
                    {matchData.map((value, id) => {
                    const matchData = value.match ;
                        // console.log("MatchData map",value);
                        
                        let possibleWinner = (matchData)? matchData.possible_winner.player_name.split(' ') : [];
                        possibleWinner = (possibleWinner.length > 0)? `${possibleWinner[0].charAt(0)}. ${possibleWinner[1] }` : "";
                        let rate = (matchData)?matchData.tip_rate:""; 
                        let playerOneName = (matchData)? matchData.player_one.player_name.split(' ') : [];
                        let playerTwoName = (matchData)? matchData.player_two.player_name.split(' ') : [];
                        playerOneName = (playerOneName.length > 0)?`${playerOneName[0].trim().charAt(0)}. ${playerOneName[1] }` : "";
                        playerTwoName = (playerTwoName.length > 0)?`${playerTwoName[0].trim().charAt(0)}. ${playerTwoName[1] }` : ""; 
                        let winning_chances = (matchData)?matchData.winning_chances:""; 
                    
                    return(<div className="col-md-6 p-0" key={id}>
                                <div className="form-group col-md-12 form-custom ipad-pr-0 ipad-mb-20" >
                                    <div className="blue_tag_btn matches_dropdown">
                                        <div className="row m-0">
                                            <div className="col-md-8 bidder-name p-0 col-8">
                                                <h6>{possibleWinner}</h6>
                                                <p className="m-0 black_color" > Winner of the match @ {rate}</p>
                                                <div>
                                                    <p className="m-0 black_color" >{playerOneName} VS {playerTwoName} </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 circules_section col-4 pr-0">
                                                <ul className="pr-0 list-unstyled ">
                                                    <li className={(winning_chances == 1 ) ? "red active" : "red" } ></li>
                                                    <li className={(winning_chances == 2  ) ? "yellow active" : "yellow" } ></li>
                                                    <li className={(winning_chances == 3 ) ? "green active" : "green" }  ></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })}  
                </div> 
            </div>
            </>
        )
    }
}
