const Styles = {

  colourStyles: {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#4aa546" : null,
        color: isFocused ? "#ffffff" : "#000000",
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? "#4aa546"  : null ),
        },
      };
    }
  },

  groupStyles :{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  
  groupBadgeStyles: {
    backgroundColor: '#4aa546',
    borderRadius: '2em',
    color: '#ffffff',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  },

  menuPortal:{
    menuPortal: base => ({ ...base, zIndex: 9999 }) 
  }
}   

export default Styles;
