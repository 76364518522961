import Request from '../Helpers/API';

export const getAllPlayers = () => {
  
    const apiUrl = `admin/get-players`;
    
    const req = new Request(apiUrl);
    
    return req.get();
};

export const getPlayersList = (params) => {
  
    const apiUrl = `admin/players-list`;
    
    const req = new Request(apiUrl, params);
    
    return req.post();
};

export const createPlayer = (params) =>{
    let formdata = new FormData();
    const apiUrl = `admin/create-player`;

    Object.keys(params).forEach((e) => {
        formdata.append(e,params[e] || '');
    });
    
    const req = new Request(apiUrl, formdata);
    return req.post();
}


export const updatePlayer = (params, id) => {
    let formdata = new FormData();
    const apiUrl = `admin/update-player/${id}`;

    Object.keys(params).forEach((e) => {
        formdata.append(e,params[e] || '');
    });

    // Object.keys(params).forEach((e) => {
    //     if(e === "playerImageUrl"){
    //         console.log(e);
    //         params[e].map((ele)=>{
    //         console.log(ele);
    //            formdata.append(e, ele || '');
    //         });
    //     } else {
    //         formdata.append(e,params[e] || '');
    //     }
    // });
    
    const req = new Request(apiUrl, formdata);
    return req.put();
}

export const deletePlayer = (id) => {
  
    const apiUrl = `admin/player-soft-delete/${id}`;
    
    const req = new Request(apiUrl);
    
    return req.patch();
};

export const getPlayerDetails = (playerId) => {
  
    const apiUrl = `admin/player-details/${playerId}`;
    
    const req = new Request(apiUrl);
    
    return req.get();
};