import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getTime, extractDate, concatDateTime, extractTime,convertDateFormat} from '../../Helpers/TimeConversion';
import { FILE_URL } from '../../Helpers/Config.js';

export default class AvtarTextFeed extends Component {
    
    render() {
        const { matchData } = this.props;
        if(!matchData) {
            return null;
        }
        const matchInfo = (matchData.match_info.length < 80)? matchData.match_info : matchData.match_info.substr(0,85);
        let playerOneName = (matchData.player_one)? matchData.player_one.player_name.split(' ') : [];
        let playerTwoName = (matchData.player_two)? matchData.player_two.player_name.split(' ') : [];
        // const avtarOne =  `${playerOneName && playerOneName[0].trim().charAt(0)}  ${playerOneName[1].charAt(0)}` ;
        // const avtarTwo = `${playerTwoName[0].trim().charAt(0)}  ${playerTwoName[1].charAt(0)}`;
        let avtarOne ="";
        let avtarTwo ="";
        // playerOneName = `${playerOneName[0].trim().charAt(0)}. ${playerOneName[1] }`;
        // playerTwoName = `${playerTwoName[0].trim().charAt(0)}. ${playerTwoName[1] }`;
        const readMore = matchData.match_info.length > 85 ? <Link to= {{pathname: "edit-match", state: matchData }} style={{fontSize:'14px'}}>read more...</Link> : "";
        let date = extractTime(matchData.date_and_time,"LLL");
        date = date.split(',');
        const matchDate = date[0]; 
        let matchTime = extractTime(matchData.date_and_time,"hh:mm A")

        return (
            <>
                <div className="col-md-12 col-12">
                    <div className="row pt-3 news_third_part news_third_part_five">
                        <div className="col-md-5 p-0 col-5 text-center"> 
                            {(matchData.player_one.player_image_url) ?
                            //  <img src = { matchData.player_one.player_image_url } className="border-10 width_height_100"/> 
                            <img src = { `${matchData.player_one.player_image_url}` } className="c-avatar-img"/> 
                             : <span className="avtar_text"> {avtarOne} </span>
                            }
                        </div>
                        <div className="col-md-2 p-0 text-center d-flex align-center justify-center col-2">
                            <p className="m-0 vs">VS</p>
                        </div>
                        <div className="col-md-5 p-0 ml-auto col-5 text-center">
                            { (matchData.player_two.player_image_url) ?
                                // <img src = { matchData.player_two.player_image_url } className="border-10 width_height_100"/>
                                <img src = {`${matchData.player_two.player_image_url}` } className="c-avatar-img"/>
                                : <span className="avtar_text"> {avtarTwo} </span>
                            }
                        </div>
                    </div>
                    <div className="row winner_name">
                        <div className="col-md-5 p-0 text-center col-5"> { playerOneName } </div>
                        <div className="col-md-2 p-0 text-center col-2"> </div>
                        <div className="col-md-5 p-0 text-center col-5">{ playerTwoName}</div>
                    </div>
                        <p className="row mb-0 mt-2">
                            <span style={{color:'#4aa546'}}> {matchDate} at {matchTime} </span> 
                        </p>
                    <div className="match-type pt-2 new_second_part_two row">
                        <p className="m-0">
                            {/* { matchData.display_on && 
                                (matchData.display_on=="1")?"Single"
                                :(matchData.display_on=="2")?"Lotry"
                                :(matchData.display_on=="3")?"Free"
                                :(matchData.display_on=="4")?"Single/Lottery"
                                :(matchData.display_on=="5")?"Single/Free"
                                :(matchData.display_on=="6")?"Lottery/Free"
                                :""
                    
                            }  */}
                            { matchData.display_on && 
                                (() => {
                                    switch (matchData.display_on) {
                                            case "1": return "Single";
                                            case "2": return "Lottery";
                                            case "3": return "Free";
                                            case "4": return "Single/Free";
                                            case "5": return "Lottery/Free";
                                            default: return "";
                                        }
                                })()
                            }
                        </p> 
                    </div>
                    <div className="new_second_part pt-2 new_second_part_two row">
                        <p className="m-0">{ matchInfo } {readMore}</p> 
                    </div>
                </div>
            </>
        )
    }
}

// export default class AvtarTextFeed extends Component {
    
//     render() {
//         const { matchData } = this.props;
//         if(matchData == undefined || matchData ==null) {
//             return null
//         }
//         let matchInfo = (matchData.match_info.length < 100)? matchData.match_info : matchData.match_info.substr(0,100)+'...';
//         let playerImages = matchData.player_images;
//         let playerOneName = (matchData.player_one) ? matchData.player_one.player_name.split(',')[1] : "";
//         let playerOneLastName = (matchData.player_one) ? matchData.player_one.player_name.split(',')[0] : "";
//         let playerTwoName = (matchData.player_two) ? matchData.player_two.player_name.split(',')[1] : "";
//         let playerTwoLastName = (matchData.player_one) ? matchData.player_two.player_name.split(',')[0] : "";
//         // console.log("playerName --->",playerOneName ,playerOneLastName,"playerTwoName---->",playerTwoName,playerTwoLastName);
//         const avtarOne =  `${playerOneName.charAt(1)}  ${ playerOneLastName.charAt(0)}`;
//         const avtarTwo = `${playerTwoName.charAt(1)}  ${ playerTwoLastName.charAt(0)}`;
//         // console.log("Avatar",avtarOne,avtarTwo); 
    
//         return (
//             <>
//             <div className="col-md-12">
//                 <div className="row pt-3 news_third_part news_third_part_five">
//                     <div className="col p-0">
//                         {playerImages.length >0 ?
//                         <img src={playerImages[0]} className="border-10 width_height_100"/>: <span className="avtar_text">{avtarOne}</span>
//                         }
//                     </div>
//                     <div className="col p-0 ml-auto">
//                           {playerImages.length >0 ?
//                         <img src={playerImages[1]} className="border-10 width_height_100"/>:
//                           <span className="avtar_text">{avtarTwo}</span>
//                         }
//                     </div>
//                 </div>
//                 <div className="row winner_name">
//                     <div className="col-md-6 p-0 text-center"> { playerOneName } </div>
//                     <div className="col-md-6 p-0 ml-auto text-center">{ playerTwoName}</div>
//                 </div>
//                 <div className="new_second_part pt-3 new_second_part_two">
//                     <p className="m-0"> { matchInfo } </p>
//                 </div>
//             </div>
//             </>
//         )
//     }
// }
