import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link } from "react-router-dom";
import * as _ from 'lodash';
import Select from 'react-select';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import InputError from "../components/Error/InputError";
import images from "../components/ImagesUrl/imagesUri"
import Rules from "../Helpers/FormRules";
import FormValidator from "../Helpers/FormValidator";
import { errorToast, successToast } from '../components/Toast/Toast.js';
import { createPlayer, updatePlayer, getPlayerDetails } from '../Actions/players.js';
import { getAllCountries } from '../Actions/countries.js';
import Loader from '../components/Loader/Loader.jsx';
import Styles from "../Helpers/Select-Styles.js";
import { FILE_URL } from '../Helpers/Config.js';

const defaultFormFields = {
    playerName: '',
    country: '',
    countryCode: '',
    playerImageUrl: null,
}

const defaultCrop = {
    unit: 'px',
    width: 300,
    aspect: 1 / 1
};

const CreatePlayer = (props) => {
    // let imageRef = null;
    const imgRef = useRef(null);
    const validator = new FormValidator(Rules.createPlayer);
    const [validation, setValidation] = useState(validator.valid());
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormdata] = useState({ ...defaultFormFields });
    const [countries, setCountries] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [playerId, setPlayerId] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [isShowPlayerOnePlaceholder, setIsShowPlayerOnePlaceholder] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [blobData, setBlobData] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [src, setSrc] = useState(null);
    const [uploadImageValidation, setUploadImageValidation] = useState(false);
    const [deletePlayerImageUrl, setDeletePlayerImageUrl] = useState(null);
    const [crop, setCrop] = useState({ ...defaultCrop });
    const [completedCrop, setCompletedCrop] = useState(null);


    useEffect(() => {
        function getPlayerData(playerId) {
            getPlayerDetails(playerId).then((response) => {
                let { data } = response.data.success;
                if (!_.isEmpty(data)) {
                    setFormdata({
                        ...formData,
                        playerName: data["player_name"],
                        country: data["country"],
                        countryCode: data["country_code"],
                        playerImageUrl: data["player_image_url"],
                    });

                    if (data["player_image_url"]) {
                        setSelectedImages([data["player_image_url"]]);
                    }
                }
            }).catch((error) => {
                if (error.response) {
                    const errorResult = error.response.data.error;
                    errorToast(errorResult.message);
                }
            });
        }

        function getCountries() {
            getAllCountries().then((response) => {
                let { data } = response.data.success;
                if (!_.isEmpty(data)) {
                    data = data.map((val) => {
                        return {
                            value: val["name"],
                            label: val["name"],
                            country_code: val["country_code"]
                        }
                    });
                    setCountries([...data]);
                }
            }).catch((error) => {
                if (error.response) {
                    const errorResult = error.response.data.error;
                    errorToast(errorResult.message);
                }
            });
        }

        if (props && props.location.id) {
            setIsEdit(true);
            setPlayerId(props.location.id);
            getPlayerData(props.location.id);
        }

        getCountries();
    }, []);

    useEffect(() => {
        if (submitted) {
            setValidation(validator.validate(formData));
        }
    }, [submitted]);

    useEffect(() => {
        if (submitted) {
            setValidation(validator.validate(formData));
        }
    }, [submitted, formData["playerName"], formData["country"]]);

    useEffect(() => {
        if (!completedCrop || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = document.createElement('canvas');
        // const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        canvas.toBlob(blob => {
            if (!blob) {
                return;
            }
            blob.name = "newFile.jpeg";
            const fileUrl = window.URL.createObjectURL(blob);
            console.log("-=-=-=-", blob, fileUrl);
            setBlobData(blob);
            setCroppedImageUrl(fileUrl);
        }, 'image/*');

    }, [completedCrop]);

    // const onImageLoaded = image => {
    //     imageRef = image;
    //     setUploadImageValidation(false);
    // };

    // const onCropComplete = crop => {
    //     makeClientCrop(crop);
    // };

    // const onCropChange = (crop, percentCrop) => {
    //     setCrop({ ...crop });
    // };

    // const makeClientCrop = async (crop) => {
    //     if (imageRef && crop.width && crop.height) {
    //         const response = await getCroppedImg(
    //             imageRef,
    //             crop,
    //             'newFile.jpeg'
    //         );
    //         setBlobData(response.blob);
    //         setCroppedImageUrl(response.croppedImageUrl);
    //     }
    // }

    // const getCroppedImg = (image, crop, fileName) => {
    //     const canvas = document.createElement('canvas');
    //     const scaleX = image.naturalWidth / image.width;
    //     const scaleY = image.naturalHeight / image.height;
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;
    //     const ctx = canvas.getContext('2d');

    //     ctx.drawImage(
    //         image,
    //         crop.x * scaleX,
    //         crop.y * scaleY,
    //         crop.width * scaleX,
    //         crop.height * scaleY,
    //         0,
    //         0,
    //         crop.width,
    //         crop.height
    //     );

    //     return new Promise((resolve, reject) => {
    //         canvas.toBlob(blob => {
    //             if (!blob) {
    //                 return;
    //             }
    //             blob.name = fileName;
    //             const fileUrl = window.URL.createObjectURL(blob)
    //             resolve({blob: blob, croppedImageUrl: fileUrl});
    //         }, 'image/*');
    //     });
    // }

    const openModal = (e) => {
        e.preventDefault();
        document.body.style.overflow = 'hidden';
        if (selectedImages.length < 1) {
            setIsModalOpen(true);
        } else {
            e.preventDefault();
        }
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setSrc(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    // const onSelectFile = (e) => {
    //     e.preventDefault();
    //     const files = e.target.files;
    //     if(files && files.length > 0 && files[0].size <= 2000000){
    //         // this.setState({ src: null },()=>{
    //             if (!(files[0].type == "image/gif" )) {
    //                 const reader = new FileReader();
    //                 reader.addEventListener('load', () =>{
    //                     console.log("-=-=-=", reader.result);
    //                     setSrc(reader.result);
    //                     setUploadImageValidation(false);
    //                     setCrop({...{  
    //                         unit: 'px',
    //                         width: 600,
    //                         height: 500,
    //                         aspect: 16/9,
    //                         }
    //                     });

    //                 });
    //                 reader.readAsDataURL(files[0]);
    //             }
    //         // });    
    //     } else if(files && files.length > 0){
    //         setUploadImageValidation(true);
    //     }   
    // };

    const removeImage = () => {
        if (playerId) {
            if (typeof formData["playerImageUrl"] == "string") {
                setDeletePlayerImageUrl(formData["playerImageUrl"]);
            }
            setSelectedImages([]);
            setFormdata({ ...formData, playerImageUrl: "" });
        } else {
            setSelectedImages([]);
            setFormdata({ ...formData, playerImageUrl: "" });
        }
    }

    const doneImage = (e) => {
        e.preventDefault();
        document.body.style.overflow = 'unset';
        if (src && blobData) {
            const newImage = new File([blobData], blobData.name, { type: blobData.type });
            setSelectedImages([croppedImageUrl]);
            setIsModalOpen(false);
            setSrc(null);
            setBlobData(null);
            setFormdata({ ...formData, playerImageUrl: newImage });
        } else {
            e.preventDefault();
        }
    }

    const onSubmit = e => {
        e.preventDefault();
        setSubmitted(true);
        const checkValidation = validator.validate(formData);
        if (checkValidation.isValid) {
            setLoading(true);
            const params = {
                "playerName": formData["playerName"].trim(),
                "country": formData["country"],
                "countryCode": formData["countryCode"],
                "playerImageUrl": formData["playerImageUrl"],
                "countryFlagUrl": null,
            };

            if (!isEdit) {
                createPlayer(params).then((response) => {
                    const { data, message } = response.data.success;
                    if (message) {
                        successToast(message);
                    }
                    setTimeout(() => {
                        props.history.push("/admin/players/player-list");
                    }, 1000);
                    setLoading(false);
                }).catch((error) => {
                    if (error.response) {
                        const errorResult = error.response.data.error;
                        errorToast(errorResult.message);
                    }
                    setLoading(false);
                });
            } else {
                params["deletePlayerImageUrl"] = deletePlayerImageUrl;
                updatePlayer(params, playerId).then((response) => {
                    const { message } = response.data.success;
                    if (message) {
                        successToast(message);
                    }
                    setTimeout(() => {
                        props.history.push("/admin/players/player-list");
                    }, 1000);
                    setLoading(false);
                }).catch((error) => {
                    if (error.response) {
                        const errorResult = error.response.data.error;
                        errorToast(errorResult.message);
                    }
                    setLoading(false);
                });
            }
        }
    }

    const onSelect = (newValue, actionMeta) => {
        const { name } = actionMeta;
        const value = (newValue) ? newValue.value : "";
        setFormdata({ ...formData, [name]: value, countryCode: newValue.country_code });
    };

    return (
        <div className="agenda_session_form_section agenda_padding">
            <div style={{ height: '94vh', overflow: "auto" }} className="mobile-scrollbar-height" >
                <div className="col-md-12 p-0 m-pt-15">
                    <form className="col-md-12 p-0" method="POST">
                        <div className="row m-0">
                            <div className="col-md-6 p-0">
                                <div className="form-group col-md-12 form-custom">
                                    <div className="row mb-3">
                                        <label htmlFor="inputEmail4" className="col left_label m-0 col-4">Player Name</label>
                                        {/* <label htmlFor="inputEmail4" className="col text-right right_label m-0 col-8">{formData.playerName.length} of 20 Characters</label> */}
                                    </div>
                                    <textarea className="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="1"
                                        onChange={(e) => { setFormdata({ ...formData, playerName: (e.target.value === " ") ? "" : e.target.value }) }}
                                        name="playerName"
                                        value={formData.playerName}
                                        placeholder="Enter Player Name"
                                        autoFocus
                                    >
                                    </textarea>
                                    <InputError show={validation.playerName.isInvalid} message={validation.playerName.message} />
                                </div>

                                <div className="form-group col-md-12 form-custom m-0 custom-height">
                                    <label htmlFor="inputState" className="left_label mb-3 m-mb-10">Country</label>
                                    <Select
                                        isClearable={false}
                                        onChange={onSelect}
                                        options={countries}
                                        placeholder={(isShowPlayerOnePlaceholder) ? "Select Country" : ""}
                                        name="country"
                                        tabIndex="3"
                                        value={_.find(countries, (ele) => { return ele.value == formData.country })}
                                        // onMenuOpen = {this.onMenuOpen.bind(this,"player_one")}
                                        // onMenuClose = {this.onMenuClose.bind(this,"player_one")}
                                        styles={Styles.colourStyles}
                                        components={{ DropdownIndicator: () => null }}
                                        isOptionDisabled={(option) => option.value === formData.country}
                                    />
                                    <InputError show={validation.country.isInvalid} message={validation.country.message} />
                                </div>
                            </div>

                            <div className="col-md-6 p-0">
                                <div className="form-group col-md-12 form-custom post-image">
                                    <div className="row mb-3">
                                        <label htmlFor="inputEmail4" className="col left_label m-0">Image </label>
                                        <label htmlFor="inputEmail4" className="col text-right right_label m-0">
                                            <Link
                                                to="#"
                                                onClick={(selectedImages.length < 1) ? openModal : null}
                                                style={{ cursor: (selectedImages.length < 1) ? "pointer" : "not-allowed" }}
                                            >
                                                Upload
                                            </Link>
                                        </label>

                                    </div>

                                    <div className="col-md-12 ">
                                        <div className="row">
                                            {selectedImages.length > 0 && (
                                                selectedImages.map((image, index) => {
                                                    return (
                                                        <div className="relative col-md-11 upload-img-div" key={index}>
                                                            <img
                                                                alt="Crop"
                                                                src={`${image}`}
                                                                className="post_square_img"
                                                                style={{
                                                                    borderRadius: '10px',
                                                                    width: "60%",
                                                                    // height: '204px',
                                                                    // maxWidth: '100%'
                                                                }}
                                                            />
                                                            <img
                                                                src={images.crossIcon}
                                                                className="player_cancel"
                                                                key={index}
                                                                id={index + 1}
                                                                onClick={removeImage}
                                                                alt="cross"
                                                            />
                                                        </div>
                                                    )
                                                })
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group col-md-12 form-custom" >
                                <div className="row">
                                    <div className="col-md-3 col-6 m-pr-7">
                                        <button className="btn common_btn blue_btn big_btn fixed_width_btn"
                                            type="submit"
                                            onClick={onSubmit}
                                        // disabled = {dissabled}
                                        // style={{ cursor:(dissabled)?"pointer":"not-allowed" }}
                                        >
                                            {isEdit == true ? "Update" : "Save"}
                                        </button>
                                    </div>
                                    <div className="col-md-3 col-6 m-pl-7">
                                        <button className="btn common_btn gray_btn big_btn fixed_width_btn "
                                            role="button"
                                            type="button"
                                            onClick={() => {
                                                props.history.push("/admin/players/player-list");
                                            }
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                contentLabel="Upload Image"
                ariaHideApp={false}
            >
                <form className="col-md-12 p-0 modal_from">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="modal_heading">Upload Image</h2>
                        </div>
                        <div className="form-group col-md-12 form-custom">
                            <div className="col-md-12 upload-btn-wrapper p-0">
                                <button className="btn import-btn" > Choose Image </button>
                                <input type="file" name="myfile" accept="image/*" onChange={onSelectFile} />
                            </div>
                            {src && (
                                // <ReactCrop
                                //     src={src}
                                //     crop={crop}
                                //     ruleOfThirds = { true }
                                //     onImageLoaded={onImageLoaded}
                                //     onComplete={onCropComplete}
                                //     onChange={onCropChange}
                                //     locked ={true}
                                // />
                                <ReactCrop
                                    src={src}
                                    onImageLoaded={onLoad}
                                    crop={crop}
                                    onChange={(c) => setCrop(c)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                />

                            )}
                            <InputError show={uploadImageValidation} message={"Upload image size should not exceed 2 MB"} />
                        </div>
                        <div className="col-md-6 m-mb-15">
                            <button
                                className="btn blue_btn common_btn big_btn"
                                onClick={doneImage}
                                style={{ cursor: (src) ? "pointer" : "not-allowed" }}
                            >
                                Done
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button
                                className="btn gray_btn common_btn big_btn"
                                onClick={() => {
                                    setSrc(null);
                                    setIsModalOpen(false);
                                    setUploadImageValidation(false);

                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>
            <Loader isLoading={loading} />
        </div>
    );
}

export default CreatePlayer;
