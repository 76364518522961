import Request from '../Helpers/API';

export const getAllCountries = () => {
  
    const apiUrl = `admin/countries`;
    
    const req = new Request(apiUrl);
    
    return req.get();
};

export const addCountry = (params) => {
  
    const apiUrl = `admin/add-country`;
    
    const req = new Request(apiUrl, params);
    
    return req.post();
};


export const updateCountry = (params, id) => {
  
    const apiUrl = `admin/update-country/${id}`;
    
    const req = new Request(apiUrl, params);
    
    return req.put();
};


export const getCountryList = (params) => {
  
    const apiUrl = `admin/country-list`;
    
    const req = new Request(apiUrl, params);
    
    return req.post();
};

export const deleteCountry = (id) => {
  
    const apiUrl = `admin/country-delete/${id}`;
    
    const req = new Request(apiUrl);
    
    return req.delete();
};

export const getCountryDetails = (id) => {
  
    const apiUrl = `admin/country-details/${id}`;
    
    const req = new Request(apiUrl);
    
    return req.get();
};