import Request from '../Helpers/API';
export const registerUser = (params) => {
  
    const api_url = `admin/signup`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};

export const getAllUsers = (params) => {
  
    const api_url = `admin/user-list`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};

export const userLogin = (params) =>{
    const api_url = `admin/login`;

    const req = new Request(api_url,params);

    return req.post();
}


export const appUpdate = (params) => {
  
    const api_url = `constant-details`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};

export const appUpdateDetails = (type) => {
  
    const api_url = `constant-details/${type}`;
    
    const req = new Request(api_url);
    
    return req.get();
};

export const profileDetail = (id) => {
  
    const api_url = `admin/profile-detail/${id}`;
    
    const req = new Request(api_url);
    
    return req.get();
};

export const updateProfile = (params,id) =>{
    const api_url = `admin/update-profile/${id}`;
    
    const req = new Request(api_url,params);
    
    return req.patch();
}

export const deleteUser = (params) =>{
    const api_url = `admin/delete-user`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const exportAllUser = () => {
  
    const api_url = `admin/export-user-data`;
    
    const req = new Request(api_url);
    
    return req.get();
};

export const emailInvitation = (userId) => {
  
    const api_url = `admin/email-invitation/${userId}`;
    
    const req = new Request(api_url);
    
    return req.get();
};

export const reactivateUser = (params) =>{
    const api_url = `admin/reactivated-user`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const hardDeleteUser = (id) =>{
    const api_url = `admin/hard-delete-user/${id}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}