import images from "./components/ImagesUrl/imagesUri"
import Login from "./views/Login";
import Logout from "./views/Logout";
import ForgotPassword from "views/ForgotPassword";
import ResetPassword from "views/ResetPassword";
import FaqList from "views/FaqList";
import CreateFaq from "views/CreateFaq";
import AddPage from "views/AddPage";
import PageList from "views/PageList";
import Tournaments from "views/Tournaments";
import CreateTournament from "views/CreateTournament";
import MatchList from "views/MatchList";
import AddMatch from "views/AddMatch";
import CreateMultipleMatches from "views/CreateMultipleMatches";
import MultiMatchesList from "views/MultiMatchesList";
import PushNotification from "views/PushNotifaction";
import PlayersList from "views/PlayersList";
import CreatePlayer from "views/CreatePlayer";
import CountriesList from "views/CountriesList";
import AddFeed from "views/AddFeed";

const dashboardRoutes = [
  {
    path: "/tournaments/tournaments",
    name: "Tournaments",
    icon: images.tournament,
    active_icon:images.tournamentActive,
    component: Tournaments,
    layout: "/admin",
    show: true,
  },
  {
    path: "/tournaments/create-tournament",
    name: "Create Tournaments",
    icon: images.tournament,
    active_icon:images.tournamentActive,
    component: CreateTournament,
    layout: "/admin",
    show: false,
  },
  {
    path: "/tournaments/edit-tournament",
    name: "Edit Tournaments",
    icon: images.tournament,
    active_icon:images.tournamentActive,
    component: CreateTournament,
    layout: "/admin",
    show: false,
  },
  {
    path: "/match/match-list",
    name: "Matches",
    icon: images.match,
    active_icon:images.matchActive,
    component: MatchList,
    layout: "/admin",
    show:true,
  },
  {
    path: "/match/add-match",
    name: "Matches",
    icon: images.match,
    active_icon:images.matchActive,
    component: AddMatch,
    layout: "/admin",
    show: false,
  },
  {
    path: "/multiple/create-match",
    name: "Multiple Matches",
    icon: images.match,
    active_icon:images.matchActive,
    component: CreateMultipleMatches,
    layout: "/admin",
    show: false,
  },
  {
    path: "/multiple/edit-multiple-match",
    name: "Edit Matches",
    icon: images.match,
    active_icon:images.matchActive,
    component: CreateMultipleMatches,
    layout: "/admin",
    show: false,
  },
  {
    path: "/multiple/multiple-matches-list",
    name: "Multiple Matches",
    icon: images.match,
    active_icon:images.matchActive,
    component: MultiMatchesList,
    layout: "/admin",
    show:true,
  },
  {
    path: "/match/edit-match",
    name: "Edit Match",
    icon: images.match,
    active_icon:images.matchActive,
    component: AddMatch,
    layout: "/admin",
    show: false,
  },
  {
    path: "/players/player-list",
    name: "Players",
    icon: images.match,
    active_icon:images.matchActive,
    component: PlayersList,
    layout: "/admin",
    show: true,
  },
  {
    path: "/players/create-player",
    name: "Create Player",
    icon: images.match,
    active_icon:images.matchActive,
    component: CreatePlayer,
    layout: "/admin",
    show: false,
  },
  {
    path: "/players/edit-player",
    name: "Update Player",
    icon: images.match,
    active_icon:images.matchActive,
    component: CreatePlayer,
    layout: "/admin",
    show: false,
  },
  {
    path: "/countries/country-list",
    name: "Countries",
    icon: images.match,
    active_icon:images.matchActive,
    component: CountriesList,
    layout: "/admin",
    show: true,
  },
  {
    path: "/faq/create-faq",
    name: "Create FAQ",
    icon: images.faq,
    active_icon: images.faqActive,
    component: CreateFaq,
    layout: "/admin",
    show: false,
  },
  {
    path: "/faq/edit-faq",
    name: "Edit FAQ",
    icon: images.faq,
    active_icon: images.faqActive,
    component: CreateFaq,
    layout: "/admin",
    show: false,
  },
  {
    path: "/faq/faq-list",
    name: "FAQ",
    icon: images.faq,
    active_icon: images.faqActive,
    component: FaqList,
    layout: "/admin",
    show: true,
  },
  {
    path: "/pages/add-page",
    name: "Add Page",
    icon: images.pages,
    active_icon: images.pagesActive,
    component: AddPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/pages/edit-page",
    name: "Edit Page",
    icon: images.pages,
    active_icon: images.pages,
    component: AddPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/pages/page-list",
    name: "Pages",
    icon: images.pages,
    active_icon: images.pagesActive,
    component: PageList,
    layout: "/admin",
    show: true,
  },
  {
    path: "/info-list",
    name: "Feed",
    icon: images.newsfeed,
    active_icon: images.newsfeedActive,
    component: AddFeed,
    layout: "/admin",
    show: true,
  },
  {
    path: "/setting/push-notification",
    name: "Settings & Notifications",
    icon: images.settings,
    active_icon:images.settingsActive,
    component: PushNotification,
    layout: "/admin",
    show:true,
  },
  {
    path: "/login",
    name: "Login",
    icon: images.admin,
    component: Login,
    layout: "/auth",
    show: false
  }, 
  {
    path: "/forgot-password",
    name: "Forgot Password",
    icon: images.admin,
    component: ForgotPassword,
    layout: "/auth",
    show: false
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    icon: images.admin,
    component: ResetPassword,
    layout: "/auth",
    show: false
  },
  {
    path: "/logout",
    name: "Logout",
    icon: images.logout,
    active_icon:images.logout,
    component: Logout,
    layout: "/admin",
    show: false,
  },

  
];

export default dashboardRoutes;
