import React, { Component } from 'react';
import images from "../components/ImagesUrl/imagesUri"
import 'simplebar/dist/simplebar.min.css';
import SimpleBar from 'simplebar-react';
import InputError from "../components/Error/InputError";
import Rules from "../Helpers/FormRules";
import FormValidator from "../Helpers/FormValidator";
import { errorToast, successToast } from '../components/Toast/Toast.js';
import ReactCrop from 'react-image-crop';
import moment from 'moment';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from 'react-modal';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { createTournament, updateTournament} from '../Actions/post.js';
import { concatDateTime, convertDateFormat, extractDate, extractTime, timeZone, extractPostTime} from '../Helpers/TimeConversion';
// import { EVENT_ID } from '../Helpers/Config.js';
import * as _ from 'lodash';
import Loader from '../components/Loader/Loader.jsx';

class CreateTournament extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator(Rules.createTournament);
        this.state={
            validation: this.validator.valid(),
            isLoading:false,
            src: null,
            croppedImageUrl:null,
            isModalOpen:false,
            selectedImages:[],
            delete_images: [],
            blobData: null,
            post_id: null,
            is_edit: false,
            is_custom_edit: false,
            endTiming: false,
            wrongDate: false,
            currentTiming: false,
            gif_status:false,
            gif: null,
            uploadImageValidation: false,
            crop: {
                unit: 'px',
                width: 600,
                height: 500,
                aspect: 16/9,
            },
            // crop: {
            //     unit: 'px',
            //     width: 360,
            //     height: 360,
            //     x: 25,
            //     y: 25,
            //     aspect: 1/1,
            // },
            formData:{ 
                tournamentTitle:'',
                tournamentStatus:'1',
                postStatus:'1',
                schedulePost:'1',
                startTime: moment(new Date()).format("HH:mm"),
                endTime: moment(new Date()).format("HH:mm"),
                startDate: new Date(),
                endDate: new Date(),
                post_images:[],
            },
        }
        this.formsubmitted = false;
    }

    componentDidMount(){
        if(this.props.location.state) {
        const  feedData  = this.props.location.state;
        console.log("Is Edit Data",feedData);  
        const data = {
            tournamentTitle: feedData.title,
            tournamentStatus: (feedData.status === 1)?"1" :"2",
            // postStatus: (feedData.is_active)? "1" : "2",
            post_images: feedData.image,
            // schedulePost: feedData.is_schedule,
            // startTime: extractPostTime(feedData.start_date_time, "HH:mm"),
            // endTime: extractPostTime(feedData.end_date_time, "HH:mm"),
            // startDate: new Date (extractDate(feedData.start_date_time)),
            // endDate: new Date(extractDate(feedData.end_date_time)),
        }
            this.setState({
                formData: data,
                post_id: feedData.id ,
                selectedImages: (feedData.image.length > 0)? feedData.image : [],
                is_edit: true,
                is_custom_edit: (feedData.is_schedule == 2)? true : false,
            });
        }
        
    }

    onSubmit = e => {
        e.preventDefault();
        const {tournamentTitle, tournamentStatus, postStatus, schedulePost, startTime, endTime, startDate, endDate, post_images , isLoading,} = this.state.formData;
        const {post_id, delete_images, is_custom_edit} = this.state;
        // const StartDate = convertDateFormat(startDate, "YYYY-MM-DD");
        // const EndDate = convertDateFormat(endDate, "YYYY-MM-DD");
        // const nowTime = extractTime(timeZone(),"HH:mm");
        // const nowDate = convertDateFormat(new Date(), "YYYY-MM-DD");
        

        this.submitted = true;
        // if(!(EndDate >= StartDate)) {
        //     this.setState({ wrongDate:true });
        //     this.submitted = false;
        // } else if(EndDate == StartDate && startTime > endTime){
        //     this.setState({ endTiming:true });
        //     this.submitted = false;
        // } else if(StartDate == nowDate && startTime < nowTime && schedulePost == "2"){
        //     this.setState({ currentTiming: true });
        //     this.submitted = false;
        // } 

        if(this.submitted == true){
            const validation = this.validator.validate(this.state.formData);
            this.setState({isLoading:true});
            if(validation.isValid) {
            const created_at= moment().unix(); 
            let tournamentImage =post_images[0];

            const feed = {
                "title" : (tournamentTitle)? tournamentTitle.trim() : "",
                // "event_id" : EVENT_ID,
                // "post_status" : (postStatus === "1")? true : false,
                "status" : tournamentStatus,
                "image" :tournamentImage ,
                "created_at":created_at,
                // "is_schedule": (schedulePost == 1)? false: true,
                // "start_date_time" :  concatDateTime(convertDateFormat(startDate, "YYYY-MM-DD"), startTime),
                // "end_date_time" :  concatDateTime(convertDateFormat(endDate, "YYYY-MM-DD"), endTime)
            }
            const updateFeed = {
                "title" : (tournamentTitle)? tournamentTitle.trim() : "",
                "image": tournamentImage,
                "status" : tournamentStatus,
                "tournament_id": post_id
            }
            console.log("Submitted Data",feed ,"------ID-----",post_id);
            
            if(post_id){
                const postImages = [];
                post_images.map((val, index) => {
                    if(typeof val == "object"){
                        postImages.push(val);
                    }
                });
                feed.delete_images = delete_images; 
                feed.post_images = postImages; 
                if(!is_custom_edit) { delete feed.start_date_time }; 
            }
            const apiMethod = (post_id) ? updateTournament(updateFeed): createTournament(feed);
            apiMethod.then((response) => {
                const result = response.data.success;
                if(result){
                  successToast(result.message);
                  setTimeout(() => {
                    this.props.history.push("/admin/tournaments/tournaments",post_id);
                  },1000);
                } else {
                  const errorResult = response.data.error;
                  this.setState({isLoading:false});
                  errorToast(errorResult.message);
                }
            }).catch((error)=>{
                if (error.response) {
                    const errorResult = error.response.data.error;
                    errorToast(errorResult.message);
                    this.setState({isLoading:false})
                } 
            })
            } else {
                this.setState({isLoading:false})
            }
        }
    }
   
    onChange = e =>{
        let {name, value} = e.target;
        let formData = { ...this.state.formData };
        const {is_custom_edit} = this.state;
        value = (value == " ")? "" : value;
        formData[name] = value;
        if(name == "schedulePost" && value == "1" && !is_custom_edit){
            formData["startDate"] = new Date();
            formData["startTime"] = moment(new Date()).format("HH:mm");
            this.setState({endTiming: false, wrongDate: false, currentTiming: false})
        }
        this.setState({ formData });
        this.setState({endTiming: false, wrongDate: false, currentTiming: false})
    }

    onSelectFile = (e) => {
        e.preventDefault();
        const files = e.target.files;
        if(files && files.length > 0 && files[0].size <= 2000000){
            this.setState({ src: null },()=>{
                if (!(files[0].type == "image/gif" )) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () =>
                        this.setState({ 
                            src: reader.result,
                            gif_status: false,
                            uploadImageValidation: false,
                            // crop: {
                            //     unit: 'px',
                            //     width: 360,
                            //     height: 360,
                            //     x: 25,
                            //     y: 25,
                            //     aspect: 1/1,
                            // }
                            crop:{  
                                unit: 'px',
                                width: 600,
                                height: 500,
                                aspect: 16/9,
                            }
                        })
                    );
                    reader.readAsDataURL(files[0]);
                } else if (files && files.length > 0){
                    this.setState({ src: URL.createObjectURL(files[0]), gif_status: true, gif: files[0], crop: null });
                }
            });    
        } else if(files && files.length > 0){
            this.setState({ uploadImageValidation: true });
        }   
    };

    openModal = (e) => {
        e.preventDefault();
        document.body.style.overflow = 'hidden';
        const { selectedImages } = this.state;
        if(selectedImages.length < 3){
            this.setState({isModalOpen:true});
        } else {
            e.preventDefault();
        }    
    }

    closeModal =()=> {
        this.setState({src: null, isModalOpen:false, src: null, gif_status: false, gif: "", uploadImageValidation: false});
    }

    doneImage = (e) => {
        e.preventDefault();
        document.body.style.overflow = 'unset';
        const { src, gif_status, gif, formData, selectedImages } = this.state;
        if(src && !gif_status){
            let { croppedImageUrl, blobData } = this.state;
            const newImage = new File([blobData], blobData.name, { type: blobData.type });
            formData["post_images"] = [...formData["post_images"], newImage]; 
            this.setState({
                src: null,
                blobData: null,
                isModalOpen:false,
                selectedImages: [...selectedImages, croppedImageUrl],
                formData
            });
        } else if(gif_status) {
            formData["post_images"] = [...formData["post_images"], gif]; 
            this.setState({
                src: null,
                blobData: null,
                isModalOpen:false,
                selectedImages: [...selectedImages, src],
                gif: null,
                gif_status: false,
                formData
            });
        } else {
            e.preventDefault();
        }      
    }
    
    onImageLoaded = image => {
        this.imageRef = image;
        this.setState({uploadImageValidation: false });
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const response = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl: response.croppedImageUrl, blobData: response.blob });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve({blob: blob, croppedImageUrl: this.fileUrl});
            }, 'image/*');
        });
    }

    removeImage = (e) =>{
        let { selectedImages, formData, delete_images, post_id} =  this.state;
        if(post_id){
            const deleteImgs = formData["post_images"].splice((e.target.id-1),1);
            if( typeof deleteImgs[0] == "string"){
                this.setState({delete_images: [...delete_images, deleteImgs], formData});
            } else {
                selectedImages.splice((e.target.id-1),1);
                this.setState({selectedImages, formData});
            }
        } else {
            formData["post_images"].splice((e.target.id-1),1);
            selectedImages.splice((e.target.id-1),1);
            this.setState({selectedImages, formData});
        }
    }

    endDateChange = (date) => {
        let { formData } = this.state;
        formData['endDate'] =  (date)? date : "";
        this.setState({formData});
        this.setState({endTiming:false,wrongDate:false})
    };

    startDateChange = (date) => {
        let { formData } = this.state;
        formData['startDate'] =  (date)? date : "";
        this.setState({formData});
        this.setState({endTiming:false,wrongDate:false})
    };

    render() {
        const {formData, src, crop, selectedImages, isModalOpen, isLoading, post_id, is_edit, is_custom_edit, endTiming, wrongDate, currentTiming} = this.state;
        const {startDate, endDate, startTime, endTime} = this.state.formData;
        let validation = this.submitted ? this.validator.validate(this.state.formData):this.state.validation;
   
        return (
               <div className="agenda_session_form_section agenda_padding"> 
            {/* <SimpleBar style={{height:'94vh'}}> */}
            <div style={{height:'94vh', overflow: "auto"}} className="mobile-scrollbar-height" >
                <div className="col-md-12 p-0 m-pt-15">
                    <form className="col-md-12 p-0" method="POST">
                    <div className="row m-0">
                        <div className="col-md-6 p-0">
                            <div className="form-group col-md-12 form-custom">
                                {/* <label htmlFor="inputEmail4" className="left_label mb-3">Post Text</label> */}
                                <div className="row mb-3">
                                    <label htmlFor="inputEmail4" className="col left_label m-0 col-4">Title</label>
                                    <label htmlFor="inputEmail4" className="col text-right right_label m-0 col-8">{formData.tournamentTitle.length} of 20 Characters</label>
                                </div>
                                <textarea className="form-control" 
                                    id="exampleFormControlTextarea1" 
                                    rows="1" 
                                    maxLength={20}
                                    onChange={this.onChange}
                                    name="tournamentTitle" 
                                    value={formData.tournamentTitle}
                                    placeholder="Enter Title (Max 20 Characters)"
                                    autoFocus
                                    >
                                </textarea>
                                
                                <InputError show={validation.tournamentTitle.isInvalid} message={validation.tournamentTitle.message} />
                            </div>
     
                            <div className="form-group col-md-12 form-custom m-0">
                                <div className="row mb-0">
                                <div className="col-md-12 custom_full_width">
                                    <label htmlFor="inputEmail4" className="left_label">Status</label>
                                    <div className="row checkbox_height">
                                    <div className="custom-control custom-radio col-md-6 col-6">
                                        <input type="radio" 
                                            id="customRadioInline1" 
                                            className="custom-control-input"
                                            name="tournamentStatus"  
                                            value={"1"}
                                            checked={formData.tournamentStatus == "1"} 
                                            onChange={this.onChange}
                                            />
                                        <label className="custom-control-label" htmlFor="customRadioInline1">Active</label>
                                    </div>
                                    <div className="custom-control custom-radio col-md-6 col-6">
                                        <input type="radio" 
                                            id="customRadioInline2" 
                                            className="custom-control-input"
                                            name="tournamentStatus"  
                                            value={"2"}
                                            checked={formData.tournamentStatus == "2"} 
                                            onChange={this.onChange}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadioInline2">Inactive</label>
                                    </div>
                                    <InputError show={validation.tournamentStatus.isInvalid} message={validation.tournamentStatus.message} />
                                    </div>
                                </div>
                                {/* <div className="col-md-6 custom_full_width">
                                    <label htmlFor="inputEmail4" className="left_label">Post Status</label>
                                    <div className="row checkbox_height">
                                    <div className="custom-control custom-radio col-md-6">
                                        <input type="radio" 
                                            id="customRadioInline3" 
                                            className="custom-control-input"
                                            name="postStatus"  
                                            value={"1"}
                                            checked={formData.postStatus == "1"} 
                                            onChange={this.onChange}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadioInline3">Publish</label>
                                    </div>
                                    <div className="custom-control custom-radio col-md-6">
                                        <input type="radio" 
                                            id="customRadioInline4" 
                                            className="custom-control-input"
                                            name="postStatus"  
                                            value={"2"}
                                            checked={formData.postStatus == "2"} 
                                            onChange={this.onChange}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadioInline4">Unpublished</label>
                                    </div>
                                    
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        
                        
                            {/* <div className="form-group col-md-12 form-custom m-0">
                                <div className="row mb-3">
                                <div className="col-md-6 custom_full_width">
                                    <label htmlFor="inputEmail4" className="left_label">Schedule Post</label>
                                    <div className="row checkbox_height">
                                    <div className="custom-control custom-radio col-md-6">
                                        <input type="radio" 
                                            id="now" 
                                            className="custom-control-input"
                                            name="schedulePost"  
                                            value={"1"}
                                            checked={formData.schedulePost == "1"} 
                                            onChange={this.onChange}
                                            disabled={(is_edit && is_custom_edit || !is_edit)? false : true}
                                        />
                                        <label className="custom-control-label" htmlFor="now">Now</label>
                                    </div>
                                    <div className="custom-control custom-radio col-md-6">
                                        <input type="radio" 
                                            id="custom_time" 
                                            className="custom-control-input"
                                            name="schedulePost"  
                                            value={"2"}
                                            checked={formData.schedulePost == "2"} 
                                            onChange={this.onChange}
                                            disabled={(is_edit && is_custom_edit || !is_edit)? false : true}
                                        />
                                        <label className="custom-control-label" htmlFor="custom_time">Custom Time</label>
                                    </div>
                                    
                                    </div>
                                </div>
                                </div>
                            </div>
                        
                            <div className="form-group col-md-12 form-custom">
                                <div className="row mb-3">
                                <div className="col-md-6 custom-height">
                                    <label htmlFor="inputEmail4" className="left_label mb-3">Starting Date</label>
                                
                                        <DatePicker
                                            selected={startDate}
                                            dateFormat="dd-MM-yyyy"
                                            onChange={this.startDateChange}
                                            className="form-control"
                                            id="category1"
                                            name="startDate"
                                            placeholderText="Start Date"
                                            minDate={new Date()}
                                            // disabled={(is_edit && is_custom_edit || !is_edit)? false : true}
                                            disabled={(formData.schedulePost == "2")? false : true}

                                        />
                                        <InputError show={validation.startDate.isInvalid} message={validation.startDate.message} />
                                    
                                </div>
                                <div className="col-md-6 custom-height">
                                    <label htmlFor="inputEmail4" className="left_label mb-3">End Date</label>
                                    
                                        <DatePicker
                                            selected={endDate}
                                            dateFormat="dd-MM-yyyy"
                                            onChange={this.endDateChange}
                                            className="form-control"
                                            id="category1"
                                            name="endDate"
                                            placeholderText="End Date"
                                            minDate={startDate}
                                            // disabled={(is_edit && is_custom_edit || !is_edit)? false : true}
                                        />
                                        { wrongDate == true ? 
                                            <InputError show={wrongDate} message="End Date should not be less then to Start Date" />
                                            : <InputError show={validation.endDate.isInvalid} message={validation.endDate.message} />
                                        }
                                    
                                </div>
                                </div>
                            </div>
                            <div className="form-group col-md-12 form-custom">
                                <div className="row mb-3">
                                <div className="col-md-6 custom-height">
                                    <label htmlFor="inputEmail4" className="left_label mb-3">Starting Time</label>
                                    <div className="select-box-div relative">
                                        
                                        <input type="time" 
                                            className="form-control" 
                                            id="time1" 
                                            name="startTime" 
                                            value= {startTime}
                                            onChange={this.onChange.bind(this)}
                                            // disabled={(is_edit && is_custom_edit || !is_edit)? false : true}
                                            disabled={(formData.schedulePost == "2")? false : true}
                                        />
                                        
                                    </div>
                                    { currentTiming == true ? 
                                      <InputError show={currentTiming} message="Start time should not be less then Current time"/> : 
                                      <InputError show={validation.startTime.isInvalid} message={validation.startTime.message} />
                                    }
                                </div>
                                <div className="col-md-6 popup-custom-height">
                                    <label htmlFor="end-time" className="left_label mb-3">End Time</label>
                                    <div className="select-box-div relative">
                                       
                                        <input type="time" 
                                            className="form-control" 
                                            id="time1" 
                                            name="endTime" 
                                            value= {endTime}
                                            onChange={this.onChange.bind(this)}
                                            // disabled={( post_id)? true : false}
                                            // min={extractTime(timeZone(),"HH:mm")}
                                        />
                                    </div>
                                    { endTiming == true ? 
                                      <InputError show={endTiming} message="End time should not be less then Start time" /> : 
                                      <InputError show={validation.endTime.isInvalid} message={validation.endTime.message} />
                                    }
                                </div> 
                                
                                </div>
                            </div>*/}
                            
                        </div>
                        
                        
                        <div className="col-md-6 p-0">
                            <div className="form-group col-md-12 form-custom post-image">
                                <div className="row mb-3">
                                <label htmlFor="inputEmail4" className="col left_label m-0">Image </label>
                                <label htmlFor="inputEmail4" className="col text-right right_label m-0">
                                    <Link to="#" onClick={(selectedImages.length < 1) ? this.openModal:null} style={{ cursor:(selectedImages.length < 1)?"pointer":"not-allowed" }}>Upload</Link>
                                </label>
                                
                                </div>
                               
                                <div className="col-md-12 ">
                                <div className="row">
                                {selectedImages.length > 0 && (
                                    selectedImages.map((image,index) =>{
                                        return(
                                            <div className="relative col-md-11 upload-img-div" key={index}>
                                                <img alt="Crop" src={image} className="post_square_img" style={{borderRadius:'10px',height: '204px', maxWidth: '100%'}} />
                                                <img src={images.crossIcon} className="post_cancel"  key={index} id={index+1} onClick={this.removeImage} alt="cross"/> 
                                            </div>
                                        )
                                    })
                                )}
                                </div>
                                </div>
                                <InputError show={validation.post_images.isInvalid} message={validation.post_images.message} />
                            </div>
                        </div>
                    
                        <div className="form-group col-md-12 form-custom" >
                            <div className="row">
                                <div className="col-md-3 col-6 m-pr-7">
                                    <button className="btn common_btn blue_btn big_btn fixed_width_btn" 
                                        type="submit"
                                        onClick={this.onSubmit.bind(this)}
                                        disabled = {(formData.post_images.length > 0 || formData.tournamentTitle.length > 0)? isLoading: true}
                                        style={{ cursor:(formData.post_images.length > 0 || formData.tournamentTitle.length > 0)?"pointer":"not-allowed" }}
                                    >{ is_edit == true ? "Update" : "Save"}  
                                    </button> 
                                </div>
                                <div className="col-md-3 col-6 m-pl-7">
                                    <button  className="btn common_btn gray_btn big_btn fixed_width_btn "
                                        role="button"
                                        type="button"
                                        onClick={ () => { this.props.history.push("/admin/tournaments/tournaments");} }
                                    >
                                        Cancel 
                                    </button>
                                </div>
                                <div className="col-md-4"></div>
                            </div> 
                            
                        </div>
                        
                        
                    </div>
                    </form>
                  
                </div>
            {/* </SimpleBar> */}
            </div>
                <Modal
                isOpen={isModalOpen}
                contentLabel="Upload Image"
                ariaHideApp={false}>
                    <form className="col-md-12 p-0 modal_from">
                     <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="modal_heading">Upload Image</h2>
                            </div>		
                            <div className="form-group col-md-12 form-custom">
                                <div className="col-md-12 upload-btn-wrapper p-0">
                                    <button className="btn import-btn" > Choose Image </button>
                                    <input type="file" name="myfile" accept="image/*" onChange={this.onSelectFile}/>
                                </div>
                                {src && (
                                    <ReactCrop
                                        src={src}
                                        crop={crop}
                                        ruleOfThirds = { true }
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                        locked ={true}
                                        // className="ReactCrop--locked"
                                    />
                            
                                )}
                                <InputError show={this.state.uploadImageValidation} message={"Upload image size should not exceed 2 MB"} />
                            </div>
                            <div className="col-md-6 m-mb-15">
                                <button
                                  className="btn blue_btn common_btn big_btn" 
                                   onClick={this.doneImage}
                                   style={{cursor:(src)?"pointer":"not-allowed" }}
                                >
                                    Done
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button className="btn gray_btn common_btn big_btn"  onClick={this.closeModal}> Cancel </button>
                            </div>
                     </div>
                 </form>
                    
             </Modal>
            <Loader isLoading={isLoading}/>
            </div>
            
        );
    }
}

export default CreateTournament;