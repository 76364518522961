import React, { Component } from 'react'
import images from '../ImagesUrl/imagesUri';
import Sidebar from 'components/Sidebar/Sidebar';

export default class Navbar extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            
        }
    }
    openNav() {
        console.log('open')
        return <Sidebar/>
    
        
    }

    render() {
        return (
            <nav className="col-md-12 p-0 mobile_nav d-md-none">
               <div className="row m-0">
                  <div className="col-md-5 col-10 p-0"><img src={images.openMenu} className="mr-2" onClick={this.openNav.bind(this)}/>
                     <img src={images.logo} className="logo"/>
                  </div>
                    <div className=" p-0  col-2 text-right">
                        <a className="btn blue_btn common_btn create_post ml-auto" role="button" href="/admin/match/add-match">+
                        </a>
                    </div>
                  <div className="pr-7 pl-0  col-6 mt-2">
                        <div className="search col d-flex">
                            <img alt="img" src={images.search}/>
                            <input className="form-control" type="text" placeholder="Search Match" aria-label="Search"/>
                        </div>
                  </div>
                  <div className=" assign_in_tags common_dropdown form-group form-custom m-0 col-6 pr-0 pl-7 mt-2">
                     <div className=" css-2b097c-container">
                        <div className=" css-yk16xz-control">
                           <div className=" css-1hwfws3">
                              <div className=" css-1uccc91-singleValue">ALL</div>
                              <div className="css-1g6gooi">
                                 {/* <div className="" style="display: inline-block;">
                                    <input autocapitalize="none" autocomplete="off" autocorrect="off" id="react-select-7-input" spellcheck="false" tabindex="3" type="text" aria-autocomplete="list" value="" style="box-sizing: content-box; width: 2px; background: 0px center; border: 0px; font-size: inherit; opacity: 1; outline: 0px; padding: 0px; color: inherit;"/>
                                    <div style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-size: 16px; font-family: CircularStd-Book; font-weight: 400; font-style: normal; letter-spacing: normal; text-transform: none;"></div>
                                 </div> */}
                              </div>
                           </div>
                           <div className=" css-1wy0on6"><span className="css-1okebmr-indicatorSeparator"></span></div>
                        </div>
                        <input name="tournament_id" type="hidden" value=""/>
                     </div>
                  </div>
               </div>
            </nav>
        )
    }
}
