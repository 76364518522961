import Request from '../Helpers/API';

export const createPage = (params) => {
  
    const api_url = `admin/create-page`;
    
    const req = new Request(api_url, params);
    
    return req.post();
};

export const getPageList = (params) => {
  
    const api_url = `admin/page-list`;
    
    const req = new Request(api_url, params);
    
    return req.post();
};

export const reactivatePage = (params) =>{
    const api_url = `admin/reactivated-page`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const hardDeletePage = (pageId) =>{
    const api_url = `admin/hard-delete-page/${pageId}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}

export const deletePage = (params) =>{
    const api_url = `admin/delete-page`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const changePageStatus = (params, pageId) =>{
    const api_url = `admin/change-page-status/${pageId}`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const getPageDetails = (pageId) =>{
    const api_url = `admin/page-details/${pageId}`;
    
    const req = new Request(api_url);
    
    return req.get();
}

export const updatePage = (params, pageId) =>{
    const api_url = `admin/update-page/${pageId}`;
    
    const req = new Request(api_url, params);
    
    return req.put();
}