import React, { Component } from 'react';
import ImageFeed from './ImageFeed';
import TextImageFeed from './TextImageFeed';

class TextFeed extends Component {
    constructor(props) {
        super(props)
    }

    selectText(feedData){
        if(feedData.title.length < 30 )
        {
           return this.singleLineText(feedData)
        } else if (feedData.title.length < 100 )
        {
           return this.threeLineText(feedData)
        } else {
            return this.fullText(feedData)
        }
    }
    
    singleLineText(feed) {
        return(
            <div className="new_second_part pt-3 new_second_part_one">
                <p className="m-0">{feed.title}</p>
            </div>
        )
        
    }
    threeLineText(feed) {
        return(
            <div className="new_second_part pt-3 new_second_part_two">
                <p className="m-0">{feed.title.substr(0,100)}</p>
            </div>
        )
    }
    fullText(feed) {
        let title = feed.title;
        let postImages = feed.image;

        title = (title.length > 240 ) ? title.substr(0,240)+'...' : title;
        return(
            <>
               
                <div className="new_second_part pt-3 new_second_part_six">
                    <p className="m-0">{title}</p>
                </div>
    
            </>
        )
    }
   
    render() {
        console.log("TextFeed",this.props.feed);
        const { feed } = this.props;
        if(feed == undefined || feed ==null) {
            return 
        }
        return (
            <>
                {this.selectText(feed)}
            </>
        );
    }
}

export default TextFeed;