import Request from '../Helpers/API';

export const createMultipleMatch = (params) => {
  
    const api_url = `admin/create-multiple-match`;
    
    const req = new Request(api_url, params);
    
    return req.post();
};

export const updateMultipleMatch = (params, id) => {
  
    const api_url = `admin/update-multiple-match/${id}`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
};

export const getMatchesList = (params) => {
  
    const api_url = `admin/multiple-match-list`;
    
    const req = new Request(api_url, params);
    
    return req.post();
};

export const softDeleteMultiMatch = (id) =>{
    const api_url = `admin/multimatch-match-soft-delete/${id}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}

export const updateMultiMatchStatus = (id, params) =>{
    const api_url = `admin/change-multiple-match-status/${id}`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const restoreMultiMatch = (id) =>{
    const api_url = `/admin/multimatch-match-restore/${id}`;
    const req = new Request(api_url);
    return req.patch();
}

export const hardDeleteMultiMatch = (id) =>{
    const api_url = `admin/multiple-match-delete/${id}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}