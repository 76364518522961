import Request from '../Helpers/API';

export const getAllTounament = () => {
  
    const api_url = `admin/get-all-tournaments`;
    
    const req = new Request(api_url);
    
    return req.get();
};

export const getMatchDetails = (id) => {
  
    const api_url = `admin/match-details/${id}`;
    
    const req = new Request(api_url);
    
    return req.get();
};

export const createMatch = (params) => {
  
    const api_url = `admin/create-match`;
    
    const req = new Request(api_url, params);
    
    return req.post();
};

export const updateMatch = (params, id) => {
  
    const api_url = `admin/update-match/${id}`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
};

export const getPlayers = (id) => {
  
    const api_url = `admin/player-list`;
    
    const req = new Request(api_url);
    
    return req.get();
};

export const getMatchList = (params) => {
  
    const api_url = `admin/match-list`;
    
    const req = new Request(api_url, params);
    
    return req.post();
};

export const softDeleteMatch = (id) =>{
    const api_url = `admin/match-soft-delete/${id}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}

export const restoreMatch = (id) =>{
    const api_url = `/admin/match-restore/${id}`;
    const req = new Request(api_url);
    return req.patch();
}

export const hardDeleteMatch = (id) =>{
    const api_url = `admin/match-delete/${id}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}

export const updateStatus = (id, params) =>{
    const api_url = `admin/change-match-status/${id}`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}