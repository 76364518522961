import React, { Component } from 'react';
import images from "../components/ImagesUrl/imagesUri";

class SortBy extends Component {

    onSorterChange(data, e){
        data["order"] = (data["order"] === "ASC")? "DESC" : "ASC";
        this.props.onSorter(data, e);
    }

    render() {
        const { sortBy, header_name, row } = this.props;

        if(sortBy["name"] && sortBy["name"].indexOf(".") !== -1){
            sortBy["name"] = sortBy["name"].split(".")[1];
        }

        return ( 
            <>
                <span style={{marginRight: "5px"}}>{header_name}</span>
                <span 
                    onClick={this.onSorterChange.bind(this, {name: row.accessor, order: sortBy["order"]})}
                >
                    <img 
                        src={images.tableTopArrow}
                        className={"up-arrow"}
                        style={{opacity:(sortBy["name"] === row.accessor && sortBy["order"] === "ASC")? "1" : "0.2", width: "5px"}}
                        alt=""
                    />
                    <img 
                        src={images.tableBottomArrow}
                        className={"down-arrow"}
                        style={{opacity:(sortBy["name"] === row.accessor && sortBy["order"] === "DESC" )? "1" : "0.2", width: "5px"}}
                        alt=""
                    />
                </span>    
            </>
        )
    }
}

export default SortBy;