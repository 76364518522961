import React, { Component } from 'react';

class ImageFeed extends Component {
    constructor(props) {
        super(props)
    }
    selectImageComponent(matchData){
        let matchTitle = matchData.title;
        let playerImages = matchData.post_images;

        if(matchTitle.length == 0 ) 
        {   
            if(playerImages.length == 1)
            {
            return this.noTextsingleImage(matchData)
            }
        }
        if(matchTitle.length < 30 )
        {
           if(playerImages.length == 1 ){
               return this.singleImage(matchData)
            }
           if(playerImages.length == 2 ){
                return this.doubleImage(matchData)
            }
            if(playerImages.length == 3 ){
                return this.tripleImages(matchData)
            }
        } else if (matchTitle.length < 100 )
        {
           return 
        } else {
            return 
        }
    }
    singleImage(matchData) {
        return (
            <div className="col-md-12 p-0 text-left pt-3 news_third_part_one">
                <img alt="img" src={matchData.post_images[0]} className="width_height_100 border-10"/>
            </div>)
    }
    doubleImage(matchData) {
        return (
            <div className="col-md-12">
            <div className="row pt-3 news_third_part news_third_part_five">
                <div className="col p-0">
                    <img src={matchData.post_images[0]} className="border-10 width_height_100"/>
                </div>
                <div className="col p-0 ml-auto">
                    <img src={matchData.post_images[1]} className="border-10 width_height_100"/>
                </div>
            </div>
            <div className="row winner_name">
                <div className="col-md-6 p-0 text-center"> Novak </div>
                <div className="col-md-6 p-0 ml-auto text-center">Novak</div>
            </div>
        </div>)
    }
    tripleImages(matchData) {
        return (
            <div className="col-md-12">
            <div className="row pt-3 news_third_part news_third_part_three">
                <div className="col p-0 mr-7">
                    <img src={matchData.post_images[0]} className="border-10 width_height_100"/>
                </div>
                <div className="col ml-3-5 mr-3-5 p-0">
                    <img src={matchData.post_images[1]} className="border-10 width_height_100"/>
                </div>
                <div className="col p-0 ml-7">
                    <img src={matchData.post_images[2]} className="border-10 width_height_100"/>
                </div>
            </div>
        </div>)
    }
    noTextsingleImage(matchData){
        return (
            <div className="col-md-12 p-0 mt-3 news_third_part_four">
                <img alt="img" src={matchData.post_images[0]} className="width_height_100 border-10"/>
            </div>)
    }

    render() {
        // console.log("Image Feed",this.props);
        const { feed } = this.props;
        if(feed == undefined || feed ==null) {
            return 
        }
        return (
            <>
                {this.selectImageComponent(feed)}
                
            </>
        );
    }
}

export default ImageFeed;