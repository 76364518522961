import React, { Component } from 'react'
import images from "../components/ImagesUrl/imagesUri";
import { Link } from "react-router-dom";
import InputError from "../components/Error/InputError";
import Rules from "../Helpers/FormRules";
import FormValidator from "../Helpers/FormValidator";
import { resetPassword } from '../Actions/admin';
import { errorToast, successToast } from '../components/Toast/Toast.js';
import Loader from '../components/Loader/Loader.jsx';


export default class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.password_validator = new FormValidator(Rules.changepassword);
    this.state = {
      old_password:'',
      new_password:'',
      confirm_password:'',
      setIsOpen:false,
      isLoading:false,
      show_new_pasword: false,
      show_confirm_pasword: false,
      show_old_pasword: false,
      isLoading:false,
      passwordValidation: this.password_validator.valid(),
      email: null,
    }
    this.changePasswordFormSubmitted = false;
  }
    
  componentDidMount() {
    if(this.props.location.state){
      this.setState({email: this.props.location.state.email});
    }
  }

  onChange=(e)=>{
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  togglePassword(name,e){
    if(name === "show_new_pasword"){
      let {show_new_pasword} = this.state;
      show_new_pasword = !show_new_pasword;
      this.setState({show_new_pasword});
    }else if(name === "show_confirm_pasword"){
      let {show_confirm_pasword} = this.state;
      show_confirm_pasword = !show_confirm_pasword;
      this.setState({show_confirm_pasword});
    }else if(name === "show_old_pasword"){
      let {show_old_pasword} = this.state;
      show_old_pasword = !show_old_pasword;
      this.setState({show_old_pasword});
    }
  }
      
  onSubmit(e){
    e.preventDefault();
    const {old_password, new_password, email} = this.state;
    const passwordValidation = this.password_validator.validate(this.state);
    this.setState({ passwordValidation, isLoading: true });
    this.changePasswordFormSubmitted = true;
    if (passwordValidation.isValid) {
      resetPassword({
        tmp_password: old_password,
        password: new_password,
        email
      }).then(response => {
        const message = (response.data.success)? response.data.success.message : response.data.error.message;
        if(response.data.success){
          successToast(message);
          this.props.history.push({ pathname: "/auth/login"});
        } else {
          errorToast(message);
          this.setState({isLoading:false});
        }
      }).catch(error => {
        if (error.response) {
          const errorResult = error.response.data.error;
          this.setState({isLoading:false});
          errorToast(errorResult.message);
        } 
        this.setState({isLoading:false});
      });
    } else {
      this.setState({ isLoading:false });
    }
  }

  render() {
    const {old_password,new_password,confirm_password,show_old_pasword,show_confirm_pasword,show_new_pasword,isLoading} = this.state;
    let passwordValidation = this.changePasswordFormSubmitted ? this.password_validator.validate(this.state):this.state.passwordValidation;
    return (
      <div className="container login_page">
        <div className="wrap-login100">
          <form role="form" method="POST" className="login100-form reset-form">
            <div className="text-center login-logo mb-4">
              <img src={images.logo} className="logo"/>
              <p style={{fontSize:'24px'}}> Reset password </p>
            </div>
            <div className="form-group col-md-11 col-11 form-custom popup-custom-height p-0">					
              <input className="form-control" 
                id="password" 
                placeholder="Temporary Password"
                type={show_old_pasword?'text':'password'}
                name="old_password" 
                defaultValue={old_password} 
                onChange={this.onChange.bind(this)}  
              />
              <img className="input_eye" src={show_old_pasword?images.eyeShow:images.eyeHide} alt=""  onClick={this.togglePassword.bind(this,"show_old_pasword")}></img>
              <InputError show={passwordValidation.old_password.isInvalid} message="Temporary Password is required" />
            </div>

            <div className="form-group col-md-11 col-11 form-custom popup-custom-height p-0">							
                <input className="form-control"
                  type={show_new_pasword?'text':'password'} 
                  id="new-password" 
                  placeholder="New Password"
                  name="new_password"
                  onChange={this.onChange}
                  value={new_password}
                />
                <img className="input_eye" src={show_new_pasword?images.eyeShow:images.eyeHide} alt=""  onClick={this.togglePassword.bind(this,"show_new_pasword")}></img>
                <InputError show={passwordValidation.new_password.isInvalid} message={passwordValidation.new_password.message} />
                <div className="input-group-undefined">
                  <div className="customtooltip">
                    <div className="d-flex"><label className="button">?</label></div>
                    <span className="customtooltiptext" style={{visibility:(passwordValidation.new_password.message.length > 0)?"visible":""}}>
                    <ul>
                        <li>Password minimum length is 6 character.</li>
                        <li>It must contain 1 letter. </li>
                        <li>It must contain 1 number. </li>
                        <li>It must contain 1 special character. </li>
                    </ul>
                    </span>
                  </div>
                </div>
            </div>
                
            <div className="form-group col-md-11 col-11 form-custom popup-custom-height p-0">							
              <input className="form-control" 
                type={show_confirm_pasword?'text':'password'}
                id="confirm-password" 
                placeholder="Confirm New Password"
                name="confirm_password"
                onChange={this.onChange}
                value={confirm_password}
              />
              <img  className="input_eye" src={show_confirm_pasword?images.eyeShow:images.eyeHide} alt=""  onClick={this.togglePassword.bind(this,"show_confirm_pasword")}></img>
              <InputError show={passwordValidation.confirm_password.isInvalid} message={passwordValidation.confirm_password.message}/>
            </div>

            <div className="col-md-11 col-11 p-0">
              <button type="submit" 
                onClick={this.onSubmit.bind(this)} 
                disabled = {isLoading} 
                className="btn common_btn blue_btn big_btn" role="button">
                  Reset Password 
              </button>
            </div>
          </form>
          <Loader isLoading={isLoading}/>
        </div>
      </div>
    )
  }
}
