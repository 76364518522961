import React, { Component } from "react";
import { Link } from "react-router-dom";
import InputError from "../components/Error/InputError";
import Rules from "../Helpers/FormRules";
import FormValidator from "../Helpers/FormValidator";
import { errorToast, successToast } from '../components/Toast/Toast.js';
import { createFeed, getFeedList, updateFeed } from '../Actions/feed';
import * as _ from "lodash";
import Loader from '../components/Loader/Loader.jsx';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css';

class AddFeed extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(Rules.AddFeed);
    this.state = {
        validation: this.validator.valid(),
        message:"",
        isLoading: false,
        edit_mode: false,
        feed_id: "",
        config : {
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            toolbarSticky: false,
            requestWithCredentials: true,
            imagePasteProcess: true,
            imagePaste: true,
            requestWithCORS: false,
            height: 200,
            pluginsEnabled: ['align'],
            toolbarButtons:{

                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
                    align: 'left',
                    buttonsVisible: 2
                },

                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                    align: 'left',
                    buttonsVisible: 0
                },

                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                    align: 'left',
                    buttonsVisible: 0
                },

                moreMisc: {
                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                    align: 'right',
                    buttonsVisible: 2
                },

                imageUploadURL: 'attachment/upload',
                imageUploadParams: { type: 'image' },
                imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            }
        }    
      }  
      this.formsubmitted = false;
    };

    componentDidMount() {  
        this.getFeedData(); 
    }

    getFeedData(){
        this.setState( { isLoading: true });
        const params = {
            "search": "",
            "page": 1,
            "limit": 10,
            "sort_by": {
                "name": "message",
                "order": "ASC"
            }
        }
        getFeedList(params).then((response)=>{
            const result = response.data.success;
            let mode = false;
            let message = '';
            let feedId = '';
            if(result&&result.data&&Array.isArray(result.data.infoList)&& result.data.infoList.length>0) {
                mode= result.data.infoList[0].message.length>0? true:false
                message= result.data.infoList[0].message;
                feedId= result.data.infoList[0].id;
            }
            this.setState({
                message: message,
                edit_mode: mode,
                feed_id: feedId,
                isLoading: false
            });
        }).catch(error =>{
            if (error.response) {
                const errorResult = error.response.data.error;
                this.setState({err:true,message:'Server Error!', isLoading:false});
                errorToast(errorResult.message);
            } 
        });
    }
    
    onChange(e){
        const { name, value } = e.target;
        this.setState({[name]: value});
    }

    onSubmit = e => {
        e.preventDefault();
        const { message, edit_mode, feed_id } = this.state;
        const validation = this.validator.validate(this.state);
        this.setState( { validation, isLoading: true }, () =>{
            this.submitted = true;
            if (validation.isValid) {
                const apiMethod = (edit_mode) ? updateFeed({ message }, feed_id): createFeed({ message });
                
                apiMethod.then((response) =>{
                    let result = response.data;
                    this.setState( { isLoading: false }, () =>{
                        if(result.success){
                            successToast(result.success.message);
    
                                this.getFeedData()
                        } else {
                            errorToast(result.error.message);
                        }
                    });
                }).catch((error)=> {
                    if (error.response) {
                        const errorResult = error.response.data.error;
                        this.setState({err:true,message:'Server Error!', isLoading:false});
                        errorToast(errorResult.message);
                    } 
                });
            } else {
              this.setState({ isLoading:false });
            }
        });
    }
  
    render() {
        const { message, isLoading, edit_mode } = this.state;
        let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
        return (
        <div className="agenda_session_form_section agenda_padding">  
            <div style={ {height:'95vh', overflow:"auto"}} className="mobile-scrollbar-height">
            <div className="col-md-12 p-0">
                <form className="col-md-12 p-0">
                    <div className="row m-0">
                        <div className="d-md-none col-12 mb-3 ">
                            <h2 className=" m-0 speakers_heading pt-3"> Create Page</h2>
                        </div>
                        {/* <div className="col-md-12 p-0">
                            <div className="form-group col-md-12 form-custom custom-height">
                                <label htmlFor="inputEmail4" className="left_label mb-3"> Page Title <span className="text-danger">*</span> </label>
                                <input className="form-control" 
                                    id="inputEmail4" 
                                    placeholder=""
                                    type="text" 
                                    name="title"
                                    value={ title }
                                    onChange={ this.onChange.bind(this) }
                                    tabIndex="1"
                                    autoFocus
                                />
                                <InputError show={validation.title.isInvalid} message={validation.title.message} />
                            </div>
                        </div> */}

                        {/* <div className="form-group col-md-12 form-custom">
                            <label htmlFor="inputEmail4" className="left_label mb-3"> Page Content <span className="text-danger">*</span> </label>
                            <FroalaEditor
                                config = {this.state.config} 
                                model={ message }
                                onModelChange={ this.handleModelChange.bind(this) }
                            />
                            <InputError show={validation.message.isInvalid} message={validation.message.message} />
                        </div> */}
                        <div className="form-group col-md-12 form-custom">
                            <label htmlFor="inputEmail4" className="left_label mb-3"> Feed Content <span className="text-danger">*</span> </label>
                            <textarea className="form-control" 
                                id = "exampleFormControlTextarea1" 
                                rows = "10" 
                                // maxLength = {400}
                                tabIndex = "2"
                                name = "message"
                                placeholder ="Type something" 
                                value = {message}
                                onChange={this.onChange.bind(this)}
                            >
                            </textarea>
                            <InputError show={validation.message.isInvalid} message={validation.message.message} />
                        </div>


                        {/* <div className="col-md-12 center_bottom_bar mt-2 mb-4">
                            <button  className="btn common_btn blue_btn big_btn fixed_width_btn" 
                                role="button" 
                                type="button"
                                onClick={this.onSubmit.bind(this)}
                                disabled = { isLoading }
                                tabIndex="9"
                            > { edit_mode == true ? "Update" : "Save"}
                            </button>
                            <button  className="btn common_btn gray_btn big_btn fixed_width_btn "
                                role="button"
                                type="button"
                                onClick={ () => { this.props.history.push("/admin/pages/page-list");} }
                            >
                                Cancel 
                            </button>
                        </div> */}
                    </div>
                    <div className="col-md-12 mt-3 m-mb-25">
                    <div className="col-md-6 pl-0 m-pr-0">
                        <div className="row">
                            <div className="col-md-6 col-6 m-pr-7">
                                <button  className="btn common_btn blue_btn big_btn fixed_width_btn" 
                                    role="button" 
                                    type="button"
                                    onClick={this.onSubmit.bind(this)}
                                    disabled = { isLoading }
                                    tabIndex="9"
                                > { edit_mode == true ? "Update" : "Save"}
                                </button>
                            </div>    
                            <div className="col-md-6 col-6 m-pl-7">
                                <button  className="btn common_btn gray_btn big_btn fixed_width_btn "
                                    role="button"
                                    type="button"
                                    onClick={ () => { this.getFeedData()} }
                                >
                                    Cancel 
                                </button>
                            </div>    
                        </div>       
                    </div>
                    </div>
                </form>	
            </div>
            </div>
            <Loader isLoading={isLoading}/>
        </div>
        );
    }
}

export default AddFeed;
