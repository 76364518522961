 const images = {
    logo: require('../../Assets/images/favicon.png'),
    iPadLogo: require('../../Assets/images/logo4iPad.png'),
    tournament:require('../../Assets/images/tournament.svg'),
    tournamentActive:require('../../Assets/images/tournament_active.svg'), 
    match:require('../../Assets/images/matche.svg'),
    matchActive:require('../../Assets/images/matche_active.svg'),
    faq:require('../../Assets/images/faq.svg'),
    faqActive:require('../../Assets/images/faq_active.svg'),
    pages: require('../../Assets/images/pages.svg'),
    pagesActive: require('../../Assets/images/pages_active.svg'),
    
    newsfeed: require('../../Assets/images/news_feed.svg'),
    newsfeedActive: require('../../Assets/images/news_feed_active.svg'),    
    // agenda: require('../../Assets/images/agenda.svg'),
    // agendaActive: require('../../Assets/images/agenda_active.svg'),
    // reports: require('../../Assets/images/reports.svg'),
    // reportsActive: require('../../Assets/images/reports_active.svg'), 
    users: require('../../Assets/images/users.svg'),
    usersActive: require('../../Assets/images/users_active.svg'), 
    // admin: require('../../Assets/images/admin.svg'),
    // adminActive: require('../../Assets/images/admin_active.svg'), 
    logout: require('../../Assets/images/logout.svg'),
    settings: require('../../Assets/images/settings.svg'),
    settingsActive: require('../../Assets/images/settings_active.svg'),
    search: require('../../Assets/images/serach.svg'),
    clock: require('../../Assets/images/time.svg'),
    clockActive: require('../../Assets/images/time_active.svg'),
    // hall: require('../../Assets/images/hall.svg'),
    // hallActive: require('../../Assets/images/hall_active.svg'),    
    // seat: require('../../Assets/images/seats.svg'),
    // seatActive: require('../../Assets/images/seat_active.svg'),
    slate: require('../../Assets/images/slate.svg'),
    tag: require('../../Assets/images/tag.svg'),
    arrow: require('../../Assets/images/arrow.svg'),
    editIcon: require('../../Assets/images/edit.svg'),
    edit2: require('../../Assets/images/edit2.svg'),
    deleteIcon: require('../../Assets/images/delete.svg'),
    importIcon: require('../../Assets/images/import.svg'),
    downloadIcon: require('../../Assets/images/download.svg'),
    crossIcon: require('../../Assets/images/white_cross.svg'),
    eyeHide: require('../../Assets/images/hide.svg'),
    eyeShow: require('../../Assets/images/show.svg'),
    userImg: require('../../Assets/images/user.png'),
    arrow: require('../../Assets/images/arrow.svg'),
    tableBottomArrow: require('../../Assets/images/table_bottom_arrow.svg'),
    tableTopArrow: require('../../Assets/images/table_top_arrow.svg'),
    BiguserImg: require('../../Assets/images/user-big.png'),
    close2: require('../../Assets/images/close2.svg'),
    email: require('../../Assets/images/email.svg'),
    call: require('../../Assets/images/call.svg'),
    bigHall: require('../../Assets/images/big-hall.svg'),
    uploadIcon: require('../../Assets/images/upload.svg'),
    blueArrow: require('../../Assets/images/blue_arrow.svg'),
    logoAdminProfile: require('../../Assets/images/logo.png'),
    squareImg: require('../../Assets/images/square.png'),
    emptyImg: require('../../Assets/images/empty_img.svg'),
    emptyProfile: require('../../Assets/images/empty_profile.png'),
    sliderArrow: require('../../Assets/images/slider-arrow.svg'),
    checkedImg: require('../../Assets/images/checked-img.svg'),
    unCheckedImg: require('../../Assets/images/unchecked-img.svg'),
    refreshIcon: require('../../Assets/images/refresh.svg'),
    caution: require('../../Assets/images/caution.svg'),
    contactUs: require('../../Assets/images/contact-us.svg'),
    questionActive: require('../../Assets/images/question_active.svg'),
    question: require('../../Assets/images/question.svg'),

    // mobile-responsive
    openMenu: require('../../Assets/images/open-menu.png'),

    

}
export default images;
