import Request from '../Helpers/API';

export const getMatchNMultiMatch = (params) => {
  
    const api_url = `admin/active-match-list`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};

export const sendNotification = (params) => {
  
    const api_url = `admin/send-notification`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};