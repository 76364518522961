import React, { Component } from "react";
import { NavLink ,Link} from "react-router-dom";
import images from "../ImagesUrl/imagesUri"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      closeNAvbar:""
    };
  }

  activeRoute(routeName) {
    let lastPart = routeName.split('/');
    lastPart = `${lastPart[lastPart.length - 1]}`;
    routeName = routeName.slice(0, routeName.length-lastPart.length);
    return this.props.location.pathname.search(routeName) > -1 ? "active" : "";
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  onSidebarClick = () => {
    this.setState({closeNAvbar: "none"})
    
  }

  openSidebar = () => {
    let {closeNAvbar} = this.state;
    if(closeNAvbar ==="none") {
      this.setState({closeNAvbar: "block"})
    }else if(closeNAvbar ==="block") {
      this.setState({closeNAvbar: "none"})
    }
  }

  render() {
    const sidebarBackground = {
      backgroundColor: '#292722'
    };
    let {closeNAvbar} = this.state;
    return (
    <Navbar bg="green" expand="sm" className="mobile_nav ">
    <div className="row m-0 d-md-none align-center">
      <div className="col-2 p-0">
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.openSidebar}/>
        
      </div>
      <div className="col-8 text-center p-0">
        <Navbar.Brand  className="m-0 m-p-0"><img src={images.logo} className="logo"/></Navbar.Brand>
      </div>
      {/* <div className="col-2 text-right p-0">
        <Nav.Link href="#" className="btn blue_btn common_btn create_post" >+</Nav.Link>
      </div> */}
     
    </div>
   
    {/* <div className="row m-0">
                  <div className="col-md-5 col-10 p-0"><img src={images.openMenu} className="mr-2"/>
                     <img src={images.logo} className="logo"/>
                  </div>
                    <div className=" p-0  col-2 text-right">
                        <a className="btn blue_btn common_btn create_post ml-auto" role="button" href="/admin/match/add-match">+
                        </a>
                    </div>
                  <div className="pr-7 pl-0  col-6 mt-2">
                        <div className="search col d-flex">
                            <img alt="img" src={images.search}/>
                            <input className="form-control" type="text" placeholder="Search Match" aria-label="Search"/>
                        </div>
                  </div>
                  <div className=" assign_in_tags common_dropdown form-group form-custom m-0 col-6 pr-0 pl-7 mt-2">
                     <div className=" css-2b097c-container">
                        <div className=" css-yk16xz-control">
                           <div className=" css-1hwfws3">
                              <div className=" css-1uccc91-singleValue">ALL</div>
                              <div className="css-1g6gooi">
                                
                              </div>
                           </div>
                           <div className=" css-1wy0on6"><span className="css-1okebmr-indicatorSeparator"></span></div>
                        </div>
                        <input name="tournament_id" type="hidden" value=""/>
                     </div>
                  </div>
               </div> */}
    <Navbar.Collapse id="basic-navbar-nav" style={{display:closeNAvbar}}>
      <div
        id="sidebar"
        className="left_section_div p-40"
      >
      <h4> 
        <img src={(images.logo)} className="logo ipad-d-none" />
        <img src={(images.iPadLogo)} className="iPadlogo img-fluid " style={{display:'none'}}/>

         {/* <span> Bet Tennis </span> */}
      </h4>
      <ul className="list-unstyled">
          {this.props.routes.map((prop, key) => {
              let icon_img = prop.icon;
              let active_class = 'mb-5 ';
              if (this.activeRoute(prop.layout + prop.path)) {
                  icon_img = prop.active_icon;
                  active_class += 'active';
              }
            if (!prop.redirect)
              return (
                <li
                  className= {active_class}
                  key={key}
                  onClick={this.onSidebarClick}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    activeClassName="active"
                  >
                    <img src={(icon_img)} className=""/>
                    <span >{prop.name}</span>
                  </NavLink>
                </li>
                
              );
              
            return null;
          })}
          <li><Link to="/admin/logout"><img src={images.logout} alt="" className=""/><span>Logout</span></Link></li>
        </ul>
      </div>
      </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Sidebar;
