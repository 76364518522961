import axios from 'axios';
import { SERVER_URL } from './Config';
import {ACCESS_API_KEY} from '../Helpers/Config.js'
import { getToken, removeToken, removeUser } from '../Helpers/Token.js';

class Request {

    constructor(api_url, params){
        this.url = SERVER_URL+'/'+api_url;
        this.params = params;
        this.token =  getToken();
        this.header =  { headers: { Authorization: this.token, "ACCESS-API-KEY" : ACCESS_API_KEY }};
    }

    async get(){
        const response = await this.checkAuth();
        if(response){
            return axios.get(this.url, this.header);
        }
    }

    async post(){
        const response = await this.checkAuth();
        if(response){
            return axios.post(this.url,this.params, this.header);
        }
    }

    async patch(){
        const response = await this.checkAuth();
        if(response){
            return axios.patch(this.url,this.params,this.header);
        }
    }

    async put(){
        const response = await this.checkAuth();
        if(response){
            return axios.put(this.url,this.params,this.header);
        } 
    }

    async delete(){
        const response = await this.checkAuth();
        if(response){
            return axios.delete(this.url,this.header);
        }
    }

    async checkAuth(){
       return new Promise((resolve, reject ) =>{
        if (this.url === `${SERVER_URL}/admin/login` || this.url === `${SERVER_URL}/admin/forget-password` || this.url === `${SERVER_URL}/admin/reset-password`) {
            resolve(true);
        } else {
                axios.get(`${SERVER_URL}/admin/validate-token`,this.header).then((response)=>{
                    const result = response.data;
                    if(result.success){ 
                        resolve(true);
                    } else {
                        setTimeout(() => {
                            removeToken();
                            removeUser();
                            window.location.replace("/");
                        }, 500);
                    } 
                }).catch((error)=>{
                    if (error.response) {
                        setTimeout(() => {
                            removeToken();
                            removeUser();
                            window.location.replace("/");
                        }, 500);
                    } 
                });
            } 
        });
    }
    
}

export default Request;