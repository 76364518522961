const ModalContent = {
    "DEL_USER":{
        header: "Delete User",
        paragraph: "",
        button: "Delete",
        per_del: true,
        per_del_msg: "Do you want to remove the account permanently?",
        img: true,
    },

    "DEL_MATCH":{
        header: "Delete Match",
        paragraph: "",
        button: "Delete",
        // per_del: true,
        // per_del_msg: "Do you want to remove the account permanently?",
        img: true,
    },

    "HARD_DEL_MATCH":{
        header: "Delete Match",
        paragraph: 'It would remove the Match permanently and all the associated matches data. It cannot be Undo. Please click on "Delete" to proceed.',
        button: "Delete",
        img: true,
    },

    "REACTIVATE_MATCH":{
        header: "Restore Match",
        paragraph: "",
        button: "Restore",
    },

    "DEL_TOURNAMENT":{
        header: "Delete Touranament",
        paragraph: "",
        button: "Delete",
        // per_del: true,
        // per_del_msg: "Do you want to remove the account permanently?",
        img: true,
    },

    "HARD_DEL_TOURNAMENT":{
        header: "Delete Touranament",
        paragraph: 'It would remove the Tournament permanently and all the associated matches data. It cannot be Undo. Please click on "Delete" to proceed.',
        button: "Delete",
        img: true,
    },

    "RESTORE_TOURNAMENT":{
        header: "Restore Tournament",
        paragraph: "",
        button: "Restore",
    },

    "HARD_DEL_USER":{
        header: "Delete User",
        paragraph: 'It would remove the user account permanently and all the associated posts data with the account. It cannot be Undo. Please click on "Delete" to proceed.',
        button: "Delete",
        img: true,
    },

    "REACTIVATE_USER":{
        header: "Reactivate User",
        paragraph: "",
        button: "Reactivate",
    },

    "DEL_ADMIN":{
        header: "Delete Admin Account",
        paragraph: "",
        button: "Delete",
        per_del: true,
        per_del_msg:"Do you want to remove the account permanently?",
        img: true,
    },

    "HARD_DEL_ADMIN":{
        header: "Delete Admin Account",
        paragraph: 'It would remove the admin account permanently and all the associated posts data with the account. It cannot be Undo. Please click on "Delete" to proceed.',
        button: "Delete",
        img: true,
    },

    "REACTIVATE_ADMIN":{
        header: "Reactivate Admin Account",
        paragraph: "",
        button: "Reactivate",
    },

    "DEL_TAG":{
        header: "Delete Tag",
        paragraph: "",
        button: "Delete",
        per_del: false,
    },

    "DEL_CATEGORY":{
        header: "Delete Category",
        paragraph: "",
        button: "Delete",
        per_del: false,
    },

    "DEL_ROOM":{
        header: "Delete Room",
        paragraph: "",
        button: "Delete",
        per_del: false,
    },

    "DEL_FAQ":{
        header: "Delete Question",
        paragraph: "",
        button: "Delete",
        per_del: true,
        per_del_msg:"Do you want to remove the question permanently?",
        img: true,
    },

    "HARD_DEL_FAQ":{
        header: "Delete Question",
        paragraph: "",
        button: "Delete",
        img: true,
    },

    "REACTIVATE_FAQ":{
        header: "Reactivate Question",
        paragraph: "",
        button: "Reactivate",
    },

    "DEL_PAGE":{
        header: "Delete Page",
        paragraph: "",
        button: "Delete",
        per_del: true,
        per_del_msg:"Do you want to remove the page permanently?",
        img: true,
    },

    "HARD_DEL_PAGE":{
        header: "Delete Page",
        paragraph: "",
        button: "Delete",
        img: true,
    },

    "REACTIVATE_PAGE":{
        header: "Reactivate Page",
        paragraph: "",
        button: "Reactivate",
    },

    "DEL_PLAYER":{
        header: "Delete Player",
        paragraph: "Do you want to remove the player?",
        button: "Delete",
        img: true,
    },
    
    "DEL_COUNTRY":{
        header: "Delete Country",
        paragraph: "Do you want to remove the country?",
        button: "Delete",
        img: true,
    },
    
}   

export default ModalContent;