export const BASE_URL = 'https://api.bettennisapp.com:3001';

export const SERVER_URL = `${BASE_URL}`;

export const ENVIRONMENT  = 'production';

export const APP_VERSION = '1.0';

export const ENC_KEY = 'BetTennisApp@#%$dev%123';

export const ACCESS_API_KEY = 'Av76BwvWXZ-xK%40VX_EL$@gr_pj/?W8Ue?=RR&ZtJK6deAkZuzT?Dw#Fv+ST?2?D6f^d$PBDP';

export const CRYPTO_ALGO = 'aes-256-ctr';

export const CRYPTO_SECRET = 'Bet_Tennis_App';

export const RADAR_API_KEY = "udt64ay37e3qpnppymys9gcw";

export const RADAR_BASE_URL = "https://api.sportradar.com/tennis-t2/en";
