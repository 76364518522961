import React, { Component } from 'react';
import images from '../components/ImagesUrl/imagesUri';
import { getMatchList, softDeleteMatch, hardDeleteMatch,getMatchDetails, updateStatus, getAllTounament,restoreMatch} from '../Actions/match.js';
import { agoTime } from '../Helpers/TimeConversion';
import { errorToast, successToast } from '../components/Toast/Toast.js';
import { Link } from "react-router-dom";
import TextFeed from 'components/Matches/TextFeed';
import TextImageFeed from 'components/Matches/TextImageFeed';
import Loader from '../components/Loader/Loader.jsx';
import InfiniteScroll from "react-infinite-scroll-component";
import Styles from "../Helpers/Select-Styles.js";
import Select from 'react-select';
import * as _ from "lodash";
import AvtarTextFeed from 'components/Matches/AvtarTextFeed';
import DeleteModal from 'components/Modal/DeleteModal';
import { createMultipleMatch, updateMultipleMatch, getMatchesList,softDeleteMultiMatch ,updateMultiMatchStatus,restoreMultiMatch,hardDeleteMultiMatch} from '../Actions/multipleMatch.js';
import MultipleMatches from 'components/Multiple Matches/MultipleMatches';
import Template from "../Helpers/Raw-Data.js";

const scrollableNodeRef = React.createRef();
let charLength = "";
class MultiMatchesList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 500,
      isLoading: false,
      simplebarHeight: '',
      matches : [],
      page: 1,
      limit: 20,
      selectedMatch: null,
      hoverMatchId: null,
      top_bar_status: 1,
      search: '',
      modalIsOpen: false,
      deleted_id:[],
      modal_for: 'DEL_MATCH',
      selectedMatchData: null,
      total_matches: 0,
      tournament_list: [],
      isShowTounamentPlaceholder: true,
      tournament_id: '',
      match_status:''
    }
  }

  componentDidMount() {
    const height = this.divElement.clientHeight;
    let deviceHeight = window.innerHeight;
    const simplebarHeight = deviceHeight-height-80;
    this.setState({ height ,simplebarHeight}, () => {
      this._matchesAPI(1);
    });
    this.getTounaments();
    if(this.props.location.state){
      this.setState({tournament_id: this.props.location.state.id});
    }
  }

  _hoverMatch = (tournament) => {
    this.setState({ hoverMatchId: tournament.id });
  }

  _searchMatches(e){
    const {value} = e.target;
    this.setState({ search: value }, () => {
      this._matchesAPI(1);
    });
  }

  _selectedMatch  = (match) => {
    if(match) {
      this.setState({selectedMatch: match.id});
      // this.setState({isLoading:true});
      // getMatchDetails(match.id).then((response)=>{
      //   const result = response.data.success;
      //   this.setState({
      //     selectedMatch: match.id,
      //     selectedMatchData: result.data,
      //     isLoading: false,
      //   });
      // }).catch((error)=>{
      //   if (error.response) {
      //     const errorResult = error.response.data.error;
      //     errorToast(errorResult.message);
      //   } 
      // });
    } else {
      this.setState({
        selectedMatch: null,
        selectedMatchData: null,
        // matchCreator: null,
        isLoading:false,
      });
    }
  }

  _changeHeader  = (status) => {
    this.setState({ top_bar_status: status }, () => {
      this._matchesAPI(1);
    });
  }
  
  _statusChange = (key, data) => {
    let { matches, selectedMatch, top_bar_status } = this.state;   
    updateMultiMatchStatus(data.id, {
      is_active: (top_bar_status == 1)? false : true,
    }).then((response)=>{
      const successResult = response.data.success;
      if(top_bar_status != 3){
        matches.splice(key,1);
        if(matches.length > 0){
          const newKey = (key > 0) ? key - 1 : 0 ;
          selectedMatch = (matches[key])? matches[key] : (matches[newKey])? matches[newKey] : null ;
          selectedMatch.match_key = newKey;
        } else {
          selectedMatch = null;
        }  
        successToast(successResult.message);
        this.setState({ matches },()=>{
          this._selectedMatch(selectedMatch);
        });
      } else {
        successToast(successResult.message);
        matches[key].is_active = !data.is_active;
        this.setState({matches},()=>{
          this._selectedFeed(matches[key]);
        });
      }  
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  }

  onDeleteMatch = () => {
    let { matches, selectedMatch ,deleted_id} = this.state;   
    this.setState({modalIsOpen:false});
    softDeleteMultiMatch(deleted_id).then((response)=>{
      const successResult = response.data.success;
      if(successResult){
        successToast(successResult.message);
        this.setState({deleted_id:""});
        setTimeout(() => {
            this._matchesAPI(1);
        },1000);
      } else {
        const errorResult = response.data.error;
        errorToast(errorResult.message);
      }
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  }

  onHardDeleteMatch = () => {
    let { matches, selectedMatch ,deleted_id} = this.state;   
    this.setState({modalIsOpen:false});
    hardDeleteMultiMatch(deleted_id).then((response)=>{
      const successResult = response.data.success;
      if(successResult){
        successToast(successResult.message);
        this.setState({deleted_id:""});
        setTimeout(() => {
            this._matchesAPI(1);
        },1000);
      } else {
        const errorResult = response.data.error;
        errorToast(errorResult.message);
      }
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  }

  onRestoreMatch = () => {
    let { matches, selectedMatch ,deleted_id} = this.state;   
    this.setState({modalIsOpen:false});
    restoreMultiMatch(deleted_id).then((response)=>{
      const successResult = response.data.success;
      if(successResult){
        successToast(successResult.message);
        this.setState({deleted_id:""});
        setTimeout(() => {
            this._matchesAPI(1);
        },1000);
      } else {
        const errorResult = response.data.error;
        errorToast(errorResult.message);
      }
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  }

  _matchesAPI = (page = 1) => {
    let { limit, top_bar_status, search, selectedMatch, matches, tournament_id } = this.state;
    if(search === undefined || search == null || search == ""){
      this.setState({isLoading:true})
    }
    this.setState({page: page},()=>{
        getMatchesList({
        search,
        page,
        limit,
        status: top_bar_status,
        // tournament_id,
        sort_by : { "name":"date_and_time", "order":"DESC" }
      }).then((response)=>{
        const successResult = response.data.success;
        const match = successResult.data.multipleMatchesAndTipsList;
        // console.log("matches successResult",successResult)
        if (page === 1) {
          if(match.length > 0){
            selectedMatch = match[0];
            selectedMatch.match_key = 0;
          } else {
            selectedMatch = null;
          }
          matches = [];
        }
        // setTimeout(() => {
          this.setState({
            search, 
            matches: [...matches, ...match],
            total_matches: successResult.data.total_matches,
            isLoading:false,
            page: page + 1 
          }, () => {
            if(page == 1){this._selectedMatch(selectedMatch)};
          });
        // }, 1000);
      }).catch((error)=>{
        if (error.response) {
          const errorResult = error.response.data.error;
          errorToast(errorResult.message);
          this.setState({isLoading:false});
        } 
      });
    });
  }

  openModal(modalType , e){
    document.body.style.overflow = 'hidden';
    let { deleted_id } = this.state;
    if(modalType === "delete"){
      deleted_id = (e && e.target.id)? [e.target.id] :'';
      this.setState({ modalIsOpen: true ,deleted_id,modal_for:'DEL_MATCH'});
    }else if(modalType == "hard-deleted") {
      this.setState({ modalIsOpen: true, modal_for: "HARD_DEL_MATCH", deleted_id: [e.target.id] });
    }else if(modalType == "reactivated"){
      this.setState({ modalIsOpen: true, modal_for: "REACTIVATE_MATCH", deleted_id: [e.target.id] });
    }
  }

  closeModal = () =>{
    document.body.style.overflow = 'unset';
    this.setState({ modalIsOpen:false});
  }
  _setCharacterLength = (divWidth) => {
    if(divWidth > 1700) {
      charLength=400;
    }else if(divWidth >= 1600) {
      charLength= 390;
    }else if(divWidth >= 1500) {
      charLength = 380;
     
    }else if(divWidth >= 1400) {
        charLength = 340;
      
    }else if(divWidth >= 1300) {
        charLength = 305;
      
    }else if(divWidth >= 1200) {
        charLength = 250;
      
    }else if(divWidth >= 1100 ) {
      charLength = 200;
      
    }else if(divWidth >= 1000 ) {
      charLength =200;
    }else {
        charLength = 400
    }
  //  }else if(divWidth >= 900) {
  //     charLength =150;
     
  //  }else if(divWidth >= 800) {
  //   charLength =140;
  //  }
  //  }else if(divWidth >= 700) {
  //   charLength =150;
   
  //  }else if(divWidth >= 600) {
  //   charLength =400;
   
  //  }else if(divWidth >= 500) {
  //   charLength =130;
   
  //  }else if(divWidth >= 400) {
  //   charLength =320;
   
  //  }else if(divWidth >= 300) {
  //   charLength =270;
   
  //  }

  }

  _render_matches = (matches) => {
    const { hoverMatchId, search, selectedMatch, modal_for } = this.state;
    
    let box = document.querySelector('div');
    let divWidth = box.offsetWidth;
    console.log("divWidth",divWidth);
    if(divWidth > 0){
     this._setCharacterLength(divWidth); 
    }
    console.log("char length",charLength);
    
    if(matches.length > 0) {
      return matches.map((match, key) => { 
        const tournamentName = (match.tournaments)? match.tournaments.title : "";
        let possibleWinner = (match.possible_winner)? match.possible_winner.player_name.split(' ') : [];
        possibleWinner = (possibleWinner.length > 0)? `${possibleWinner[0].charAt(0)}. ${possibleWinner[1] }` : "";
        const matchInfo = (match.description.length < charLength)? match.description : match.description.substr(0,charLength);
        const readMore = (match.description.length > charLength) ? <Link to= {{pathname: "edit-multiple-match", state: match }} style={{fontSize:'14px'}}>read more...</Link> : "";
        return(
        <div className="row multiple_matches_section" key={key} style={{border: "1px solid #4aa546"}} id="div">
            <div className="col-md-12 multiple_matches_header m-p-0">
              <div className="new_right_part text-right ml-auto d-flex align-center">
                  { match.is_deleted === true ? 
                    <div>
                      <Link to="#" data-toggle="tooltip" title="Restore" data-placement="auto">
                        <img src={images.refreshIcon} alt="restore" width="25px" id={match.id}  onClick = { this.openModal.bind(this,"reactivated") }/> 
                      </Link>
                    </div>: 
                    <>
                      <label className="switch small">
                          <input 
                            type="checkbox" 
                            onChange={() => this._statusChange(key, match)}
                            checked={(match.is_active)? true : false}
                          />
                          <span className="slider round small"></span>
                      </label>
                      <div>
                        <Link to= {{pathname: "edit-multiple-match", state: match }}>
                            <img src={images.editIcon} alt="edit"/> 
                        </Link>
                      </div>
                    </>
                  }  
                  <div className="cross_btn">
                    <Link to="#">
                      <img 
                        alt="img"
                        src={require("../Assets/images/close2.svg")}
                        className="close-icon"
                        id={match.id}
                        key={key}
                        onClick={(match.is_deleted === true)?this.openModal.bind(this,"hard-deleted"):this.openModal.bind(this,"delete")}
                        // onClick={() => this.onDeleteMatch(key, match)}
                      />
                    </Link>
                  </div>
                </div>
            </div>
            <div className="col-md-4 pl-0 m-p-0 ipad-pr-0">
                <div className="matches_list list active relative ">
                  <div className=" align-center d-flex">
                      <div className=" new_left_part d-flex align-center">
                          <div>
                            <h5 className="m-0">Multiple Selection</h5>
                          </div>
                      </div>
                  </div>
                  <div className=" pt-3 bidder-name">
                      <div className="row m-0 bidder-name">
                          <p className="col-md-8 p-0 col-8">{match.matchesIdsForMultipleMatches.length} selection @ {match.tip_rate} </p>
                          <div className="col-md-4  circules_section text-right p-0 col-4">
                              <ul className="pr-0 list-unstyled " style={{display:'inline-flex'}}>
                                <li className={(match.winning_chances == 1 ) ? "red active" : "red" } ></li>
                                <li className={(match.winning_chances == 2  ) ? "yellow active" : "yellow" } ></li>
                                <li className={(match.winning_chances == 3 ) ? "green active" : "green" }  ></li>
                              </ul>
                          </div>
                      </div>
                      {/* <p className="m-0">{match.matchesIdsForMultipleMatches.length} selection @ {match.tip_rate}</p> */}
                  </div>
                  <div className="new_second_part pt-2 new_second_part_two">
                    <p className="m-0">{ matchInfo } {readMore}</p>
                  </div>
                </div>
            </div>
            {/* { matchTitle.length > 0 && playerImages.length > 0 ? <TextImageFeed match ={match}/>:null }
            { matchTitle.length > 0 && playerImages.length === 0 ? <AvtarTextFeed matchData = {match} /> :null} */}
            {/* <AvtarTextFeed matchData = {match} /> */}
            {match.matchesIdsForMultipleMatches.length > 0 ? <MultipleMatches matchData={match.matchesIdsForMultipleMatches}/>:""}
        </div>)
      });
    } else {
      return <p>{(search.length > 0)?"No Match found !":"No Match Available !"}</p>;
    }
  }
  
  onSelect = (newValue, actionMeta) => {
    this.setState({ tournament_id: (newValue)? newValue.value : ""}, () =>{
      this._matchesAPI(1);
    });
  };

  getTounaments(){
    getAllTounament().then((response)=>{
      const result = response.data.success;
      result.data.map((val, key) =>{
          val.value = val.id;
          val.label = val.title;
          delete val.id;
          delete val.title;
      });
      result.data.unshift({value:"", label: "ALL"});
      this.setState({ tournament_list: result.data });
      // console.log("API response",result);
      
    }).catch(error =>{
      if (error.response) {
        const errorResult = error.response.data.error;
        this.setState({err:true,message:'Server Error!', isLoading:false});
        errorToast(errorResult.message);
      } 
    });
  }

  onMenuOpen( placeholderType, e){
    if(placeholderType == "tournament"){
      this.setState({ isShowTounamentPlaceholder: false });
    } 
  }

  onMenuClose(placeholderType ,e){
    this.setState({
      isShowTounamentPlaceholder: true, 
    });
  }

  onChangeStatus = (newValue, actionMeta) => {
    this.setState({ match_status: (newValue)? newValue.value : ""}, () =>{
      this._changeHeader(newValue.value)
    });
  };

  render(){  
    const { simplebarHeight, matches, top_bar_status, search, isLoading, page, total_matches, isShowTounamentPlaceholder, modalIsOpen,
      tournament_list, tournament_id, modal_for,match_status} = this.state; 
      let matchAlign ="";
      if(matches.length < 3) {
        matchAlign="left"
      }
    return ( 
      <>
        <div className="center_section_div p-30 pb-0">
          <div className="col-md-12 center_top_bar p-0" ref={ (divElement) => { this.divElement = divElement } }>
            <div className="row m-m-0">
              <div className="col-md-6 align-self-end m-sm-none ipad-d-none">
                <ul className="list-unstyled d-flex">
                  <li className={(top_bar_status === 1 ? "active" : "")}>
                    <label style = {{cursor:"pointer"}} onClick={()=> this._changeHeader(1)} > Published </label>
                  </li>
                  <li className={(top_bar_status === 2 ? "active" : "")} onClick={()=> this._changeHeader(2)} >
                    <label style = {{cursor:"pointer"}}> Unpbulished </label>
                  </li>
                  <li className={(top_bar_status === 3 ? "active" : "")} onClick={()=> this._changeHeader(3)}>
                    <label style = {{cursor:"pointer"}}> Archive </label>
                  </li>
                </ul>
              </div>
              {/* <div className="col-md-6 col-7 assign_in_tags common_dropdown form-group form-custom m-0 m-pl-0 m-sm-none xl-none"> */}
              <div className="col-md-6 col-7 assign_in_tags common_dropdown form-group form-custom m-0 m-pl-0 mb-2 xl-none">
                <Select 
                    isClearable = {false}
                    isSearchable={false}
                    onChange = { this.onChangeStatus }
                    options = { Template.match_status }
                    defaultValue={Template.match_status[0]}
                    value = { _.find(Template.match_status, (ele) => { return ele.id == match_status }) }
                    name = "tournament_id"
                    // onMenuOpen = {this.onMenuOpen.bind(this,"tournament")}
                    // onMenuClose = {this.onMenuClose.bind(this,"tournament")}
                    styles = { Styles.colourStyles }
                    menuPlacement= "auto"
                    components={{ DropdownIndicator:() => null }}
                  />
              </div>
              <div className="col-md-6 align-self-end d-flex m-p-0 mb-2">
                <div className="search col d-flex"> <img alt="img" src={images.search}/>
                  <input 
                    className="form-control" 
                    type="text" 
                    onChange={this._searchMatches.bind(this)} 
                    defaultValue={search} 
                    placeholder="Search Matches" 
                    aria-label="Search"
                  />
                </div>
                <Link to="create-match" className="btn blue_btn common_btn create_post ml-4 m-ml-15" role="button" >+ <span className="create m-sm-none ipad-d-none"> Multiple Matches </span></Link>

              </div>
              {/* <div className="col-md-3 assign_in_tags common_dropdown form-group form-custom m-0 ">
                <Select
                  isClearable = {false}
                  onChange = { this.onSelect }
                  options = { tournament_list }
                  placeholder = { (isShowTounamentPlaceholder)? "Select Tournament" : "" }
                  value = { _.find(tournament_list,(ele) => { return ele.value == tournament_id }) }
                  name = "tournament_id"
                  tabIndex = "3"
                  onMenuOpen = {this.onMenuOpen.bind(this,"tournament")}
                  onMenuClose = {this.onMenuClose.bind(this,"tournament")}
                  styles = { Styles.colourStyles }
                  menuPlacement= "auto"
                  components={{ DropdownIndicator:() => null }}
                />
              </div> */}
            </div>
          </div>
          <div className="newfeed_section row m-0 scrollbar-macosx" style={{textAlign:matchAlign}}>
            <InfiniteScroll
              dataLength={matches.length}
              next={this._matchesAPI.bind(this, page)}
              hasMore={(matches.length < total_matches)? true : false}
              height={simplebarHeight-10}
              style={{overflowX:"hidden", overflowY:"auto"}}
              className="pt-3"
            >
              {this._render_matches(matches)}
            </InfiniteScroll>
          </div>
        </div>
        <DeleteModal 
          modalIsOpen = { modalIsOpen }
          closeModal = { this.closeModal }
          onAction = { (modal_for === "DEL_MATCH") ? this.onDeleteMatch : (modal_for === "HARD_DEL_MATCH")? this.onHardDeleteMatch : this.onRestoreMatch }
          modalFor = { modal_for }
        />
        <Loader isLoading={isLoading}/>
      </>
    );
  }
}

export default MultiMatchesList;