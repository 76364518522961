import React, { Component } from 'react';

class TextImageFeed extends Component {
    constructor(props) {
        super(props)
    }
    selectTextImageFeed(feedData) 
    {
        
        
        let postTitle = feedData.title;
        let postImage = feedData.image; 

        if(postTitle.length < 50 )
        {
           
            return( this.singleLineTextWithSingleImg(feedData))

        }
        if(postTitle.length < 50 && postImage.length == 2 )
        {
           
            return( this.singleLineTextWithDoubleImg(feedData))

        }
        if(postTitle.length < 50 && postImage.length == 3 )
        {
           
            return( this.singleLineTextWithTripleImg(feedData))

        }
        if(postTitle.length > 50 && postImage.length > 0 )
        {
           
            return( this.multiLineTextWithMultiImg(feedData))


        }
    }
    singleLineTextWithSingleImg(feedData){
        return(
            <>
             <div className="new_second_part pt-3 new_second_part_one">
                <p className="m-0">{feedData.title}</p>
            </div>
            <div className="col-md-12 p-0 mt-3 news_third_part_one">
                <img alt="img" src={feedData.image} className="width_height_100 border-10"/>
            </div>
            </>
        )
    }
    singleLineTextWithDoubleImg(feedData){
        let postTitle = feedData.post_title;
        let postImages = feedData.post_images;

        return(

            <>
             <div className="new_second_part pt-3 new_second_part_one">
                <p className="m-0">{postTitle}</p>
            </div>
            <div className="col-md-12">
                <div className="row pt-3 news_third_part news_third_part_five">
                    <div className="col p-0 mr-7">
                        <img src={postImages[0]} className="border-10 width_height_100"/>
                    </div>
                    <div className="col p-0 ml-7">
                        <img src={postImages[1]} className="border-10 width_height_100"/>
                    </div>
                </div>
            </div>
            </>
        )
    }
    singleLineTextWithTripleImg(feedData){
        let postTitle = feedData.post_title;
        let postImages = feedData.post_images;
        return(
            <> 
             <div className="new_second_part pt-3 new_second_part_one">
                <p className="m-0">{postTitle}</p>
            </div>
            <div className="col-md-12">
                <div className="row pt-3 news_third_part news_third_part_three">
                    <div className="col p-0 mr-7">
                        <img src={feedData.post_images[0]} className="border-10 width_height_100"/>
                    </div>
                    <div className="col ml-3-5 mr-3-5 p-0">
                        <img src={feedData.post_images[1]} className="border-10 width_height_100"/>
                    </div>
                    <div className="col p-0 ml-7">
                        <img src={feedData.post_images[2]} className="border-10 width_height_100"/>
                    </div>
                </div>
            </div>
            </>
        )
    }
    multiLineTextWithMultiImg(feedData){
        let title = feedData.post_title;
        let postImages = feedData.post_images;

        title = (title.length > 50 ) ? title.substr(0,100)+'...' : title;
        return(
            <> 
            <div className="new_second_part pt-3 new_second_part_two">
                <p className="m-0">{title}</p>
            </div>
            {postImages.length ==1 ?
            <div className="col-md-12 p-0 mt-3 news_third_part_two">
                <img alt="img" src={postImages[0]} className="width_height_100 border-10"/>
            </div>:
            postImages.length == 2 ?
            <div className="col-md-12">
                <div className="row pt-3 news_third_part news_third_part_five">
                    <div className="col p-0 mr-7">
                        <img src={feedData.post_images[0]} className="border-10 width_height_100"/>
                    </div>
                    <div className="col p-0 ml-7">
                        <img src={feedData.post_images[1]} className="border-10 width_height_100"/>
                    </div>
                </div>
            </div>:
            postImages.length == 3 ? 
            <div className="col-md-12">
                <div className="row pt-3 news_third_part news_third_part_three">
                    <div className="col p-0 mr-7">
                        <img src={feedData.post_images[0]} className="border-10 width_height_100"/>
                    </div>
                    <div className="col ml-3-5 mr-3-5 p-0">
                        <img src={feedData.post_images[1]} className="border-10 width_height_100"/>
                    </div>
                    <div className="col p-0 ml-7">
                        <img src={feedData.post_images[2]} className="border-10 width_height_100"/>
                    </div>
                </div>
            </div>:''}
            </>
        )
    }
    render() {
        console.log("TextImageFeed ",this.props.feed);
        const { feed } = this.props;
        if(feed == undefined || feed ==null) {
            return 
        }
        return (
            <>
                {this.selectTextImageFeed(feed)}
            </>
        );
    }
}

export default TextImageFeed;