import React, { Component } from 'react'
import images from "../components/ImagesUrl/imagesUri";
import { Link } from "react-router-dom";
import InputError from "../components/Error/InputError";
import Rules from "../Helpers/FormRules";
import FormValidator from "../Helpers/FormValidator";
import { forgotPassword } from '../Actions/admin';
import { errorToast, successToast } from '../components/Toast/Toast.js';
import Loader from '../components/Loader/Loader.jsx';


export default class ForgotPassword extends Component {
    constructor(props) {
      super(props)
      this.validator = new FormValidator(Rules.forgotpassword);
      this.state = {
        email:'',
        isLoading:false,
        validation: this.validator.valid(),
      }
      this.formsubmitted = false;
    }

    onChange(e){
      const {name, value} = e.target;
      this.setState({[name]: value.trim()});
    }
  
    onSubmit(e){
      e.preventDefault();
      const { email } = this.state;
      const validation = this.validator.validate(this.state);
      this.setState({ validation, isLoading:true, });
      this.submitted = true;
      if (validation.isValid) {
        forgotPassword({
          email, 
        }).then(response => {
          if(response.data.success){
            successToast(response.data.success.message);
            this.setState({ showForget:false, isLoading: false },()=>{
              this.props.history.push("/auth/reset-password",{ email: email });
            });
          }else{
            errorToast(response.data.error.message);
            this.setState({isLoading:false});
          }
        }).catch(error => {
          if (error.response) {
            const errorResult = error.response.data.error;
            this.setState({ isLoading:false});
            errorToast(errorResult.message);
          } 
          this.setState({isLoading:false});        
        });
      } else {
        this.setState({ isLoading:false });
      }
    }

    render() {
      let {email,isLoading}= this.state;
      let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
      return (
        <div className="container login_page">
          <div className="wrap-login100">
            <form role="form" method="POST" className="login100-form">
              <div className="text-center login-logo mb-4">
                <img src={images.logo} className="logo" />
                <p style={{fontSize:'24px'}}>Forgot password</p>
              </div>
              
              <div className="form-group col-md-12 form-custom p-0 popup-custom-height">
                <input className="form-control" 
                  id="time1"
                  type="email"  
                  placeholder="Email"
                  name="email" 
                  onChange={this.onChange.bind(this)} 
                  defaultValue={email} 
                  required/>
                <InputError show={validation.email.isInvalid} message={validation.email.message} />
              </div>
              
              <div className="form-group col-md-12 form-custom p-0">
                <label className="m-0" style={{fontSize:'14px',color:'#50624abf'}}>An email with temporary password will be sent to the above given email</label>
              </div>
            
              <div className="col-md-12 p-0 mt-30">
                <button type="submit" 
                  onClick={this.onSubmit.bind(this)} 
                  disabled = {isLoading} 
                  className="btn common_btn blue_btn big_btn" role="button">
                    Send Email 
                </button>
              <div className="back_to_login">
                <Link to="/auth/login" className="txt1">
                  Back to login
                </Link>
              </div>
            </div>
            </form>
            <Loader isLoading={isLoading}/>
        </div>
      </div>
    )
  }
}
