import React, { Component } from 'react';
import Modal from 'react-modal';
import ModalContent from "../../Helpers/Modal-Content.js";
import images from "../ImagesUrl/imagesUri";

export default class DeleteModal extends Component {
    constructor(props){
        super(props) 
        this.state = {
            setIsOpen: false,
            permanent_delete: false
        }
    }

    componentWillReceiveProps(){
        this.setState({ permanent_delete: false });
    }

    onPermanentDelete(e){
        this.setState({ permanent_delete: !this.state.permanent_delete });
    }

    render(){ 
        const { permanent_delete } = this.state;
        const { modalIsOpen, closeModal, onAction, modalFor } = this.props;
        const modalData = (ModalContent[modalFor]) ? ModalContent[modalFor] : null; 
        return ( 
            <>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Delete Modal"
                    ariaHideApp={false}
                >
                    <form className="col-md-12 p-0 modal_from">
                        <div className="row">
                             <div className="col-md-12 text-center">
                                <div className="row text-center justify-content-center mb-5">
                                    { (modalData && modalData.img ) &&  <img src={images.caution} alt="" className="caution-img mr-3" /> }
                                    <h2 className="modal_heading m-0">
                                      { (modalData) ? modalData.header : "" }
                                    </h2>
                                </div>
                            </div>    
                            {(modalData && modalData.paragraph.length > 0) && 
                                <div className="col-md-12 text-center mb-4">
                                    <p> 
                                        { modalData.paragraph }
                                    </p>
                                </div>
                            }	
                            {(modalData && modalData.per_del) && 
                                <div className="col-md-12 ml-3 mb-4 custom-control custom-checkbox">
                                    <input className="custom-control-input"
                                        id="customCheck2"
                                        type="checkbox" 
                                        name="remember"
                                        onClick = { this.onPermanentDelete.bind(this) }
                                        checked = { permanent_delete }
                                    />
                                    <label className ="custom-control-label ml-10" name = "label" htmlFor="customCheck2">
                                        { modalData.per_del_msg }
                                    </label>
                                </div>
                            }    	
                            <div className="col-md-6 m-mb-15">
                                <button 
                                className="btn blue_btn common_btn big_btn"
                                role="button"
                                onClick={() => { (modalData && modalData.per_del)? onAction(permanent_delete) : onAction() }}
                                >
                                    { (modalData) ? modalData.button : "" }
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button
                                className="btn gray_btn common_btn big_btn"
                                role="button"
                                onClick={() => closeModal()}
                                >
                                  Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal>
            </> 
        );
    }
}

