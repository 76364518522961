import React, { Component } from 'react';
// import SettingsNotificationsTopbar from './SettingsNotificationsTopbar';
import images from "../components/ImagesUrl/imagesUri"
import SimpleBar from 'simplebar-react';
import Rules from "../Helpers/FormRules";
import FormValidator from "../Helpers/FormValidator";
import { errorToast, successToast } from '../components/Toast/Toast.js';
import 'simplebar/dist/simplebar.min.css';
import InputError from "../components/Error/InputError";
// import { createEvent } from '../Actions/event';
import { getMatchList } from '../Actions/match';
import { getMatchesList } from '../Actions/multipleMatch';
import { sendNotification,getMatchNMultiMatch } from '../Actions/Notification';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { Link } from "react-router-dom";
import * as _ from "lodash";
import moment from 'moment';
import { convertDateFormat, extractTime, concatDateTime, timeZone, extractPostTime } from '../Helpers/TimeConversion';
import Loader from '../components/Loader/Loader.jsx';
import "react-datepicker/dist/react-datepicker.css";
import Template from "../Helpers/Raw-Data.js";
import Styles from "../Helpers/Select-Styles.js";

export default class PushNotification extends Component {
    constructor(props) {
        super(props)
        this.noti_validaor = new FormValidator(Rules.Notification);
        this.state = {
            noti_validation: this.noti_validaor.valid(),
            isLoading:false,
            date: new Date(),
            drop_value: "1",
            selected_matches: [],
            selected_multi_matches: [],
            isShowPlaceholder: true,
            selected_receiver_type:'',
            matchList:[],
            multiMatchList:[],
            linkId:'',
            noti_for:'',
            emptyLinkId:false,
            notification:{
                notification_title: "",
                send_status: 1,
                time: moment(new Date()).format("HH:mm"),
                date: new Date(),
                noti_receivers:[],
            }
        }
    }

    componentDidMount(){
        
    }
  
    getMatches(){
        this.setState({isLoading:true});
        let {matchList} =  this.state;
        getMatchList({
            "search":"",
            // page,
            // limit,
            "status":1 ,
            // tournament_id,
            "sort_by" : { "name":"date_and_time", "order":"DESC" }
          }).then((response)=>{
            const result = response.data.success;
            // console.log("Result",result);
            let MatchData = result.data.matchesAndTipsList;
            // console.log("matchData",MatchData);
            MatchData.map((val, key) =>{
                val.value = val.id;
                val.label = val.title;
            });
            
            this.setState({ matchList:MatchData,isLoading:false});
        }).catch((error)=>{
            if (error.response) {
                const errorResult = error.response.data.error;
                errorToast(errorResult.message);
                this.setState({isLoading:false});    
            } 
        });
    }

    getMultiMatches(){
        this.setState({isLoading:true});
        let {multiMatchList} =  this.state;
        getMatchesList({
            "search":"",
            // page,
            // limit,
            "status": 1,
            // tournament_id,
            "sort_by" : { "name":"date_and_time", "order":"DESC" }
          }).then((response)=>{
            const result = response.data.success;
            // console.log("Result",result);
            let MatchData = result.data.multipleMatchesAndTipsList;
            // console.log("matchData",MatchData);
            MatchData.map((val, key) =>{
                val.value = val.id;
                val.label = val.matchesIdsForMultipleMatches.length +' selection @ '+ val.tip_rate;
            });
            this.setState({ multiMatchList:MatchData,isLoading:false});
        }).catch((error)=>{
            if (error.response) {
                const errorResult = error.response.data.error;
                errorToast(errorResult.message);
                this.setState({isLoading:false});  
            } 
        });
    }

    onChange(formtype, e){
        let {name, value} = e.target;
        let event = { ...this.state.event };
        let notification = { ...this.state.notification };
        // value = value.trim();
        value = (value == " ")? "" : value;
        if(formtype === "notification"){
            notification[name] = value;
            this.setState({ notification });

        } else {
            event[name] = value;
            this.setState({ event });
        }
        
    }
      
    onNotificationSubmit = (e) => {
        e.preventDefault();
        let {drop_value,selected_matches,selected_multi_matches,linkId,noti_for,notification,emptyLinkId,matchList,selected_receiver_type,multiMatchList} = this.state;
        if(drop_value == 1) {
            noti_for = 1
            this.setState({linkId,noti_for})
            // this.setState({emptyLinkId:false});
        }
        if(drop_value == 2) {
            // this.setState({emptyLinkId:true})
            if(selected_matches.length > 0 ) 
            {
                linkId = selected_matches[0].id;
                noti_for= 2;
                this.setState({linkId,noti_for});
                emptyLinkId = false;
            }else {
                emptyLinkId = true;
            } 
        }
        if(drop_value == 3) {
            // this.setState({emptyLinkId:true})
            if(selected_multi_matches.length > 0 ) 
            {
                linkId = selected_multi_matches[0].id;
                noti_for = 3 ;
                this.setState({linkId,noti_for});
                emptyLinkId = false;
            }else {
                emptyLinkId = true;
            } 
        }
       this.setState({emptyLinkId})
        const notiValidation = this.noti_validaor.validate(this.state.notification); 
        this.notiSubmitted = true;
        if (notiValidation.isValid) { 
            if(emptyLinkId === false) {
            this.setState({ isLoading: true });
            // let{notification_title,send_status,time,date} = this.status.notification;
            let send_date = concatDateTime(convertDateFormat(notification.date,"YYYY-MM-DD"), notification.time);
            let notify = {
                "send_status": notification.send_status,
                "send_date": send_date,
                "notification_title": notification.notification_title,
                "time": notification.time,
                "noti_for": noti_for,
                "content_id" : linkId
            }
            console.log("Form submitted",notify);
            sendNotification(notify).then((response) => {
                const result = response.data.success;
                if(result){
                    successToast(result.message);
                    this.notiSubmitted = false;
                    const notify ={
                        notification_title: "",
                        send_status: 1,
                        time: moment(new Date()).format('HH:mm'),
                        date: new Date(),
                        noti_receivers:[]
                    }
                    selected_multi_matches = [];
                    selected_matches=[];
                    matchList=[];
                    selected_receiver_type='';
                    multiMatchList=[];

                    this.setState({notification: notify, selected_multi_matches,selected_matches,matchList,multiMatchList,selected_receiver_type,isLoading:false,drop_value:1});
                } else {
                const errorResult = response.data.error;
                this.setState({isLoading:false});
                errorToast(errorResult.message);
                }
            }).catch((error) =>{
                this.setState({isLoading:false});
                errorToast('Something went wrong. Please try again!');
            });
            }
        } else {
            this.setState({ isLoading:false });
        }
    }

    dateChange = (date) => {
        let { notification } = this.state;
        notification['date'] = (date)? date : "";
        this.setState({notification});
    };

    
    onMenuOpen = (e) => {
        this.setState({isShowPlaceholder: false});
    }

    onMenuClose = (selectType, e) =>{
        const { selected_receivers } = this.state;
        if(  selectType === "Individual"){
            this.setState({isShowPlaceholder: true});
        } else {
            this.setState({isShowPlaceholder: true});
        }
    }

    onChangeLinkMode = (newValue, actionMeta) => {
        this.setState({ selected_receiver_type: newValue , drop_value: (newValue)? newValue.value : ""});
        if(newValue.value == 2 ) {
            this.getMatches(); 
        }
        if(newValue.value == 3 ) {
            this.getMultiMatches();
        }
        if(newValue.value == 1) {
            this.setState({emptyLinkId:false})
        }
  
    };

    onChangeMatches = (newValue, actionMeta) => {
        let { selected_multi_matches,selected_matches } = this.state;
        if(actionMeta.name == "single") {
            selected_matches = (newValue)? [{id: newValue.value, type: newValue.title}]  : [];
        }
        if(actionMeta.name == "multi") {
            selected_multi_matches = (newValue)? [{id: newValue.value, type: newValue.matchesIdsForMultipleMatches.length +' selection @ '+ newValue.tip_rate}]  : [];
        }
        this.setState({selected_matches,selected_multi_matches,emptyLinkId:false});
       
    };

    render() {
        // const PushNotification = "active";
        const { drop_value, user_data, selected_receivers,selected_matches,selected_multi_matches, tag_data, notification, isLoading, isShowPlaceholder, selected_receiver_type ,matchList,multiMatchList,emptyLinkId} = this.state;
        const notiValidation = this.notiSubmitted ? this.noti_validaor.validate(this.state.notification):this.state.noti_validation;
        // const groupOptions = [{label:"Users",options: user_data},{label:"Tags", options: tag_data}];
        // console.log("selected_matches and selected_multi_matches",selected_matches,selected_multi_matches);
        
        return (
            <div className="agenda_session_form_section agenda_padding">
                {/* <SettingsNotificationsTopbar  PushNotification={PushNotification}/> */}
                {/* <SimpleBar style={{height:'85vh'}}> */}
                <div style={{height:'95vh', overflow: "auto"}}>
                <div className="col-md-12 p-0 setting_pages">
                    <form className="col-md-12 p-0">
                        <div className="row m-0">
                        <div className="col-md-12 m-sm-none">
                            <h2 className="main-heading"> Push Notifications </h2>
                        </div>
                        <div className="d-md-none col-12 mb-3 d-md-none ">
                            <h2 className=" m-0 speakers_heading pt-3"> Push Notifications</h2>
                        </div>
                        <div className="col-md-6 p-0">
                        <div className="form-group col-md-12 form-custom custom-height">
                            {/* <label htmlFor="inputEmail4" className="left_label mb-3">Notificaitons Text</label> */}
                            <div className="row mb-3">
                                <label htmlFor="inputEmail4" className="col-4 left_label m-0 pr-0">Notifications</label>
                                <label htmlFor="inputEmail4" className="col-8 text-right right_label m-0 pl-0">{notification.notification_title.length} of 400 Characters</label>
                            </div>
                            <input 
                                type="text" 
                                className="form-control"
                                name="notification_title"
                                id="notificaitons" 
                                placeholder="Notifications Text"
                                value={notification.notification_title}
                                onChange={this.onChange.bind(this,"notification")}
                                maxLength={400}
                            />
                            <InputError show={notiValidation.notification_title.isInvalid} message={notiValidation.notification_title.message} />
                        </div>
                        
                        
                        <div className="form-group col-md-12 form-custom custom-height">
                            <label className="left_label">Sending Time</label>
                            <div className="row checkbox_height">
                            <div className="custom-control custom-radio col-md-6 col-12">
                                <input 
                                    type="radio"
                                    id="customRadioInline1" 
                                    name="send_status"
                                    className="custom-control-input"
                                    checked={notification.send_status == 1} 
                                    defaultValue={1}
                                    onChange={this.onChange.bind(this,"notification")}
                                />
                                <label className="custom-control-label ipad-fs-15" htmlFor="customRadioInline1">Immediately</label>
                            </div>
                            <div className="custom-control custom-radio col-md-6 col-12">
                                <input 
                                    type="radio" 
                                    id="customRadioInline2"
                                    name="send_status"
                                    className="custom-control-input"
                                    checked={notification.send_status == 2} 
                                    defaultValue={2}
                                    onChange={this.onChange.bind(this,"notification")}
                                />

                                <label className="custom-control-label ipad-fs-15" htmlFor="customRadioInline2">Custom Time</label>
                            </div>
                            </div>
                        </div>
                        
                        <div className="form-group col-md-12 form-custom m-0">
                            <div className="row mb-3">
                            <div className="col-md-6 custom-height">
                                <label htmlFor="input1" className="left_label mb-3">Date</label>
                                    <DatePicker
                                        locale="en"
                                        selected={notification.date}
                                        name="date"
                                        dateFormat="MM-dd-yyyy"
                                        onChange={this.dateChange}
                                        className="form-control"
                                        id="category1"
                                        placeholderText="Date"
                                        minDate={new Date()}
                                        disabled={(notification.send_status == 1)? true : false}
                                        disabledKeyboardNavigation 
                                    />
                                <InputError show={notiValidation.date.isInvalid} message={notiValidation.date.message} />
                            </div>
                            <div className="col-md-6 custom-height">
                                <label htmlFor="end-time" className="left_label mb-3">Time</label>
                                <div className="col p-0">
                                    <input type="time" 
                                        className="form-control" 
                                        id="noti_time" 
                                        name="time"  
                                        disabled={(notification.send_status == 1)? true : false}
                                        defaultValue= {notification.time}
                                        onChange={this.onChange.bind(this,"notification")}
                                        required
                                    />
                                <InputError show={notiValidation.time.isInvalid} message={notiValidation.time.message} />
                                </div>
                            </div>
                            </div>
                        </div>
                        
                        </div>
                        <div className="col-md-6 p-0">
                        <div className="form-group col-md-12 form-custom assign_in_tags common_dropdown">
                            <label htmlFor="inputEmail4" className="col left_label mb-3 p-0">Link To</label>
                                <Select
                                    isClearable = {false}
                                    isSearchable = {false}
                                    onChange={this.onChangeLinkMode}
                                    options={Template.push_notification}
                                    defaultValue={Template.push_notification[0]}
                                    value = {(selected_receiver_type)?selected_receiver_type:Template.push_notification[0]}
                                    name= "selected_receiver_type"
                                    components={{ DropdownIndicator:() => null }}
                                    styles={Styles.colourStyles}
                                /> 
                            
                        </div>  
                        <div className="col-md-12 form-custom custom-height">
                            { (drop_value == 2)?
                                <Select
                                    isClearable = {false}
                                    onChange={this.onChangeMatches}
                                    options={matchList}
                                    placeholder={(isShowPlaceholder)? "Search Match": ""}
                                    value={ selected_matches.type}
                                    name = "single"
                                    components={{ DropdownIndicator:() => null }}
                                    onMenuOpen = {this.onMenuOpen.bind(this, "recivers")}
                                    onMenuClose = {this.onMenuClose}
                                    styles={Styles.colourStyles}
                                /> : null}
                            { (drop_value == 3)?
                                <Select
                                    isClearable = {false}
                                    onChange={this.onChangeMatches}
                                    options={multiMatchList}
                                    // formatGroupLabel={this._formatGroupLabel}
                                    placeholder={(isShowPlaceholder)? "Search MultipleMatches": ""}
                                    value={ selected_multi_matches.type}
                                    // controlShouldRenderValue={false}
                                    name = "multi"
                                    components={{ DropdownIndicator:() => null }}
                                    onMenuOpen = {this.onMenuOpen}
                                    onMenuClose = {this.onMenuClose.bind(this,"Tags&Individual")}
                                    styles={Styles.colourStyles}
                                />:null
                            }    
                            <InputError show={emptyLinkId == true} message="Match is required" />
                        </div>
                        </div>
                    
                        <div className="col-md-12 mb-4">
                             <Link to="#"
                                className="btn common_btn blue_btn big_btn fixed_width_btn"
                                role="button"
                                onClick={this.onNotificationSubmit.bind(this)}
                            >
                                Send Notifications
                            </Link>
                         </div>
                    </div>
                    </form>
                </div>
                {/* </SimpleBar> */}
                </div>
                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}
