import Request from '../Helpers/API';

export const createFAQ = (params) => {
  
    const api_url = `admin/create-faq`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};

export const getFAQList = (params) => {
  
    const api_url = `admin/faq-list`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};

export const reactivateFaq = (params) =>{
    const api_url = `admin/reactivated-faq`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const hardDeleteFaq = (faqId) =>{
    const api_url = `admin/hard-delete-faq/${faqId}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}

export const deleteFaq = (params) =>{
    const api_url = `admin/delete-faq`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const changeFaqStatus = (params, faqId) =>{
    const api_url = `admin/change-faq-status/${faqId}`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const getFaqDetails = (faqId) =>{
    const api_url = `admin/faq-details/${faqId}`;
    
    const req = new Request(api_url);
    
    return req.get();
}

export const updateFaq = (params, faqId) =>{
    const api_url = `admin/update-faq/${faqId}`;
    
    const req = new Request(api_url, params);
    
    return req.put();
}

export const updatePosition = (params, faqId) =>{
    const api_url = `admin/update-position/${faqId}`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}