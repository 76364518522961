import React, { Component } from 'react';
import InputError from "../components/Error/InputError";
import Rules from "../Helpers/FormRules";
import FormValidator from "../Helpers/FormValidator";
import { errorToast, successToast } from '../components/Toast/Toast.js';
import * as _ from "lodash";
import Loader from '../components/Loader/Loader.jsx';
import { getTime, extractDate, concatDateTime, extractTime} from '../Helpers/TimeConversion';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Template from "../Helpers/Raw-Data.js";
import Styles from "../Helpers/Select-Styles.js";
import { getAllTounament, getMatchDetails, updateMatch, createMatch, getPlayers } from '../Actions/match.js';
import { getAllPlayers } from '../Actions/players.js';

class AddSesion extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator(Rules.createMatch);
        this.state = {
            validation: this.validator.valid(),
            isLoading: false,
            isShowPlayerOnePlaceholder: true,
            isShowTagPlaceholder: true,
            isShowPlayerTwoPlaceholder: true,
            isShowTounamentPlaceholder: true,
            isShowWinPlayerPlaceholder: true,
            isShowWinChancePlaceholder: true,
            player_list: [],
            tournament_list: [],
            possible_player_list: {},
            winning_color: "",
            edit_mode: false,
            match_id:'',
            formData:{
                title: '',
                player_one_id: '',
                player_two_id: '',
                player_one_name: '',
                player_two_name: '',
                player_one_country_code: '',
                player_one_country:'',
                player_two_country_code: '',
                player_two_country:'',
                possible_winner_id: '',
                tournament_id: '',
                match_info: '',
                tip_rate: '',
                match_time: '',
                publish_at_time: '',
                match_date: getTime(),
                publish_at: getTime(),
                winning_chances: '',
                display_on:''
            },
        }  
        this.formsubmitted = false;
    }

    componentDidMount() {
        this.getList();
        this.getTounaments();
        if(this.props.location.state){
            // this.getMatch(matchId);
            const MatchData = this.props.location.state;
            //console.log("MatchData====",MatchData);
            const winningColor =  _.find(Template.winning_chances, (ele) => { return ele.id == MatchData.winning_chances });
            const displayOn =  _.find(Template.display_on, (ele) => { return ele.id == MatchData.display_on });
            console.log("props displayOn==",displayOn);
            this.setState({
                winning_color: winningColor.value,
                match_id: this.props.location.state.id, 
                edit_mode: true,
                formData: {
                    title: MatchData.title,
                    player_one_id: MatchData.player_one_id,
                    player_two_id: MatchData.player_two_id,
                    player_one_name: (MatchData.player_one)? MatchData.player_one.player_name : '',
                    player_two_name: (MatchData.player_two)? MatchData.player_two.player_name : '',
                    player_one_country_code: (MatchData.player_one)? MatchData.player_one.country_code : '',
                    player_two_country_code: (MatchData.player_two)? MatchData.player_two.country_code : '',
                    player_one_country: (MatchData.player_one)? MatchData.player_one.country : '',
                    player_two_country: (MatchData.player_two)? MatchData.player_two.country : '',
                    possible_winner_id: MatchData.possible_winner_id,
                    tournament_id: MatchData.tournament_id,
                    match_info: MatchData.match_info,
                    tip_rate: MatchData.tip_rate,
                    match_time: extractTime(MatchData.date_and_time,"HH:mm"),
                    publish_at_time: extractTime(MatchData.publish_at,"HH:mm"),
                    match_date: MatchData.date_and_time,
                    publish_at: MatchData.publish_at,
                    winning_chances: MatchData.winning_chances,
                    display_on:(MatchData.display_on === null)?3:MatchData.display_on
                },
            });
        }
    }

    async getList(){
        getAllPlayers().then((response) => {
            const result = response.data.success;
            this.setState({ player_list: result.data }, () => {
                const { formData, player_list } = this.state;
                if(formData.player_one_id && formData.player_two_id){
                    const tipPlayers = {
                        player_one_name: _.find( player_list,(ele) => { return ele.value == formData.player_one_id }),
                        player_two_name: _.find( player_list,(ele) => { return ele.value == formData.player_two_id })
                    };
                    this.setState({ possible_player_list: tipPlayers });
                }    
            });
        }).catch( error =>{
            if (error.response) {
                const errorResult = error.response.data.error;
                this.setState({err:true,message:'Server Error!', isLoading:false});
                errorToast(errorResult.message);
            } 
        });
    }

    getTounaments(){
        getAllTounament().then((response)=>{
            const result = response.data.success;
            result.data.map((val, key) =>{
                val.value = val.id;
                val.label = val.title;
                delete val.id;
                delete val.title;
            });
            this.setState({ tournament_list: result.data });
        }).catch(error =>{
            if (error.response) {
                const errorResult = error.response.data.error;
                this.setState({err:true,message:'Server Error!', isLoading:false});
                errorToast(errorResult.message);
            } 
        });
    }

    getMatch(matchId){
        getMatchDetails(matchId).then((response)=>{
            const result = response.data.success;
            const MatchData = (result.data)? result.data : "";
            const winningColor =  _.find(Template.winning_chances, (ele) => { return ele.label == this.state.formData.winning_chances });
            const matchState = {
                "title": MatchData.title,
                "player_one_id": MatchData.player_one_id,
                "player_two_id": MatchData.player_two_id,
                "player_one_name": (MatchData.player_one)?MatchData.player_one.player_name:'',
                "player_two_name": (MatchData.player_two)?MatchData.player_two.player_name:'',
                "player_one_country_code": (MatchData.player_one)?MatchData.player_one.country_code:'',
                "player_two_country_code": (MatchData.player_two)?MatchData.player_one.country_code:'',
                "player_one_country": (MatchData.player_one)?MatchData.player_one.country_code:'',
                "player_two_country": (MatchData.player_two)?MatchData.player_one.country_code:'',
                "possible_winner_id": MatchData.possible_winner_id,
                "tournament_id": MatchData.tournament_id,
                "match_info": MatchData.match_info,
                "tip_rate": MatchData.tip_rate,
                "match_time": extractTime(MatchData.date_and_time, "HH:mm"),
                "match_date": MatchData.date_and_time,
                "winning_chances": MatchData.winning_chances,
                "publish_at_time": extractTime(MatchData.publish_at, "HH:mm"),
                "publish_at": MatchData.publish_at, 
            };
            this.setState({
               formData:matchState,
               winning_color: winningColor.value,
               match_id: matchId, 
            });
        }).catch(error =>{
            if (error.response) {
                const errorResult = error.response.data.error;
                this.setState({err:true,message:'Server Error!', isLoading:false});
                errorToast(errorResult.message);
            } 
        });
    }

    onChange = (e) =>{
        let {name, value} = e.target;
        let formData = { ...this.state.formData };
        value = (value == " ")? "" : value;
        formData[name] = value;
        this.setState({ formData});
        this.setState({[name]: value});
    }

    onDateChange = (date) => {
        let { formData } = this.state;
        // console.log("date", date, getTime(date));
        formData['match_date'] = (date)? getTime(date) : "";
        this.setState({ formData });
    };
    onPublishDateChange = (date) => {
        let { formData } = this.state;
        // console.log("date", date, getTime(date));
        formData['publish_at'] = (date)? getTime(date) : "";
        this.setState({ formData });
    };

    onSelect = (newValue, actionMeta) => {
        let { formData, winning_color, possible_player_list, isShowWinPlayerPlaceholder } = this.state;
        if(actionMeta.name == "winning_chances" || actionMeta.name == "display_on"){
           formData[actionMeta.name] = (newValue)? newValue.id : "";
           winning_color = (newValue)? newValue.value : "";
        } else {
           formData[actionMeta.name] = (newValue)? newValue.value : "";
        }

        if(actionMeta.name == "player_one_id"){
            possible_player_list["player_one_name"] = (newValue)? newValue : "";
            formData["player_one_name"] = (newValue)? newValue.label : "";
            formData["player_one_country_code"] = (newValue)? newValue.country_code : "";
            formData["player_one_country"] = (newValue)? newValue.country : "";
            formData["possible_winner_id"] = '';
            isShowWinPlayerPlaceholder = true;

        } else if(actionMeta.name == "player_two_id"){
            possible_player_list["player_two_name"] = (newValue)? newValue : "";
            formData["player_two_name"] = (newValue)? newValue.label : "";
            formData["player_two_country_code"] = (newValue)? newValue.country_code : "";
            formData["player_two_country"] = (newValue)? newValue.country : "";
            formData["possible_winner_id"] = '';
            isShowWinPlayerPlaceholder = true;
        }

        this.setState({ formData, winning_color, possible_player_list, isShowWinPlayerPlaceholder });
    };

    onMenuOpen( placeholderType, e){
        if(placeholderType == "possible_winner"){
            this.setState({ isShowWinPlayerPlaceholder: false });
        } else if(placeholderType == "player_one"){
            this.setState({isShowPlayerOnePlaceholder: false });
        } else if(placeholderType == "player_two") {
            this.setState({ isShowPlayerTwoPlaceholder: false });
        } else if(placeholderType == "tournament"){
            this.setState({ isShowTounamentPlaceholder: false });
        } else if(placeholderType == "winning_chances"){
            this.setState({ isShowWinChancePlaceholder: false });
        }
    }

    onMenuClose(placeholderType ,e){
        this.setState({ 
            isShowPlayerOnePlaceholder: true,
            isShowPlayerTwoPlaceholder: true,
            isShowTounamentPlaceholder: true,
            isShowWinPlayerPlaceholder: true,
            isShowWinChancePlaceholder: true
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const validation = this.validator.validate(this.state.formData);
        this.setState( { validation, isLoading: true }, () =>{
            this.submitted = true;
            if (validation.isValid) {
                const { edit_mode, formData, match_id } = this.state;
                console.log("formData.display_on", formData.display_on)
                const matchRequestData = {
                    "title": formData.title,
                    "player_one_id": formData.player_one_id,
                    "player_two_id": formData.player_two_id,
                    "player_one_name": formData.player_one_name,
                    "player_two_name": formData.player_two_name,
                    "player_one_country_code": formData.player_one_country_code,
                    "player_two_country_code": formData.player_two_country_code,
                    "player_one_country": formData.player_one_country,
                    "player_two_country": formData.player_two_country,
                    "possible_winner_id": formData.possible_winner_id,
                    "tournament_id": formData.tournament_id,
                    "match_info": formData.match_info,
                    "tip_rate": formData.tip_rate,
                    "date_and_time": concatDateTime(extractDate(formData.match_date), formData.match_time),
                    // "publish_at": formData.publish_at,
                    "publish_at": concatDateTime(extractDate(formData.publish_at), formData.publish_at_time),
                    "winning_chances": formData.winning_chances,
                    "final_winner_id":"",
                    "display_on":(formData.display_on)?formData.display_on:3
                };
                console.log("Api submited data ==",matchRequestData);
                const apiMethod = (edit_mode) ? updateMatch(matchRequestData, match_id): createMatch(matchRequestData);
                apiMethod.then((response) =>{
                    let result = response.data;
                    this.setState( { isLoading: false }, () =>{
                        if(result.success){
                            successToast(result.success.message);
                                setTimeout(() => {
                                    this.props.history.push("/admin/match/match-list",{ id: formData.tournament_id });
                                },1000);
                        } else {
                            errorToast(result.error.message);
                        }
                    });
                }).catch((error)=> {
                    if (error.response) {
                        const errorResult = error.response.data.error;
                        this.setState({err:true,message:'Server Error!', isLoading:false});
                        errorToast(errorResult.message);
                    } 
                });
            } else {
              this.setState({ isLoading:false });
            }
        });
    }

    render() {
        let { formData, isLoading, isShowPlayerTwoPlaceholder, isShowTounamentPlaceholder, winning_color, isShowWinPlayerPlaceholder,
            isShowWinChancePlaceholder, isShowPlayerOnePlaceholder, player_list, tournament_list, possible_player_list,edit_mode
        } = this.state;
        let validation = this.submitted ? this.validator.validate(this.state.formData):this.state.validation;
        console.log("__+_+__+", formData);

        
        return (
            <div className="agenda_session_form_section agenda_padding">
                <div style={{height:'90vh', overflow: "auto"}} className="mobile-scrollbar-height">
                <div className="text-left col-md-12 p-0">
                <form className="col-md-12 p-0" autoComplete="off">
                    <div className="row m-0">
                        <div className="d-md-none col-12 mb-3 ">
                            <h2 className=" m-0 speakers_heading pt-3"> Create Match</h2>
                        </div>
                    <div className="col-md-6 p-0">
                        <div className="form-group col-md-12 form-custom custom-height">
                            <div className="row mb-3 m-mb-10">
                                <label htmlFor="inputEmail4" className="col left_label m-0 col-4"> Title </label>
                                <label htmlFor="inputEmail4" className="col text-right right_label m-0 col-8">{formData.title.length} of 120 Characters</label>
                            </div>
                            <input className="form-control" 
                                id="inputEmail4" 
                                placeholder="Enter Title"
                                type="text"
                                name="title"
                                tabIndex="1"
                                maxLength={120}
                                value={formData.title}
                                onChange={this.onChange}
                                autoFocus
                            />
                            <InputError show={validation.title.isInvalid} message={validation.title.message} />
                        </div>
                        
                        <div className="form-group col-xl-12 form-custom  m-0 assign_in_tags common_dropdown">
                        <div className="row">
                            <div className="col-md-12 col-xl-6 m-mb-25 custom-height">
                                <label htmlFor="inputState" className="left_label mb-3 m-mb-10">Player 1</label>
                                 <Select
                                    isClearable = {false}
                                    onChange = {this.onSelect }
                                    options = { player_list }
                                    placeholder = {(isShowPlayerOnePlaceholder)? "Player 1" : ""}
                                    name = "player_one_id"
                                    tabIndex="3"
                                    value = { _.find(player_list,(ele) => { return ele.value == formData.player_one_id }) }
                                    onMenuOpen = {this.onMenuOpen.bind(this,"player_one")}
                                    onMenuClose = {this.onMenuClose.bind(this,"player_one")}
                                    styles={Styles.colourStyles}
                                    components={{ DropdownIndicator:() => null }}
                                    isOptionDisabled = { (option) => option.value === formData.player_two_id }
                                />
                                <InputError show={validation.player_one_id.isInvalid} message={validation.player_one_id.message} />
                            </div>
                            <div className="col-md-12 col-xl-6 custom-height m-mb-25">
                                <div className="row m-0 mb-3 m-mb-10">
                                    <label htmlFor="inputEmail4" className="col left_label m-0 p-0"> Player 2 </label>
                                </div>
                                <Select
                                    isClearable = { false }
                                    onChange = { this.onSelect }
                                    options = { player_list }
                                    placeholder={(isShowPlayerTwoPlaceholder)?"Player 2" : ""}
                                    name = "player_two_id"
                                    value = { _.find(player_list,(ele) => { return ele.value == formData.player_two_id }) }
                                    onMenuOpen = {this.onMenuOpen.bind(this,"player_two")}
                                    onMenuClose = {this.onMenuClose.bind(this,"player_two")}
                                    styles = {Styles.colourStyles}
                                    components = {{ DropdownIndicator:() => null }}
                                    isOptionDisabled = { (option) => option.value === formData.player_one_id }
                                />
                                <InputError show={validation.player_two_id.isInvalid} message={validation.player_two_id.message} />
                            </div>
                        </div>  
                        </div>
                        <div className="form-group col-md-12 form-custom m-0 p-0 ">
                            <div className="row m-0">
                                <div className="col-md-6 m-mb-25">
                                    <label htmlFor="inputEmail4" className="col left_label mb-3 m-mb-10 p-0"> Date </label>
                                    <DatePicker
                                        selected = { new Date((extractDate(formData.match_date))) }
                                        dateFormat ="MM-dd-yyyy"
                                        onChange = { this.onDateChange }
                                        className="form-control"
                                        placeholderText="Date"
                                    />
                                    <InputError show = { validation.match_date.isInvalid } message = { validation.match_date.message } />
                                </div>
                                <div className="col-md-6 custom-height">
                                    <label htmlFor = "inputEmail4" className = "left_label mb-3 m-mb-10"> Time </label>
                                    <input type = "time" 
                                        className = "form-control" 
                                        id = "time1" 
                                        name = "match_time"  
                                        value = {formData.match_time}
                                        onChange={ this.onChange.bind(this) }
                                    />
                                    <InputError show={validation.match_time.isInvalid} message={validation.match_time.message} />
                                </div>
                            </div>
                        </div>
 
                        <div className="form-group col-md-12 form-custom custom-height assign_in_tags common_dropdown">
                            <label htmlFor="inputState" className="left_label mb-3 m-mb-10"> Tournament  </label>
                            <Select
                                isClearable = {false}
                                onChange = { this.onSelect }
                                options = { tournament_list }
                                placeholder = { (isShowTounamentPlaceholder)? "Select Tournament" : "" }
                                value = {_.find(tournament_list,(ele) => { return ele.value == formData.tournament_id })}
                                name = "tournament_id"
                                tabIndex = "4"
                                onMenuOpen = {this.onMenuOpen.bind(this,"tournament")}
                                onMenuClose = {this.onMenuClose.bind(this,"tournament")}
                                styles = { Styles.colourStyles }
                                menuPlacement= "auto"
                                components={{ DropdownIndicator:() => null }}
                            />
                            <InputError show={validation.tournament_id.isInvalid} message={validation.tournament_id.message} />
                        </div>

                        <div className="form-group col-md-12 form-custom custom-height assign_in_tags common_dropdown">
                            <label htmlFor="inputState" className="left_label mb-3 m-mb-10"> Display On </label>
                            
                            <Select
                                isClearable = { false }
                                isSearchable = { false }
                                onChange = { this.onSelect }
                                options = { Template.display_on }
                                placeholder = { (isShowWinChancePlaceholder) ? "Select Display On" : ""}
                                value = { _.find(Template.display_on, (ele) => { 
                                    const displayOn = parseInt(formData.display_on, 10);
                                    return ele.id === displayOn 
                                }) }
                                name = "display_on"
                                onMenuOpen = {this.onMenuOpen.bind(this, "display_on")}
                                onMenuClose = {this.onMenuClose.bind(this, "display_on")}
                                styles = { Styles.colourStyles }
                                menuPlacement= "auto"
                                components = { { DropdownIndicator:() => null } }
                            />
                            <InputError show={validation.display_on.isInvalid} message={validation.display_on.message} />
                        </div>
                        
                    </div>

                    <div className="col-md-6 p-0">
                        <div className="form-group col-md-12 form-custom">
                            <div className="row mb-3 m-mb-10">
                                <label htmlFor="inputEmail4" className="col left_label m-0 col-4"> Match Info </label>
                                <label htmlFor="inputEmail4" className="col text-right right_label m-0 col-8"> {formData.match_info.length} of 400 Characters</label>
                            </div>
                            <textarea className="form-control" 
                                id = "exampleFormControlTextarea1" 
                                rows = "5" 
                                maxLength = {400}
                                tabIndex = "2"
                                name = "match_info" 
                                value = {formData.match_info}
                                onChange={this.onChange.bind(this)}
                            >
                            </textarea>
                            <InputError show={validation.match_info.isInvalid} message={validation.match_info.message} />
                        </div>
                        
                        <div className="form-group col-xl-12 form-custom m-0 p-0 assign_in_tags common_dropdown">
                            <div className="row m-0">
                            <div className="col-md-12 col-xl-6 custom-height m-mb-25 ">
                                <div className="row m-0 mb-3 m-mb-10">
                                    <label htmlFor="inputEmail4" className="col left_label m-0 p-0"> Tip For Player </label>
                                </div>
                                <Select
                                    isClearable = {false}
                                    onChange = { this.onSelect }
                                    options = { Object.values(possible_player_list) }
                                    placeholder = { (isShowWinPlayerPlaceholder) ? "Tip For Player" : ""}
                                    value = { Object.values(possible_player_list).filter(({ value }) => value === formData.possible_winner_id)}
                                    // value = { _.find(Object.values(possible_player_list), (ele) => { return ele.value == formData.possible_winner_id }) || '' }
                                    name = "possible_winner_id"
                                    tabIndex = "4"
                                    onMenuOpen = {this.onMenuOpen.bind(this,"possible_winner")}
                                    onMenuClose = {this.onMenuClose.bind(this,"possible_winner")}
                                    styles = { Styles.colourStyles }
                                    components = {{ DropdownIndicator:() => null }}
                                    isDisabled = { (formData.player_one_id || formData.player_two_id) ? false : true }
                                />
                                <InputError show={validation.possible_winner_id.isInvalid} message={validation.possible_winner_id.message} />
                            </div>
                                <div className="col-md-12  col-xl-6 custom-height">
                                    <label htmlFor="inputEmail4" className="left_label mb-3 m-mb-10">Tip Rate</label>
                                    <input 
                                        className = "form-control" 
                                        id = "tip_rate" 
                                        type = "number"
                                        name = "tip_rate"
                                        tabIndex = "13"
                                        value = { formData.tip_rate }
                                        onChange = {this.onChange.bind(this)}
                                    />
                                    <InputError show={validation.tip_rate.isInvalid} message={validation.tip_rate.message} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-12 form-custom custom-height assign_in_tags common_dropdown">
                            <label htmlFor="inputState" className="left_label mb-3 m-mb-10"> Winning Chances </label>
                            <div className="color-box" style={{ backgroundColor: winning_color }}> </div>
                            <Select
                                isClearable = { false }
                                isSearchable = { false }
                                onChange = { this.onSelect }
                                options = { Template.winning_chances }
                                placeholder = { (isShowWinChancePlaceholder) ? "Winning Chances" : ""}
                                value = { _.find(Template.winning_chances, (ele) => { return ele.id == formData.winning_chances }) }
                                name = "winning_chances"
                                // onMenuOpen = {this.onMenuOpen.bind(this, "winning_chances")}
                                // onMenuClose = {this.onMenuClose.bind(this, "winning_chances")}
                                styles = { Styles.colourStyles }
                                menuPlacement= "auto"
                                components = { { DropdownIndicator:() => null } }
                            />
                            <InputError show={validation.winning_chances.isInvalid} message={validation.winning_chances.message} />
                        </div>
                    <div className="form-group col-md-12 form-custom m-0 p-0 ">
                            <div className="row m-0">
                            <div className="col-md-6 m-mb-25">
                                <label htmlFor="inputEmail4" className="col left_label mb-3 m-mb-10 p-0"> Publish At </label>
                                <DatePicker
                                    selected = { new Date((extractDate(formData.publish_at))) }
                                    dateFormat ="MM-dd-yyyy"
                                    onChange = { this.onPublishDateChange }
                                    className="form-control"
                                    placeholderText="Publish At"
                                />
                                <InputError show = { validation.publish_at.isInvalid } message = { validation.publish_at.message } />
                            </div>
                                <div className="col-md-6 custom-height">
                                    <label htmlFor = "inputEmail4" className = "left_label mb-3 m-mb-10"> Publish At Time </label>
                                    <input type = "time" 
                                        className = "form-control" 
                                        id = "time1" 
                                        name = "publish_at_time"  
                                        value = {formData.publish_at_time}
                                        onChange={ this.onChange.bind(this) }
                                    />
                                    <InputError show={validation.publish_at_time.isInvalid} message={validation.publish_at_time.message} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="col-md-6 pl-0 m-pr-0">
                        <div className="row">
                            <div className="col-md-6 col-6 m-pr-7">
                                <button
                                    className="btn common_btn blue_btn big_btn fixed_width_btn"
                                    style = {{marginBottom:'15px'}}
                                    role="button"
                                    tabIndex="17"
                                    disabled = { isLoading }
                                    onClick = {this.onSubmit.bind(this)}
                                >{ edit_mode == true ? "Update" : "Save"} 
                                </button>
                            </div>
                            <div className="col-md-6 col-6 m-pl-7">
                                <button  className="btn common_btn gray_btn big_btn fixed_width_btn "
                                    role="button"
                                    type="button"
                                    onClick={ () => { this.props.history.push("/admin/match/match-list",{ id: formData.tournament_id });} }
                                >
                                    Cancel 
                                </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </form>	
            </div>
            </div>
            <Loader isLoading = { isLoading }/>
            </div>
        );
    }

}

export default AddSesion;