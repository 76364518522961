import { BASE_URL, ENC_KEY } from "./Config";
import Crypter from "./Crypter";

export const getBaseUrl = () => (BASE_URL);

export const altImage = () => {
    return BASE_URL+'img/faces/user_avatar.png';
}

export const setToken = (token) => {
    localStorage.setItem('tennis',token);
}

export const getToken = () => {
    return localStorage.getItem('tennis');
}

export const removeToken = () => {
    return localStorage.removeItem('tennis');
}

export const setRememberME = (token) => {
    const encryptedString = Crypter.encrypt(token);
    localStorage.setItem('tennis_remember',encryptedString);
}

export const getRememberME = () => {
    let remberme = localStorage.getItem('tennis_remember');

    if (remberme !== null && remberme !== undefined) {
        remberme = Crypter.decrypt(remberme);
    }

    return remberme;
}

export const removeRememberME = () => {
    return localStorage.removeItem('tennis_remember');
}

export const getUser = () => {
    const user = localStorage.getItem('tennis_user');    
    let userdata = null;

    if (user !== null && user !== undefined) {
        userdata = JSON.parse(Crypter.decrypt(user));
    }

    return userdata;
}

export const setUser = (data) => {
    const encryptedString = Crypter.encrypt(JSON.stringify(data));
    
    localStorage.setItem('tennis_user',encryptedString);
}

export const removeUser = () => {
    return localStorage.removeItem('tennis_user');
}