import React from "react";
import { Redirect } from "react-router-dom";
import { removeToken, removeUser } from "../Helpers/Token.js";
import Loader from '../components/Loader/Loader.jsx';

class Logout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading:true
    }
  }
  
  componentDidMount(){
    removeToken();
    removeUser();
    setTimeout(() => {
      this.props.history.push("/auth/login");
    }, 2000);
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <Loader isLoading = {isLoading}/>
      </>
    );
  }
}

export default Logout;
