const Rules = {
  login: [
    {
      field: 'email',
      method: 'isEmpty',
      validWhen: false,
      message: 'Email is required.'
    },
    {
      field: 'email',
      method: 'isEmail',
      validWhen: true,
      message: 'Invalid Email.'
    },
    {
      field: 'password',
      method: 'isEmpty',
      validWhen: false,
      message: 'Password is required.'
    }
  ],

  forgotpassword: [
    {
      field: 'email',
      method: 'isEmpty',
      validWhen: false,
      message: 'Email is required.'
    },
    {
      field: 'email',
      method: 'isEmail',
      validWhen: true,
      message: 'Invalid email.'
    }
  ],

  changepassword: [
    {
      field: 'old_password',
      method: 'isEmpty',
      validWhen: false,
      message: 'Old Password is required.'
    },
    {
      field: 'new_password',
      method: 'isEmpty',
      validWhen: false,
      message: 'New Password is required.'
    },
    {
      field: 'new_password',
      method: 'matches',
      validWhen: true,
      message: 'Invalid Password',
      args: ['new_password']
    },
    {
      field: 'confirm_password',
      method: 'isEmpty',
      validWhen: false,
      message: 'Confirm password is required.'
    },
    {
      field: 'confirm_password',
      method: 'equals',
      validWhen: true,
      message: 'Your passwords don\'t match.',
      args: ['new_password']
    },
  ],

  reset_password: [
    {
      field: 'tmp_password',
      method: 'isEmpty',
      validWhen: false,
      message: 'Temporary Password is required.'
    },
    {
      field: 'new_password',
      method: 'isEmpty',
      validWhen: false,
      message: 'New Password is required.'
    },
    {
      field: 'new_password',
      method: 'matches',
      validWhen: true,
      message: 'Invalid Password',
      args: ['new_password']
    },
    {
      field: 'confirm_password',
      method: 'isEmpty',
      validWhen: false,
      message: 'Confirm password is required.'
    },
    {
      field: 'confirm_password',
      method: 'equals',
      validWhen: true,
      message: 'Your passwords don\'t match.',
      args: ['new_password']
    },
  ],

  Register: [
    {
      field: 'email',
      method: 'isEmpty',
      validWhen: false,
      message: 'Email is required.'
    },
    {
      field: 'email',
      method: 'isEmail',
      validWhen: true,
      message: 'Invalid Email.'
    },
    {
      field: 'first_name',
      method: 'isEmpty',
      validWhen: false,
      message: 'First Name is required.'
    },
    {
      field: 'last_name',
      method: 'isEmpty',
      validWhen: false,
      message: 'Last Name is required.'
    }
  ],

  EditUser: [
    {
      field: 'firstName',
      method: 'isEmpty',
      validWhen: false,
      message: 'First Name is required.'
    },
    {
      field: 'lastName',
      method: 'isEmpty',
      validWhen: false,
      message: 'Last Name is required.'
    },
    {
      field: 'phoneNumber',
      method: 'isEmpty',
      validWhen: false,
      message: 'Phone Number is required.'
    },
    {
      field: 'phoneNumber',
      method: 'isNumeric',
      validWhen: true,
      message: 'Invalid phone format.',
    }
  ],

  Event: [
    {
      field: 'event_title',
      method: 'isEmpty',
      validWhen: false,
      message: 'Event title is required.'
    },
    {
      field: 'start_date',
      method: 'isEmpty',
      validWhen: false,
      message: 'Start date is required.'
    },
    {
      field: 'end_date',
      method: 'isEmpty',
      validWhen: false,
      message: 'End date is required.'
    },
    {
      field: 'start_time',
      method: 'isEmpty',
      validWhen: false,
      message: 'Start Time is required.'
    },
    {
      field: 'end_time',
      method: 'isEmpty',
      validWhen: false,
      message: 'End Time is required.'
    },
    {
      field: 'general_survey_url',
      method: 'isURL',
      validWhen: true,
      message: 'Please enter valid url.'
    },
  ],

  Notification: [
    {
      field: 'notification_title',
      method: 'isEmpty',
      validWhen: false,
      message: 'Notification title is required.'
    },
    // { 
    //   field: 'selected_multi_matches', 
    //   method: 'isEmpty', 
    //   validWhen: false, 
    //   message: 'Link is required.'
    // },
    {
      field: 'date',
      method: 'isEmpty',
      validWhen: false,
      message: 'Date is required.'
    },
    {
      field: 'time',
      method: 'isEmpty',
      validWhen: false,
      message: 'Time is required.'
    },
  ],

  CreateFaq: [
    {
      field: 'question',
      method: 'isEmpty',
      validWhen: false,
      message: 'Question is required.'
    },
    {
      field: 'answer',
      method: 'isEmpty',
      validWhen: false,
      message: 'Answer is required.'
    },
  ],

  AddPage: [
    {
      field: 'title',
      method: 'isEmpty',
      validWhen: false,
      message: 'Name is required.'
    },
    {
      field: 'content',
      method: 'isEmpty',
      validWhen: false,
      message: 'Body is required.'
    }
  ],
  AddFeed: [
    {
      field: 'message',
      method: 'isEmpty',
      validWhen: false,
      message: 'Body is required.'
    }
  ],

  createTournament: [
    {
      field: 'tournamentTitle',
      method: 'isEmpty',
      validWhen: false,
      message: 'Tournament Title is required.'
    },
    {
      field: 'tournamentStatus',
      method: 'isEmpty',
      validWhen: false,
      message: 'Tournament Status is required.'
    },
    {
      field: 'post_images',
      method: 'isEmpty',
      validWhen: false,
      message: 'Tournament Image is required.'
    }
  ],

  createMatch: [
    {
      field: 'title',
      method: 'isEmpty',
      validWhen: false,
      message: 'Title is required.'
    },
    {
      field: 'match_info',
      method: 'isEmpty',
      validWhen: false,
      message: 'Match info is required.'
    },
    {
      field: 'player_one_id',
      method: 'isEmpty',
      validWhen: false,
      message: 'Player one is required.'
    },
    {
      field: 'player_two_id',
      method: 'isEmpty',
      validWhen: false,
      message: 'Player two is required.'
    },
    {
      field: 'possible_winner_id',
      method: 'isEmpty',
      validWhen: false,
      message: 'Tip Player is required.'
    },
    {
      field: 'tournament_id',
      method: 'isEmpty',
      validWhen: false,
      message: 'Tournament is required.'
    },
    {
      field: 'match_time',
      method: 'isEmpty',
      validWhen: false,
      message: 'Time is required.'
    },
    {
      field: 'tip_rate',
      method: 'isEmpty',
      validWhen: false,
      message: 'Tip rate is required.'
    },
    {
      field: 'match_date',
      method: 'isEmpty',
      validWhen: false,
      message: 'Date is required.'
    },
    {
      field: 'winning_chances',
      method: 'isEmpty',
      validWhen: false,
      message: 'Winning chance is required.'
    },
    {
      field: 'display_on',
      method: 'isEmpty',
      validWhen: false,
      message: 'Display on is required.'
    },
    {
      field: 'publish_at',
      method: 'isEmpty',
      validWhen: false,
      message: 'Publish date is required.'
    },
    {
      field: 'publish_at_time',
      method: 'isEmpty',
      validWhen: false,
      message: 'Publish time is required.'
    },
  ],

  MultipleMatch: [
    {
      field: 'selected_matches_list',
      method: 'isEmpty',
      validWhen: false,
      message: 'Mathes is required.'
    },
    {
      field: 'tip_rate',
      method: 'isEmpty',
      validWhen: false,
      message: 'Tip rate is required.'
    },
    {
      field: 'description',
      method: 'isEmpty',
      validWhen: false,
      message: 'Description is required.'
    },
    {
      field: 'winning_chances',
      method: 'isEmpty',
      validWhen: false,
      message: 'Winning chance is required.'
    }
  ],

  createPlayer: [
    {
      field: 'playerName',
      method: 'isEmpty',
      validWhen: false,
      message: 'Player Name Title is required.'
    },
    {
      field: 'country',
      method: 'isEmpty',
      validWhen: false,
      message: 'Country is required.'
    }
  ],
}

export default Rules;