import React, { Component } from 'react';
import images from '../ImagesUrl/imagesUri';

class TextImageFeed extends Component {
    constructor(props) {
        super(props)
    }

    selectTextImageFeed(matchData) {
        let matchTitle = matchData.title;
        let playerImage = matchData.player_images; 
        

        if(matchTitle.length < 50 && playerImage.length == 1 ) {
            return( this.singleLineTextWithSingleImg(matchData))
        }

        if(matchTitle.length < 50 && playerImage.length == 2 ){
            return( this.singleLineTextWithDoubleImg(matchData))
        }

        if(matchTitle.length < 50 && playerImage.length == 3 ){
            return( this.singleLineTextWithTripleImg(matchData))
        }

        if(matchTitle.length > 50 && playerImage.length > 0 ){
            return( this.multiLineTextWithMultiImg(matchData))
        }
    }

    singleLineTextWithSingleImg(matchData){
        return(
            <>
                <div className="new_second_part pt-3 new_second_part_one">
                    <p className="m-0">{matchData.title}</p>
                </div>
                <div className="col-md-12 p-0 mt-3 news_third_part_one">
                    <img alt="img" src={matchData.player_images[0]} className="width_height_100 border-10"/>
                </div>
            </>
        )
    }

    singleLineTextWithDoubleImg(matchData){
        let matchTitle = (matchData.title.length < 100)? matchData.title : matchData.title.substr(0,100)+'...';
        let playerImages = matchData.player_images;
        let playerOneName = (matchData.player_one) ? matchData.player_one.player_name.split(' ')[0] : "";
        let playerTwoName = (matchData.player_two) ? matchData.player_two.player_name.split(' ')[0] : "";

        return(
            <>
                <div className="col-md-12">
                    <div className="row pt-3 news_third_part news_third_part_five">
                        <div className="col p-0">
                            <img src={playerImages[0]?playerImages[0]:images.emptyProfile} className="border-10 width_height_100"/>
                        </div>
                        <div className="col p-0 ml-auto">
                            <img src={playerImages[1]?playerImages[1]:images.emptyProfile} className="border-10 width_height_100"/>
                        </div>
                    </div>
                    <div className="row winner_name">
                        <div className="col-md-6 p-0 text-center"> { playerOneName } </div>
                        <div className="col-md-6 p-0 ml-auto text-center">{ playerTwoName}</div>
                    </div>
                    <div className="new_second_part pt-3 new_second_part_two">
                        <p className="m-0"> { matchTitle } </p>
                    </div>
                </div>
            </>
        )
    }

    singleLineTextWithTripleImg(matchData){
        let matchTitle = matchData.title;
        let playerImages = matchData.player_images;
        return(
            <> 
                <div className="new_second_part pt-3 new_second_part_one">
                    <p className="m-0">{matchTitle}</p>
                </div>
                <div className="col-md-12">
                    <div className="row pt-3 news_third_part news_third_part_three">
                        <div className="col p-0 mr-7">
                            <img src={matchData.player_images[0]?matchData.player_images[0]:images.emptyProfile} className="border-10 width_height_100"/>
                        </div>
                        <div className="col ml-3-5 mr-3-5 p-0">
                            <img src={matchData.player_images[1]?matchData.player_images[1]:images.emptyProfile} className="border-10 width_height_100"/>
                        </div>
                        <div className="col p-0 ml-7">
                            <img src={matchData.player_images[2]?matchData.player_images[2]:images.emptyProfile} className="border-10 width_height_100"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    multiLineTextWithMultiImg(matchData){
        let matchTitle = (matchData.title.length < 100)? matchData.title : matchData.title.substr(0,100)+'...';
        let playerImages = matchData.player_images;
        let playerOneName = (matchData.player_one) ? matchData.player_one.player_name.split(',')[1] : "";
        let playerTwoName = (matchData.player_two) ? matchData.player_two.player_name.split(',')[1] : "";
        return(
            <> 
                {playerImages.length ==1 ?
                <div className="col-md-12 p-0 mt-3 news_third_part_two">
                    <img alt="img" src={playerImages[0?playerImages[0]:images.emptyProfile]} className="width_height_100 border-10"/>
                </div>:

                playerImages.length == 2 ?
                <div className="col-md-12">
                    <div className="row pt-3 news_third_part news_third_part_five">
                        <div className="col p-0">
                            <img src={playerImages[0?playerImages[0]:images.emptyProfile]} className="border-10 width_height_100"/>
                        </div>
                        <div className="col p-0 ml-auto">
                            <img src={playerImages[1]?playerImages[1]:images.emptyProfile} className="border-10 width_height_100"/>
                        </div>
                    </div>
                    <div className="row winner_name">
                        <div className="col-md-6 p-0 text-center"> { playerOneName } </div>
                        <div className="col-md-6 p-0 ml-auto text-center">{ playerTwoName }</div>
                    </div>
                    <div className="new_second_part pt-3 new_second_part_two">
                        <p className="m-0">{ matchTitle }</p>
                    </div>
                </div>:

                playerImages.length == 3 ? 
                <div className="col-md-12">
                    <div className="row pt-3 news_third_part news_third_part_three">
                        <div className="col p-0 mr-7">
                            <img src={playerImages[0]?playerImages[0]:images.emptyProfile} className="border-10 width_height_100"/>
                        </div>
                        <div className="col ml-3-5 mr-3-5 p-0">
                            <img src={playerImages[1]?playerImages[1]:images.emptyProfile} className="border-10 width_height_100"/>
                        </div>
                        <div className="col p-0 ml-7">
                            <img src={playerImages[2]?playerImages[2]:images.emptyProfile} className="border-10 width_height_100"/>
                        </div>
                    </div>
                </div>:''}
            </>
        )
    }

    render() {
        const { match } = this.props;
        if(match == undefined || match ==null) {
            return 
        }
        return (
            <>
                {this.selectTextImageFeed(match)}
            </>
        );
    }
}

export default TextImageFeed;