import Request from '../Helpers/API';
// Old API
export const getAllFeeds = (params) => {
  
    const api_url = `admin/feeds-list`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};
//
export const getTournamentList = (params) => {
  
    const api_url = `admin/tournaments-list`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};
// Old API
export const getPost = (params,id) =>{
    const api_url = `admin/user-posts/${id}`;
    
    const req = new Request(api_url, params);
    
    return req.put();
}
//
export const getTournamentDetails = (params,id) =>{
    const api_url = `admin/tournaments-details/${id}`;
    
    const req = new Request(api_url, params);
    
    return req.get();
}
//old API
export const deletePost = (id, isSchedule) =>{
    const api_url = `admin/user-posts/${id}/${isSchedule}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}
//
export const softDeleteTournament = (id) =>{
    const api_url = `/admin/tournaments-soft-delete/${id}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}

export const hardDeleteTournament = (id) =>{
    const api_url = `/admin/tournaments-delete/${id}`;
    const req = new Request(api_url);
    return req.delete();
}

export const restoreTournament = (id) =>{
    const api_url = `/admin/tournaments-restore/${id}`;
    const req = new Request(api_url);
    return req.patch();
}

//old API
export const updateStatusPost = (id, params) =>{
    const api_url = `/admin/user-posts/status/${id}`;
    
    const req = new Request(api_url, params);
    
    return req.post();
}

export const updateStatus = (id, params) =>{
    const api_url = `/admin/change-tournament-status/${id}`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const createTournament = (params) =>{
    const api_url = `admin/tournaments-create`;
    let formdata = new FormData();
    Object.keys(params).forEach((e) => {
        if(e === "post_images"){
            console.log(e);
            params[e].map((ele)=>{
            console.log(ele);
               formdata.append(e, ele || '');
            });
        } else {
            formdata.append(e,params[e] || '');
        }
    });
    
    const req = new Request(api_url, formdata);
    return req.post();
}
export const updateTournament = (params) =>{
    const api_url = `admin/tournament-update`;

    let formdata = new FormData();
    Object.keys(params).forEach((e) => {
        if(e === "post_images"){
            console.log(e);
            params[e].map((ele)=>{
            console.log(ele);
               formdata.append(e, ele || '');
            });
        } else {
            formdata.append(e,params[e] || '');
        }
    });
    
    const req = new Request(api_url,formdata);
    
    return req.patch();
}

export const deleteComment = (id) =>{
    const api_url = `admin/delete-comment/${id}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}

export const updatePost = (params, id) =>{
    const api_url = `admin/update-feed/${id}`;

    let formdata = new FormData();
    Object.keys(params).forEach((e) => {
        if(e === "post_images"){
            console.log(e);
            params[e].map((ele)=>{
            console.log(ele);
               formdata.append(e, ele || '');
            });
        } else {
            formdata.append(e,params[e] || '');
        }
    });
    
    const req = new Request(api_url,formdata);
    
    return req.put();
}




