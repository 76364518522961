import React, { Component } from 'react';
import Loader from "react-loader";
import Modal from 'react-modal';


const customStyles = {
    content : {
      height                 : '200px',
      width                  : '200px',
      backgroundColor:'transparent',
      border:0
    //   borderRadius:'100%'
    //   transform             : 'translate(-50%, -50%)'
    }
  };

class LoadingSpinner extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            modalIsOpen:false
        }
    }
    closeModal = () =>{
        document.body.style.overflow = 'unset';
        this.setState({modalIsOpen:false});
       
      }
    render() {
        // console.log("Loader props",this.props);
       
        return (
           
            <Modal
                isOpen={this.props.isLoading}
                // onAfterOpen={this.afterOpenModal.bind(this)}
                onRequestClose={this.closeModal.bind(this)}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
                >

                <Loader
                    loaded={!this.props.isLoading}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#4aa546"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </Modal>
           
        );
    }
}

export default LoadingSpinner;