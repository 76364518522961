import React, { Component } from 'react';
import { agoTime } from '../Helpers/TimeConversion';
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";

class TournamentRightSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.feed !== this.props.feed && this.props.feed !== null) {
      const heightFirst = this.divElementNF.clientHeight;
      const heightLast = this.divElementButtonNF.clientHeight;
      const heightTop = window.innerHeight;

      const divHeight = heightTop - heightLast - heightFirst;
      this.setState({ height: divHeight });
    }
  }

  _renderComments = (commentList, deleteComment) => {
    return commentList.map((comment, key) => {
      
    const avtar = (comment.profile_pic)?  <img src={comment.profile_pic} className="user-sm-img mr-3" alt="" /> : <span className="avtar_text">{`${comment.first_name.charAt(0)}${comment.last_name.charAt(0)}`}</span>;

      return <div className="d-flex align-center pb-3" key={comment.comment_id}>
      <div> {avtar} </div>
      <div className="comment-box">
      <div className="comment_heading"> {comment.comment_by_name} </div>
          <div className="comment_text"> {comment.comment_text} </div>
            <Link>
              <img alt='img'
                src={require("../Assets/images/close2.svg")}
                className="close-icon" onClick={() => deleteComment(key, comment)}
              />
            </Link>
         </div>
      </div>
    });
  }

  render() {
    const { height } = this.state;
    const { feed, feedCreator, deleteFeed, _statusChange, deleteComment } = this.props;
    
    if(feed === null) {
      return <></>;
    }

    // const avtar = (feedCreator.profile_pic)?  <img src={feedCreator.profile_pic} className="user-sm-img" alt="" /> : <span className="avtar_text">{`${feedCreator.first_name.charAt(0)}${feedCreator.last_name.charAt(0)}`}</span>;

    const feedImages = feed.image;
    // const postLikes = feed.like_count ? feed.like_count : 0 ;
    // const postComments = feed.comment_data ? feed.comment_data : [] ;
    return (
      <div className="col p-0" >
        <div className="right_section_div p-30">
          <div className="col-md-12 pb-3" ref={(divElementNF) => { this.divElementNF = divElementNF }}>
            <div className="row">
            <div className="align-center d-flex col p-0">
                <div className="new_left_part d-flex align-center"> 
                  {/* <div>
                    <h5 className="m-0">{feed.title}</h5>
                  </div> */}
                </div>
                {/* <div className="new_right_part text-right ml-auto d-flex">
                  <label className="switch big">
                    <input type="checkbox" onClick={() => _statusChange(feedCreator.feed_key, feed) } checked={(feed.is_active)? true : false}/>
                  </label>
                </div> */}
              </div>
            </div>
          </div>
          {/* <SimpleBar style={{ height }}> */}
          <div style={{ height, overflow: "auto" }}>
            <>
            <div className="col-md-12">
              {feed.title ? <div className="row">
                <div className="new_second_part">
                  <p className="m-0">{feed.title}</p>
                </div>
              </div> : null}

              <div className="row news_third_part pt-3">
              {feedImages.length > 0 ?
                <Carousel 
                  showThumbs= {false} 
                  showArrows={true} 
                  showIndicators={true} 
                  autoPlay={true} 
                  transitionTime={500}
                  infiniteLoop={true}  
                >
                  {feedImages.map((image,index)=>{
                    return (
                      <div className="col-md-12 p-0 text-left img-fluid border-10" key={index}>
                        <img src={image} alt="" className="border-10"/>
                      </div>
                    )
                  })}
                
                </Carousel> : ''}
              
              </div>
            </div>
            <div className="col-md-12"  >
            </div>
            </>
          {/* </SimpleBar> */}
          </div>
        </div>
        <div className={"fixed_section"} ref={(divElementButtonNF) => { this.divElementButtonNF = divElementButtonNF }}>
          <Link style={{cursor:(feed.is_deleted == false)?"pointer":"not-allowed" }}
            className="btn common_btn blue_btn big_btn"
            role="button"
            to= {(feed.is_deleted == false)?{pathname: "edit-tournament", state: feed }:"#"}
          >
            Edit
          </Link> 
          {(feed.is_deleted == false)?
          <Link to ="#" className="btn common_btn big_btn pink_btn mt-3" style={{cursor:(feed.is_deleted == false)?"pointer":"not-allowed" }} role="button"  onClick={() => deleteFeed(feedCreator.feed_key, feed)}>
            Delete
          </Link>:
          <Link to ="#" className="btn common_btn big_btn pink_btn mt-3" style={{cursor:(feed.is_deleted == false)?"pointer":"not-allowed" }} role="button">
            Delete
          </Link>}
        </div>
      </div>
    );
  }
}

export default TournamentRightSidebar;
