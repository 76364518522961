import validator from 'validator';


class FormValidator {
  
    constructor(validations) {
        // validations is an array of rules specific to a form
        this.validations = validations;
    }

    validate(state) {
        console.log('--------state-------------',state)
        // start out assuming valid
        let validation = this.valid();
        // for each validation rule
        this.validations.forEach(rule => {
            console.log('--------rule-------------',rule)
            // if the field isn't already marked invalid by an earlier rule
            if (!validation[rule.field].isInvalid) {
                
                // determine the field value, the method to invoke and
                // optional args from the rule definition
                const field_value = state[rule.field].toString();
                let args = rule.args || [];
                const validation_method = typeof rule.method === 'string' ? validator[rule.method] :rule.method
                if (rule.method === 'equals') {
                    args = args[0];    
                    args = [state[rule.args]];
                }
                if(rule.method == "matches"){
                    if(!validator.matches(state[rule.args], '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})' ,'i')){
                        validation[rule.field] = { 
                            isInvalid: true, 
                            message: rule.message 
                        }
                        validation.isValid = false;
                    }    
                // } else if(rule.method == "isMobilePhone"){
                //     if(!validator.isMobilePhone(field_value, state.local)){
                //         validation[rule.field] = { 
                //             isInvalid: true, 
                //             message: rule.message 
                //         }
                //         validation.isValid = false;
                //     }    
                } else if(rule.method == "isURL"){ 
                    if((field_value && validation_method(field_value, ...args, state) !== rule.validWhen)) {
                        validation[rule.field] = { 
                            isInvalid: true, 
                            message: rule.message 
                        }
                        validation.isValid = false;
                    }
                } else if(rule.method != "matches"){
                    if(validation_method(field_value, ...args, state) !== rule.validWhen) {
                        validation[rule.field] = { 
                            isInvalid: true, 
                            message: rule.message 
                       }
                        validation.isValid = false;
                    }
                } 
            }
        });

        return validation;
    }

  // create a validation object for a valid form
    valid() {
        
        const validation = {}
    
        this.validations.map(rule => (
            validation[rule.field] = { isInvalid: false, message: '' }
        ));
        
        return { isValid: true, ...validation };
    }
}

export default FormValidator;