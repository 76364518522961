import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './Assets/css/style.css';
import './Assets/css/fonts.css';


import './Assets/css/bootstrap.css';
import './Assets/css/responsive.css';
import './Assets/css/scroll.css';
import './Assets/css/custom.css';
import * as serviceWorker from './serviceWorker';
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth.jsx";
import {ToastMsg } from './components/Toast/Toast';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/admin" render={props => <AdminLayout {...props} />} />
            <Route path="/auth" render={props => <AuthLayout {...props} />} />
            <Redirect from="/" to="/auth/login" />
        </Switch>
    <ToastMsg/>
    </BrowserRouter>,
   document.getElementById('root')
   );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
