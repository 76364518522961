import React, { Component } from 'react';
import InputError from "../components/Error/InputError";
import Rules from "../Helpers/FormRules";
import FormValidator from "../Helpers/FormValidator";
import { errorToast, successToast } from '../components/Toast/Toast.js';
import * as _ from "lodash";
import Loader from '../components/Loader/Loader.jsx';
import { getTime, extractDate, concatDateTime, extractTime} from '../Helpers/TimeConversion';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Template from "../Helpers/Raw-Data.js";
import Styles from "../Helpers/Select-Styles.js";
import { getMatchList } from '../Actions/match.js';
import { createMultipleMatch, updateMultipleMatch } from '../Actions/multipleMatch.js';
import { Link } from "react-router-dom";
import images from "../components/ImagesUrl/imagesUri"

class CreateMultipleMatches extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator(Rules.MultipleMatch);
        this.state = {
            validation: this.validator.valid(),
            isLoading: false,
            isShowMatchesPlaceholder: true,
            isShowWinChancePlaceholder: true,
            matches_list: [],
            edit_mode: false,
            multiple_match_id: "",
            winning_color: "",
            formData:{
                select_match_ids: [], 
                description: '',
                tip_rate: '',
                selected_matches_list: [],
                winning_chances:''
            },
        }  
        this.formsubmitted = false;
    }

    componentDidMount() {
        this.getMatches();
        if(this.props.location.state){
            // this.getMatch(matchId);
            const MatchData = this.props.location.state;
            const winningColor =  _.find(Template.winning_chances, (ele) => { return ele.id == (MatchData.winning_chances)?MatchData.winning_chances:null });
            const multipleMatches = (MatchData.matchesIdsForMultipleMatches)?MatchData.matchesIdsForMultipleMatches:[];
            let matchList = [];
            _.map(MatchData.matchesIdsForMultipleMatches, (val, key)=>{
                val.match.value = val.match.id;
                val.match.label = val.match.title;
                matchList = [...matchList, val.match];
            });
            this.setState({
                winning_color:(MatchData.winning_chances)? winningColor.value: "",
                multiple_match_id: this.props.location.state.id, 
                edit_mode: true,
                formData: {
                    description:(MatchData.description)?MatchData.description: "",
                    tip_rate: (MatchData.tip_rate)?MatchData.tip_rate: "",
                    selected_matches_list:  matchList,
                    winning_chances:(MatchData.winning_chances)?MatchData.winning_chances: "",
                },
            });
        }   
    }

    getMatches(){
        this.setState({isLoading:true})
        getMatchList({
            "page": 1,
            "limit": 10,
            "search":"",
            "sort_by":{"name":"created_at", "order":"DESC"},
            "status": 1,
            "display_on":true
        }).then((response)=>{
            const result = response.data.success;
            result.data.matchesAndTipsList.map((val, key) =>{
                val.value = val.id;
                val.label = val.title;
                delete val.id;
                delete val.title;
            });
            this.setState({ matches_list: result.data.matchesAndTipsList ,isLoading:false});
        }).catch(error =>{
            if (error.response) {
                const errorResult = error.response.data.error;
                this.setState({err:true,message:'Server Error!', isLoading:false});
                errorToast(errorResult.message);
            } 
        });
    }

    // getMatch(matchId){
    //     console.log("))))))",matchId);
    //     getMatchDetails(matchId).then((response)=>{
    //         const result = response.data.success;
    //         const MatchData = (result.data)? result.data : "";
    //         const winningColor =  _.find(Template.winning_chances, (ele) => { return ele.label == this.state.formData.winning_chances });
    //         const matchState = {
    //             "title": MatchData.title,
    //             "player_one_id": MatchData.player_one_id,
    //             "player_two_id": MatchData.player_two_id,
    //             "player_one_name": (MatchData.player_one)?MatchData.player_one.player_name:'',
    //             "player_two_name": (MatchData.player_two)?MatchData.player_two.player_name:'',
    //             "possible_winner_id": MatchData.possible_winner_id,
    //             "selecetedMatches": MatchData.selecetedMatches,
    //             "description": MatchData.description,
    //             "tip_rate": MatchData.tip_rate,
    //             "match_time": extractTime(MatchData.date_and_time, "HH:mm"),
    //             "match_date": MatchData.date_and_time,
    //             "winning_chances": MatchData.winning_chances,
    //         };
    //         this.setState({
    //            formData:matchState,
    //            winning_color: winningColor.value,
    //            multiple_match_id: matchId, 
    //         });
    //     }).catch(error =>{
    //         if (error.response) {
    //             const errorResult = error.response.data.error;
    //             this.setState({err:true,message:'Server Error!', isLoading:false});
    //             errorToast(errorResult.message);
    //         } 
    //     });
    // }

    onChange = (e) =>{
        let {name, value} = e.target;
        let formData = { ...this.state.formData };
        value = (value == " ")? "" : value;
        formData[name] = value;
        this.setState({ formData});
        this.setState({[name]: value});
    }

    onMenuOpen( placeholderType, e){
         if(placeholderType == "selectMatch"){
            this.setState({ isShowMatchesPlaceholder: false });
        }else if(placeholderType == "winning_chances"){
            this.setState({ isShowWinChancePlaceholder: false });
        } 
    }

    onMenuClose(placeholderType ,e){
        this.setState({ 
            isShowMatchesPlaceholder: true,
            isShowWinChancePlaceholder: true
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const validation = this.validator.validate(this.state.formData);
        this.setState( { validation, isLoading: true }, () =>{
            this.submitted = true;
            if (validation.isValid) {
                const { edit_mode, formData, multiple_match_id } = this.state;
                const matchRequestData = {
                    "description": formData.description,
                    "tip_rate": formData.tip_rate,
                    "match_ids": _.map(formData.selected_matches_list, "value"), 
                    "winning_chances": formData.winning_chances,
                };
                const apiMethod = (edit_mode) ? updateMultipleMatch(matchRequestData, multiple_match_id): createMultipleMatch(matchRequestData);
                apiMethod.then((response) =>{
                    let result = response.data;
                    this.setState( { isLoading: false }, () =>{
                        if(result.success){
                            successToast(result.success.message);
                                setTimeout(() => {
                                    this.props.history.push("/admin/multiple/multiple-matches-list");
                                },1000);
                        } else {
                            errorToast(result.error.message);
                        }
                    });
                }).catch((error)=> {
                    if (error.response) {
                        const errorResult = error.response.data.error;
                        this.setState({err:true,message:'Server Error!', isLoading:false});
                        errorToast(errorResult.message);
                    } 
                });
            } else {
              this.setState({ isLoading:false });
            }
        });
    }

    onSelectChange = (newValue, actionMeta) => {
        if(!(actionMeta.action == "pop-value")){
            let { formData } = this.state;
            formData["selected_matches_list"] = (newValue)? newValue : [];
            this.setState({ formData });
        }
    };

    onSelect = (newValue, actionMeta) => {
        let { formData, winning_color} = this.state;
        if(actionMeta.name == "winning_chances"){
            formData[actionMeta.name] = (newValue)? newValue.id : "";
            winning_color = (newValue)? newValue.value : "";
        } else {
            formData[actionMeta.name] = (newValue)? newValue.value : "";
        }
         this.setState({ formData, winning_color });
    };

    removeMatch = (e) => {
        let {formData, selected_matches_list } = this.state;
        _.remove(formData.selected_matches_list, (x) => {
            return x.value === e.target.id;
        });
        this.setState({formData, selected_matches_list});
    }

    render() {
        const { formData, isLoading, isShowMatchesPlaceholder, matches_list, edit_mode, winning_color,isShowWinChancePlaceholder} = this.state;
        const validation = this.submitted ? this.validator.validate(this.state.formData) : this.state.validation;

        return (
            <div className="agenda_session_form_section agenda_padding">
                <div style={{height:'90vh', overflow: "auto"}} className="mobile-scrollbar-height">
                    <div className="text-left col-md-12 p-0">
                        <form className="col-md-12 p-0" autoComplete="off">
                            <div className="row m-0">
                                <div className="d-md-none col-12 mb-3 ">
                                    <h2 className=" m-0 speakers_heading pt-3"> Create Multiple Match</h2>
                                </div>
                                <div className="col-md-6 p-0">
                                    {/* <div className="form-group col-md-12 form-custom custom-height">
                                        <div className="row mb-3">
                                            <label htmlFor="inputEmail4" className="col left_label m-0"> Title </label>
                                            <label htmlFor="inputEmail4" className="col text-right right_label m-0">{formData.title.length} of 120 Characters</label>
                                        </div>
                                        <input className="form-control" 
                                            id="inputEmail4" 
                                            placeholder="Enter Title"
                                            type="text"
                                            name="title"
                                            tabIndex="1"
                                            maxLength={120}
                                            value={formData.title}
                                            onChange={this.onChange}
                                            autoFocus
                                        />
                                        <InputError show={validation.title.isInvalid} message={validation.title.message} />
                                    </div> */}
                                    <div className="form-group col-md-12 form-custom custom-height assign_in_tags common_dropdown">
                                        <label htmlFor="inputState" className="left_label mb-3  m-mb-10"> Winning Chances </label>
                                        <div className="color-box" style={{ backgroundColor: winning_color }}> </div>
                                        <Select
                                            isClearable = { false }
                                            isSearchable = { false }
                                            onChange = { this.onSelect }
                                            options = { Template.winning_chances }
                                            placeholder = { (isShowWinChancePlaceholder) ? "Winning Chances" : ""}
                                            value = { _.find(Template.winning_chances, (ele) => { return ele.id == formData.winning_chances }) }
                                            name = "winning_chances"
                                            onMenuOpen = {this.onMenuOpen.bind(this, "winning_chances")}
                                            onMenuClose = {this.onMenuClose.bind(this, "winning_chances")}
                                            styles = { Styles.colourStyles }
                                            menuPlacement= "auto"
                                            components = { { DropdownIndicator:() => null } }
                                        />
                                        <InputError show={validation.winning_chances.isInvalid} message={validation.winning_chances.message} />
                                    </div>
                                    
                                    <div className="form-group col-md-12 form-custom custom-height m-0 assign_in_tags common_dropdown">
                                        <label htmlFor="inputEmail4" className="left_label mb-3  m-mb-10">Rate</label>
                                            <input 
                                                className = "form-control" 
                                                id = "tip_rate" 
                                                type = "number"
                                                name = "tip_rate"
                                                tabIndex = "2"
                                                value = { formData.tip_rate }
                                                onChange = {this.onChange.bind(this)}
                                            />
                                            <InputError show={validation.tip_rate.isInvalid} message={validation.tip_rate.message} />
                                    </div>
                                    <div className="form-group col-md-12 form-custom m-mb-25">
                                        <div className="row mb-3 m-mb-10">
                                            <label htmlFor="inputEmail4" className="col left_label m-0 col-4 m-pr-0"> Description </label>
                                            <label htmlFor="inputEmail4" className="col text-right right_label m-0 col-8 m-pl-0"> {formData.description.length} of 400 Characters</label>
                                        </div>
                                        <textarea className="form-control" 
                                            id = "exampleFormControlTextarea1" 
                                            rows = "5" 
                                            maxLength = {400}
                                            tabIndex = "3"
                                            name = "description" 
                                            value = {formData.description}
                                            onChange={this.onChange.bind(this)}
                                        >
                                        </textarea>
                                        <InputError show={validation.description.isInvalid} message={validation.description.message} />
                                    </div>                        
                                    
                                </div>

                                <div className="col-md-6 p-0">
                                    <div className="form-group col-md-12 form-custom custom-height assign_in_tags common_dropdown">
                                        <label htmlFor="inputState" className="left_label mb-3"> Matches </label>
                                            <Select
                                                isMulti
                                                isClearable = {false}
                                                isSearchable = {true}
                                                onChange = {this.onSelectChange}
                                                options = { matches_list }
                                                tabIndex = "4"
                                                placeholder= {(isShowMatchesPlaceholder)? "Select Matches": ""}
                                                value = {formData.selected_matches_list}
                                                controlShouldRenderValue={false}
                                                name = "multi"
                                                components = {{ DropdownIndicator:() => null }}
                                                onMenuOpen = {this.onMenuOpen.bind(this,"selectMatch")}
                                                onMenuClose = {this.onMenuClose.bind(this,"selectMatch")}
                                                styles={Styles.colourStyles}
                                            />
                                        <InputError show={validation.selected_matches_list.isInvalid} message={validation.selected_matches_list.message} />
                                    </div>
                                    {(formData.selected_matches_list.length > 0) ?
                                        <div className="form-group col-md-12 form-custom mb-2">
                                            {formData.selected_matches_list.map((matchData, key)=>{
                                                let playerOneName = (matchData.player_one)? matchData.player_one.player_name.split(' ') : [];
                                                let playerTwoName = (matchData.player_two)? matchData.player_two.player_name.split(' ') : [];
                                                playerOneName = `${playerOneName[0].trim().charAt(0)}. ${playerOneName[1] }`;
                                                playerTwoName = `${playerTwoName[0].trim().charAt(0)}. ${playerTwoName[1] }`;
                                                let possibleWinner = (matchData.possible_winner)? matchData.possible_winner.player_name.split(' ') : [];
                                                possibleWinner = (possibleWinner.length > 0)? `${possibleWinner[0].charAt(0)}. ${possibleWinner[1] }` : "";
                                                return (
                                                    <div className="blue_tag_btn matches_dropdown" key={key}>

                                                        {/* <img className="close-icon" src={images.close2} alt="cross" id = {matchData.value} onClick={this.removeMatch} /> */}
                                                        <div className="cross_btn">
                                                            <img className="close-icon" src={images.close2} alt="cross" id = {matchData.value} onClick={this.removeMatch} />
                                                        </div>
                                                        <div className="row m-0">    
                                                            <div className="col-md-6 bidder-name p-0 col-6">
                                                                <h6>{ possibleWinner} </h6>
                                                                <p className="m-0"  style={{color:'black'}}> Winner of the match @ { matchData.tip_rate }</p>
                                                                <div><p className="m-0"  style={{color:'black'}}>{playerOneName} vs {playerTwoName} </p></div>
                                                            </div>
                                                            <div className="col-md-6 circules_section col-6">
                                                                <ul className="pr-0 list-unstyled">
                                                                    <li className={(matchData.winning_chances == 1 ) ? "red active" : "red" } ></li>
                                                                    <li className={(matchData.winning_chances == 2  ) ? "yellow active" : "yellow" } ></li>
                                                                    <li className={(matchData.winning_chances == 3 ) ? "green active" : "green" }  ></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )    
                                            })}
                                        </div>: null
                                    }
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="col-md-6 pl-0 m-pr-0">
                                        <div className="row">
                                            <div className="col-md-6 col-6 m-pr-7">
                                                <button
                                                    className="btn common_btn blue_btn big_btn fixed_width_btn"
                                                    style = {{marginBottom:'15px'}}
                                                    role="button"
                                                    tabIndex="5"
                                                    disabled = { isLoading }
                                                    onClick = {this.onSubmit.bind(this)}
                                                    >{ edit_mode == true ? "Update" : "Save"} 
                                                </button>
                                            </div>
                                            <div className="col-md-6 col-6 m-pl-7">
                                                <button  className="btn common_btn gray_btn big_btn fixed_width_btn "
                                                    role="button"
                                                    type="button"
                                                    onClick={ () => { this.props.history.push("/admin/multiple/multiple-matches-list")}}
                                                > Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>	
                    </div>
                </div>
                <Loader isLoading = { isLoading }/>
            </div>
        );
    }

}

export default CreateMultipleMatches;