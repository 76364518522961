import React, { useEffect, useState } from 'react';
import * as _ from "lodash";
import ReactTable from 'react-table';
import "react-table/react-table.css";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Loader from '../components/Loader/Loader.jsx';
import { errorToast, successToast } from '../components/Toast/Toast.js';
import { getPlayersList, deletePlayer } from '../Actions/players.js';
import images from "../components/ImagesUrl/imagesUri";
import DeleteModal from '../components/Modal/DeleteModal.jsx';
import { FILE_URL } from '../Helpers/Config.js';
import { getAvtarText } from '../Helpers/CommonHelpers.js';
import SortBy from '../components/SortBy';

const defaultFields = {
    "player_name": "",
    "country": "",
    "country_code":""
};

const defaultSortBy = {
    "name":"player_name",
    "order":"ASC"
};

const defaultColumn =   [ 
    {accessor: "player_image_url", key: "Image", Header:'Image', minWidth: 80, sortable: false, filterable: false},
    {accessor: "player_name", key: "Player Name", Header:'Player Name', minWidth: 180},
    {accessor: "country", key: "Country", Header:'Country', minWidth: 140},
    {accessor: "country_code", key: "Country Code", Header:'Country Code', minWidth: 80},
    {accessor: "action", key: "Action", Header:"Actions", minWidth: 40, sortable: false, filterable: false},
];

const PlayersList = (props) => {
    const limit = 100;
    const [loading, setLoading] = useState(true);   
    const [playersList, setPlayersList] = useState([]);
    const [totalPlayers, setTotalPlayers] = useState([]);
    const [column, setColumn] = useState([...defaultColumn]);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({...defaultFields});
    const [sortBy, setSortBy] = useState({...defaultSortBy});
    const [showModal, setShowModal] = useState(false);
    const [rowId, setRowId] = useState(null);

    useEffect(() => {
        function prepareTablefields(){
            defaultColumn.map((val, idx)=>{
                const header = val.key;
                val.label = (val.label)? val.label : val.Header;
                if(!val.hasOwnProperty("sortable") || val.sortable){
                    val.Header = ()=>{
                        return(
                            <>
                                <SortBy 
                                    row={val} 
                                    sortBy={sortBy} 
                                    header_name={val.label} 
                                    onSorter={onSorterChange}
                                />
                            </>
                        )    
                    }
                }    

                if(val.accessor == "player_image_url"){
                    val.Cell = (props) => (
                        <div style={{textAlign: "center"}}>
                            {(props.original.player_image_url)?
                                <img
                                    src={`${props.original.player_image_url}`}
                                    className="c-avatar-img-medium"
                                    alt={getAvtarText(props.original.player_name)}
                                />
                                :
                                <span className="avtar_medium"> {getAvtarText(props.original.player_name)} </span>
                            }
                        </div>    
                    );
                }

                if(val.accessor == "action"){
                    val.Cell = (props) => (
                        <div className= "d-flex p-0 m-0 space-bw">
                            <Link to= {{ pathname: "/admin/players/edit-player", id: props.original["id"] }}>
                                <img src={images.editIcon} alt="" style={{height:"18px"}}/> 
                            </Link>
                            <Link to="#">
                                <img 
                                    src={images.deleteIcon}
                                    key={props.original["id"]}
                                    style={{height:"18px"}}
                                    onClick = {() => {
                                            setShowModal(true);
                                            setRowId(props.original["id"])
                                        }
                                    }
                                />
                            </Link>
                        </div>    
                    );
                }
                val.Filter = () => (
                    <input
                        className="form-control form-control-sm"
                        placeholder={`Search`}
                        defaultValue={filters[val.accessor]}
                        name={`${val.accessor}`}
                        onChange={onFilterChange}
                    />
                );
            });
    
            setColumn([...defaultColumn]);
        }

        const onSorterChange = (sortBy, e) => {
            setSortBy({...sortBy});
        }

        const onFilterChange = (e) => {
            e.persist()
            const onFilterChangeDebounce = _.debounce((e)=>{
                const {name, value} = e.target;
                if (name) {
                    setFilters({...filters, [name]: value.trim()});
                }
            }, 800);
            onFilterChangeDebounce(e);
        }

        function getPlayers(){
            setLoading(true);
            const params = {
                "page": page,
                "limit": limit,
                "filters": filters,
                "sort_by": sortBy
            };

            getPlayersList(params).then((response)=>{
                const { data } = response.data.success;
                if(!_.isEmpty(data)){
                    const { getPlayers = [],  totalResults = 0 } = data;
                    setPlayersList([...getPlayers]);
                    setTotalPlayers(totalResults);
                    setLoading(false);
                }
            }).catch((error)=>{
                if (error.response) {
                    const errorResult = error.response.data.error;
                    setLoading(false);
                    setPlayersList([]);
                } 
            });

        }

        prepareTablefields();
        getPlayers();

    }, [page, sortBy["name"], sortBy["order"], filters["country"], filters["country_code"], filters["player_name"]]);

    const onDeletePlayer = () => {
        document.body.style.overflow = 'unset';
        setLoading(true);
        setShowModal(false);
        deletePlayer(rowId).then((response)=>{
            const result = response.data.success;
            if(result){
              successToast(result.message);
            }
            setPage(1);
            setRowId(null);
            setFilters({...defaultFields});
          }).catch((error)=>{
            if (error.response) {
              const errorResult = error.response.data.error;
              errorToast(errorResult.message);
              setRowId(null);
            } 
        });
    }

    return (
        <>
            <div className="agenda_session_form_section agenda_padding">
                <div className="col-md-12 center_top_bar mobile-top-bar m-p-0">
                    <div className="col-md-12 agenda_session_tabbar">
                        <div className="row align-center">
                            <Link to={ {pathname: "/admin/players/create-player"}} className="btn blue_btn common_btn ml-auto m-ml-15 create_post" role="button">
                                <span className="add"> + </span>
                                <span className="m-sm-none"> Create Player </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="mobile-scrollbar-height">
                    <div style={{height:'74vh', overflow:"auto"}} className="newfeed_section scrollbar-macos text-left col-md-12">
                        <div className="col-12 agenda_table mb-4 m-table-responsive">
                            <ReactTable
                                className="table table-bordered m-0 admin_table"
                                loading={loading}
                                // loadingText={<div id="data_list_processing" className="dataTables_processing card">Processing...</div>}
                                // noDataText={<h4>No records available !</h4>}
                                pageSize={playersList.length}
                                data={playersList}
                                showPageSizeOptions= {false}
                                filterable= {true}
                                sortable={false}
                                columns= {column}
                                showPagination={false}
                                manual
                            />
                        </div>
                    </div>     
                    <div className="col-md-12 m-mb-25" style={{marginTop:"20px"}}>
                        <div className="row mb-4 admin-bottom-part m-m-0">
                            <div className="ml-auto col-md-5 d-flex col-6 m-m-0 m-p-0">
                                { (totalPlayers > 0) && <label className="">Showing Results {1 + (page * limit - limit)} to {playersList.length + (page * limit - limit)} of {totalPlayers}</label>}
                            </div>
                            <div className="ml-auto col-md-7 align-center d-flex pagination_section col-6 m-m-0 m-p-0">
                                <nav aria-label="Page navigation example" className="pl-4 m-p-0">
                                    <Pagination
                                        activePage={page}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={totalPlayers || 0}
                                        pageRangeDisplayed={5}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination m-0 my_pagination"
                                        onChange={(customPage)=>{setPage(customPage)}}
                                        nextPageText={<img src={images.arrow} className="right-arrow" />}
                                        prevPageText={<img src={images.arrow} className="left-arrow" />}
                                        hideFirstLastPages= {true}
                                    />
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal 
                modalIsOpen = { showModal }
                closeModal = { () => { setShowModal(false); setRowId("") } }
                onAction = {onDeletePlayer}
                modalFor = { "DEL_PLAYER" } 
            />	
            <Loader isLoading={loading}/>
        </>
    )
}

export default PlayersList;