import React, { Component } from 'react';
import images from '../components/ImagesUrl/imagesUri';
import { getTournamentList, updateStatus, softDeleteTournament, hardDeleteTournament, getTournamentDetails, restoreTournament } from '../Actions/post.js';
import { agoTime } from '../Helpers/TimeConversion';
import { errorToast, successToast } from '../components/Toast/Toast.js';
import { Link } from "react-router-dom";
import TextFeed from 'components/Newsfeed/TextFeed';
import ImageFeed from 'components/Newsfeed/ImageFeed';
import TextImageFeed from 'components/Newsfeed/TextImageFeed';
import Loader from '../components/Loader/Loader.jsx';
import InfiniteScroll from "react-infinite-scroll-component";
import TournamentRightSidebar from './TournamentRightSidebar';
import DeleteModal from 'components/Modal/DeleteModal';
import Template from "../Helpers/Raw-Data.js";
import Styles from "../Helpers/Select-Styles.js";
import Select from 'react-select';
import * as _ from "lodash";

const scrollableNodeRef = React.createRef();

class Tournaments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 500,
      isLoading:false,
      simplebarHeight:'',
      feeds : [],
      page: 1,
      limit: 20,
      selectedFeed: null,
      hoverTournamentId: null,
      status: 1,
      search: '',
      selectedFeedData: null,
      feedCreator: null,
      // hasMore: true,
      total_feeds: 0,
      updatedTournament:'',
      modalIsOpen:false,
      modal_for:'DEL_TOURNAMENT',
      deleted_id:[],
      match_status:''
    }
  }

  _searchFeeds(e){
    const {value} = e.target;
    this.setState({ search: value }, () => {
      this._feedsAPI(1);
    });
  }

  componentDidMount() {
    if(this.props.history.action == "PUSH"){
      console.log("Edit from ", this.props,"selectedFeed",this.state.selectedFeed);
      this.setState({updatedTournament:this.props.history.location.state})
    }
    
    const height = this.divElement.clientHeight;
    let deviceHeight = window.innerHeight;

    const simplebarHeight = deviceHeight-height-80;
    this.setState({ height ,simplebarHeight}, () => {
      this._feedsAPI(1);
    });

  }

  _selectedTournament  = (tournamentId) => {
    setTimeout(() => {
      this.props.history.push("/admin/match/match-list", {id:tournamentId});
    },1000);
  }

  _selectedFeed  = feed => {
    if(feed) {
      this.setState({
        selectedFeed: feed.id,
      });
      // const { status } = this.state;
      // this.setState({isLoading:true})
      // getTournamentDetails({status},feed.id).then((response)=>{
      //   const successResult = response.data.success;
      //   this.setState({
      //     selectedFeed: feed.id,
      //     selectedFeedData: successResult.data,
      //     feedCreator: feed,
      //     isLoading:false,
      //   });
      // }).catch((error)=>{
      //   if (error.response) {
      //     const errorResult = error.response.data.error;
      //     errorToast(errorResult.message);
      //   } 
      // });
    } else {
      this.setState({
        selectedFeed: null,
        selectedFeedData: null,
        feedCreator: null,
        isLoading:false,
      });
    }
  }

  _hoverTournament = (tournament) => {
    this.setState({ hoverTournamentId: tournament.id });
  }

  _changeHeader  = status => {
    this.setState({
      status
    }, () => {
      this._feedsAPI(1);
    });
  }
  
  onChangeStatus = (newValue, actionMeta) => {
    this.setState({ match_status: (newValue)? newValue.value : ""}, () =>{
      this._changeHeader(newValue.value)
    });
  };

  _statusChange = (key, data) => {
    let {feeds, selectedFeed, status} = this.state;   
    const activeType = (status === 2) ? true : false;
    updateStatus(data.id, {
      status: (status == 1 )? 2 : 1,
      
    }).then((response)=>{
      const successResult = response.data.success;
      if(status != 3){
        feeds.splice(key,1);
        if(feeds.length > 0){
          const newKey = (key > 0) ? key - 1 : 0 ;
          selectedFeed = (feeds[key])? feeds[key] : (feeds[newKey])? feeds[newKey] : null ;
          selectedFeed.feed_key = newKey;
        } else {
          selectedFeed = null;
        }  
        successToast(successResult.message);
        this.setState({ feeds },()=>{
          this._selectedFeed(selectedFeed);
        });
      } else {
        successToast(successResult.message);
        feeds[key].is_active = !data.is_active;
        this.setState({feeds},()=>{
          this._selectedFeed(feeds[key]);
        });
      }  
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  }

  openModal(modalType , e){
    e.preventDefault();
    document.body.style.overflow = 'hidden';
    let { deleted_id } = this.state;
    if(modalType === "delete"){
      deleted_id = (e && e.target.id)? [e.target.id] :'';
      this.setState({ modalIsOpen: true ,deleted_id,modal_for:'DEL_TOURNAMENT'});
    }else if(modalType == "hard-deleted") {
      this.setState({ modalIsOpen: true, modal_for: "HARD_DEL_TOURNAMENT", deleted_id: [e.target.id] });
    }else if(modalType === "restore"){
      this.setState({ modalIsOpen: true, modal_for: "RESTORE_TOURNAMENT", deleted_id: [e.target.id] });
      e.preventDefault();
    }
  }

  closeModal = () =>{
    document.body.style.overflow = 'unset';
    this.setState({ modalIsOpen:false});
  }

  deleteTournament = () => {
    this.setState({modalIsOpen:false});
    document.body.style.overflow = 'unset';
    let {feeds, selectedFeed, deleted_id} = this.state;   
    softDeleteTournament(deleted_id).then((response)=>{
      const successResult = response.data.success;
      if(successResult){
        successToast(successResult.message);
        this.setState({deleted_id:""});
        setTimeout(() => {
            this._feedsAPI(1);
        },1000);
      } else {
        const errorResult = response.data.error;
        errorToast(errorResult.message);
      }
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  }

  onHardDeleteTournament = () => {
    this.setState({modalIsOpen:false});
    document.body.style.overflow = 'unset';
    let {feeds, selectedFeed, deleted_id} = this.state;   
    hardDeleteTournament(deleted_id).then((response)=>{
      const successResult = response.data.success;
      if(successResult){
        successToast(successResult.message);
        this.setState({deleted_id:""});
        setTimeout(() => {
            this._feedsAPI(1);
        },1000);
      } else {
        const errorResult = response.data.error;
        errorToast(errorResult.message);
      }
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  }

  onRestoreTournament = () => {
    this.setState({modalIsOpen:false});
    document.body.style.overflow = 'unset';
    let {feeds, selectedFeed, deleted_id} = this.state;   
    // console.log("onRestoreTournament",deleted_id);
    restoreTournament(deleted_id).then((response)=>{
      const successResult = response.data.success;
      if(successResult){
        successToast(successResult.message);
        this.setState({deleted_id:""});
        setTimeout(() => {
            this._feedsAPI(1);
        },1000);
      } else {
        const errorResult = response.data.error;
        errorToast(errorResult.message);
      }
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  }

  // deletePostComment = (key, data) => {
  //   let { selectedFeedData } = this.state;   
  //   deleteComment(data.comment_id).then((response)=>{
  //     const successResult = response.data.success;
  //     selectedFeedData.comment_data.splice(key,1);
  //     this.setState({ selectedFeedData });
  //     successToast(successResult.message);
  //   }).catch((error)=>{
  //     if (error.response) {
  //       const errorResult = error.response.data.error;
  //       errorToast(errorResult.message);
  //     } 
  //   });
  // }
  
  _feedsAPI = (page = 1) => {
    let {limit, status, search, selectedFeed, feeds } = this.state;
    const acitveType = status ;
    if(search === undefined || search == null || search == ""){
      this.setState({isLoading:true})
    }
    this.setState({page: page},()=>{
      getTournamentList({
        page,
        limit,
        search,
        "sort_by":{"name":"created_at", "order":"DESC"},
	      "status": acitveType
        // is_active: acitveType,
        // "page": page,
        // "limit": 10,
        // "search":"",
       
        
      }).then((response)=>{
        const successResult = response.data.success;
        const feed = successResult.data.tournamentsList;
        // console.log("Tournament list API Data",successResult);
        
        if (page === 1) {
          if(feed.length > 0){
            selectedFeed = feed[0];
            selectedFeed.feed_key = 0;
          } else {
            selectedFeed = null;
          }
          feeds = [];
        }
        // setTimeout(() => {
          this.setState({
            search, 
            feeds: [...feeds, ...feed],
            total_feeds: successResult.data.total_tournaments,
            scrolling: false,
            isLoading:false,
            // hasMore: (feed.length == limit)? true: false,
            page: page + 1 
          }, () => {
            if(page == 1){this._selectedFeed(selectedFeed)};
          });
        // }, 1000);
      }).catch((error)=>{
        if (error.response) {
          const errorResult = error.response.data.error;
          errorToast(errorResult.message);
          this.setState({isLoading:false});
        } 
      });
    });
  }
  
  _render_feeds = feeds => {
    const {hoverTournamentId, search,updatedTournament, selectedFeed,modal_for} = this.state;
    
    if(feeds.length > 0) {
      return feeds.map((feed, key) => {
        const feedTitle = feed.title?feed.title : "";
        const feedImages = feed.image?feed.image : "";
        // const avtar = (feed.profile_pic)?  <img src={feed.profile_pic} className="user-sm-img" alt="" /> : <span className="avtar_text">{`${feed.first_name.charAt(0)}${ feed.last_name.charAt(0)}`}</span>;
    
        return(
          <div key={key} className="list active relative tournament">
            <div className=" align-center d-flex">
              <div className=" new_left_part d-flex align-center">
                <div>
                  <h5 className="m-0">{feed.title.substr(0,20)}</h5>
                </div>
              </div>
              
              <div className="new_right_part text-right ml-auto d-flex align-center">
                {feed.is_deleted === true ? 
                <div>
                  <Link to="#" data-toggle="tooltip" title="Restore" data-placement="auto">
                    <img src={images.refreshIcon} alt="restore" width="25px" id={feed.id} onClick = { this.openModal.bind(this,"restore") }/> 
                  </Link>
                </div>:
                <>
                <label className="switch small">
                  <input type="checkbox" onChange={() => this._statusChange(key, feed)} checked={(feed.status === 1)? true : false}/>
                  <span className="slider round small"></span>
                </label>
                <div>
                  <Link to= {{pathname: "edit-tournament", state: feed }}>
                      <img src={images.editIcon} alt=""/> 
                  </Link>
                </div>
                </>}
                <div className="cross_btn">
                  <Link to="#">
                    <img 
                      alt="img"
                      src={require("../Assets/images/close2.svg")}
                      className="close-icon"
                      id={feed.id}
                      onClick={(feed.is_deleted === true)?this.openModal.bind(this,"hard-deleted"):this.openModal.bind(this,"delete")}
                      // onClick={() => this.deleteTournament(key, feed)}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div  
              onClick={ () => this._selectedTournament(feed.id)}
              style={{ cursor:"pointer" }}
            >
              {/* {feedTitle.length > 0 && feedImages.length === 0 ? <TextFeed feed={feed}/>:null}
              {feedImages.length > 0 && feedTitle.length === 0 ?  <ImageFeed feed={feed}/>:null}
              {feedTitle.length > 0 && feedImages !== "" ? <TextImageFeed feed ={feed}/>:null} */}
              
              {/* {(feedTitle.length < 30)? this.lessTitleData(feed) : this.moreTitleData(feed) } */}
              {feedImages.length === 1 ?  this.OneImg(feed):this.lessTitleData(feed)}
            </div>
          </div>
        )
      });
    } else {
      return <p>{(search.length > 0)?"No Search found !":"No Tournament Available !"}</p>;
    }
  }

  lessTitleData (feed){
    const feedImages = feed.image;
    return(
      
      <>
        <div className="new_second_part pt-3 new_second_part_one">
        {feed.title ?<p className="m-0">{feed.title}</p>:''}
        </div>
        {feedImages.length > 0 ?
        <div className="col-md-12 p-0 text-left mt-3 news_third_part_one">
            <img src={feedImages[0]} className="img-fluid border-10" alt=""/>
        </div>:null}
      </>
    )
  }
  moreTitleData (feed){
    const feedImages = feed.image;
    return(
      <>
        <div className="new_second_part pt-3  new_second_part_two">
        {feed.title ?<p className="m-0">{feed.title.substr(0,100)}.....</p>:''}
        </div>
        {feedImages.length > 0 ?
        <div className="col-md-12 p-0 text-left mt-3 news_third_part_two">
            <img src={feedImages[0]} className="img-fluid border-10" alt=""/>
        </div>:null}
      </>
    )
  }

  // _renderTitle(feedTitle, imagesLength){
  //   if(feedTitle === '') {
  //     return null;
  //   } else if(feedTitle.length <= 30) {

  //   } else if(imagesLength > 0) {
      
  //   } else {

  //   }
  // }

  // _renderImages = imageArray => {
  //   if(imageArray.length === 0) {
  //     return null;
  //   } else if(imageArray.length === 3) {
  //     return null;
  //   } else if(imageArray.length === 2) {
  //     return null;
  //   } else {
      
  //   }
  // }

  OneImg(feed){
    const feedImages = feed.image;
    return(
      <>
        {feedImages.length ==1 ?
        <div className="col-md-12 p-0 mt-3 news_third_part_four">
            <img src={feedImages[0]} className="width_height_100 border-10" alt=""/>
        </div>:null}
      </>
    )
    
  }
  // moreThanOneImg(feed){
  //   const feedImages = feed.image;
  //   return(
  //     <>
  //       {feedImages.length > 2 ?
  //       <div className="col-md-12 p-0 text-left pt-3 news_third_part">
  //           <img src={feedImages[0]} className="img-fluid border-10" alt=""/>
  //           <img src={feedImages[1]} className="img-fluid border-10" alt=""/>
  //           <img src={feedImages[2]} className="img-fluid border-10" alt=""/>
  //       </div>:<div className="col-md-12 p-0 text-left pt-3 news_third_part">
  //           <img src={feedImages[0]} className="img-fluid border-10" alt=""/>
  //           <img src={feedImages[1]} className="img-fluid border-10" alt=""/>
  //       </div>}
  //     </>
  //   )
  // }
  
  render(){  
    const {simplebarHeight, feeds, status, search, selectedFeedData, feedCreator, isLoading, page, total_feeds,modalIsOpen,modal_for,match_status} = this.state; 
    let tournamentAlign = "";
    if(feeds.length < 3) {
      tournamentAlign="left"
    }
    return ( 
        <>
        <div className="center_section_div p-30 pb-0">
        <div className="col-md-12 center_top_bar p-0 mobile-top-bar" ref={ (divElement) => { this.divElement = divElement } }>
          <div className="row m-m-0">
            <div className="col-md-6 align-self-end m-sm-none ipad-d-none">
                <ul className="list-unstyled d-flex">
                  <li className={(status === 1 ? "active" : "")} onClick={()=> this._changeHeader(1)}>
                    <label style = {{cursor:"pointer"}}> Published </label>
                  </li>
                  <li className={(status === 2 ? "active" : "")}  onClick={()=> this._changeHeader(2)}>
                    <label style = {{cursor:"pointer"}}> Unpbulished </label>
                  </li>
                  <li className={(status === 3 ? "active" : "")} onClick={()=> this._changeHeader(3)}>
                    <label style = {{cursor:"pointer"}}> Archive </label>
                  </li>
                </ul>
            </div>
            {/* <div className="col-md-6 col-7 assign_in_tags common_dropdown form-group form-custom m-0 m-pl-0 m-sm-none xl-none"> */}
            <div className="col-md-6 col-7 assign_in_tags common_dropdown form-group form-custom m-0 m-pl-0 mb-2 xl-none">
                <Select 
                    isClearable = {false}
                    isSearchable={false}
                    onChange = { this.onChangeStatus }
                    options = { Template.match_status }
                    defaultValue={Template.match_status[0]}
                    value = { _.find(Template.match_status, (ele) => { return ele.id == match_status }) }
                    name = "tournament_id"
                    styles = { Styles.colourStyles }
                    menuPlacement= "auto"
                    components={{ DropdownIndicator:() => null }}
                  />
            </div>
            <div className="col-md-6 col-12 align-self-end d-flex mb-2 m-p-0">
                <div className="search col d-flex "> 
                    <img alt="img" src={images.search}/>
                    <input className="form-control" type="text" onChange={this._searchFeeds.bind(this)} defaultValue={search} placeholder="Search Tournament" aria-label="Search"/>
                </div>
                <Link to="create-tournament" className="btn blue_btn common_btn create_post  ml-4 m-ml-15" role="button" >+ <span className="create m-sm-none ipad-d-none"> Tournament </span></Link>
                
            </div>
          </div>
           
        </div>
        <div className="newfeed_section row m-0 scrollbar-macosx" style={{textAlign:tournamentAlign}}>
        <InfiniteScroll
            dataLength={feeds.length}
            next={this._feedsAPI.bind(this, page)}
            hasMore={(feeds.length < total_feeds)? true : false}
            height={simplebarHeight-10}
            style={{overflowX:"hidden", overflowY:"auto"}}
            className="pt-3"
        >
            {this._render_feeds(feeds)}
        </InfiniteScroll>
        </div>
      </div>
      {/* <TournamentRightSidebar
        feed={selectedFeedData}
        feedCreator = {feedCreator}
        deleteTournament={this.deleteTournament}
        _statusChange={this._statusChange}
      /> */}
        <DeleteModal 
          modalIsOpen = { modalIsOpen }
          closeModal = { this.closeModal }
          onAction = { (modal_for === "DEL_TOURNAMENT") ? this.deleteTournament : (modal_for === "HARD_DEL_TOURNAMENT") ? this.onHardDeleteTournament : this.onRestoreTournament }
          modalFor = { modal_for }
        />
      <Loader isLoading={isLoading}/>
      </>
    );
  }
}

export default Tournaments;