import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import { getToken } from "../Helpers/Token";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }

  async checkLogin () {
    const token = getToken();
    if(token === null || token === '' || token === undefined){
      this.props.history.push("/");
    }
  }

  getRoutes = routes => {
    this.checkLogin();
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => (
              <prop.component
                {...props}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  
  sidebarRoutes = routes =>{
    const route = [];
    routes.map((obj) => {
      if ((obj.layout === "/admin" && obj.show == true)) {
        route.push(obj);
      } 
      return true;
    });
    return route;
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
            <Sidebar {...this.props} routes={this.sidebarRoutes(routes)} />
            <Switch>{this.getRoutes(routes)}</Switch>
        </div>
      </div>
    );
  }
}

export default Admin;
