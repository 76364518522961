import Request from '../Helpers/API';

export const adminProfileDetails = (id) =>{
    const api_url = `admin/admin-profile-detail/${id}`;
    
    const req = new Request(api_url);
    
    return req.get();
};
export const createAdmin = (params) => {
  
    const api_url = `admin/create-admin`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};

export const changePassword = (params) =>{
    const api_url = `admin/changed-password`;
    
    const req = new Request(api_url,params);
    
    return req.patch();
}

export const updateAdminProfile = (params,id) =>{
    
    const api_url = `admin/update-admin/${id}`;
    let formdata = new FormData();
    Object.keys(params).forEach((e) => {formdata.append(e,params[e] || ''); });
    const req = new Request(api_url,formdata);
    
    return req.put();
    
}

export const getAllAdmin = (params) => {
  
    const api_url = `admin/admin-list`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};

export const deleteAdmin = (params) =>{
    const api_url = `admin/delete-admin`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const forgotPassword = (params) =>{
    const api_url = `admin/forget-password`;
    
    const req = new Request(api_url,params);
    
    return req.patch();
}

export const resetPassword = (params) =>{
    const api_url = `admin/reset-password`;
    
    const req = new Request(api_url,params);
    
    return req.patch();
}

export const checkAdmin = (emailId) =>{
    const api_url = `admin/check-admin/${emailId}`;
    
    const req = new Request(api_url);
    
    return req.get();
};

export const getAllCountries = () =>{
    const api_url = `admin/get-countries`;
    
    const req = new Request(api_url);
    
    return req.get();
};

export const reactivateAdmin = (params) =>{
    const api_url = `admin/reactivated-admin`;
    
    const req = new Request(api_url, params);
    
    return req.patch();
}

export const hardDeleteAdmin = (id) =>{
    const api_url = `admin/hard-delete-admin/${id}`;
    
    const req = new Request(api_url);
    
    return req.delete();
}