import Request from '../Helpers/API';

export const createFeed = (params) => {
  
    const api_url = `admin/create-info`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};

export const getFeedList = (params) => {
  
    const api_url = `admin/info-list`;
    
    const req = new Request(api_url,params);
    
    return req.post();
};

export const updateFeed = (params, feedId) =>{
    const api_url = `admin/update-info/${feedId}`;
    
    const req = new Request(api_url, params);
    
    return req.put();
}