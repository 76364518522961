// import * as moment from 'moment-timezone';
import { TIME_ZONE_STR } from './Config';
import * as moment from 'moment';

/**
 * "Input:"YYYY-MM-DD HH:mm:ss",
 *  Output:"1582857000000",
 *  return typ
 */
export function getTime(date = new Date()) {
    const response = moment(date).utc().format('x');
    return response;
}

/**
 *"Input-date: 2020-02-28", Input-time: "18:00",
 * Output:"1582857000000",
 * return typ
 */
export function concatDateTime(date, time = "00:00") {
    const response = moment(date + ' ' + time, 'YYYY-MM-DD HH:mm').utc().format('x');
    return response;
}

export function getUnixTime(time = "00:00") {
    const response = moment("1980-01-01" + ' ' + time, 'YYYY-MM-DD HH:mm').utc().format('x');
    return response;
}

/**
 * Input:"1582857000000",
 * Output: "2020-02-20",
 * return typ
 */
export function extractDate(date) {
    const response = moment.unix(date / 1000).utc().format('YYYY-MM-DD');
    return response;
}

/**
 *Input:"1582857000000",
 * Output: "18:00",
 * return typ
 * fmt: HH:mm or hh:mm A
 */
export function extractTime(date, fmt) {
    const response = moment.unix(date / 1000).format(fmt);
    return response;
}

/**
 *Input-date:"1582857000000",Input-day integer number"
 *Output: integer number"
 *return typ
 */
export function getDays(startDate, day) {
    // const response = moment(moment(extractDate(startDate)).add(day, 'day').utc().format('LL')).utc().format("YYYY-MM-DD");
    const response = moment(extractDate(startDate)).add(day, 'day').utc().format('LL');

    return response;
}

/**
 * Input:"1582857000000",
 * Output: "March 1, 2020",
 * return typ
 */
export function getDateDifference(startDate, endDate) {
    startDate = moment(extractDate(startDate));
    endDate = moment(extractDate(endDate));
    const response = moment.duration(endDate.diff(startDate)).asDays();
    return response;
}

export function concatUnixTime(startDate, date) {
    const response = moment(moment(date).utc().format("YYYY-MM-DD") + ' ' + extractTime(startDate), 'YYYY-MM-DD HH:mm').utc().format('x');
    return response;
}

export function convertDateFormat(date, fmt) {
    const response = moment(date).utc().format(fmt);
    return response;
}

export function agoTime(date){
    const response = moment.unix(date / 1000).fromNow();
    return response;
}

export function extractPostTime(date, fmt) {
    const response = moment.unix(date / 1000).format(fmt);
    return response;
}
