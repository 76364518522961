import React, { useEffect, useState } from 'react';
import * as _ from "lodash";
import ReactTable from 'react-table';
import "react-table/react-table.css";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Loader from '../components/Loader/Loader.jsx';
import { errorToast, successToast } from '../components/Toast/Toast.js';
import { getCountryList, deleteCountry } from '../Actions/countries.js';
import images from "../components/ImagesUrl/imagesUri";
import DeleteModal from '../components/Modal/DeleteModal.jsx';
import SortBy from '../components/SortBy';
import AddCountry from "./AddCountry";

const defaultFields = {
    "name": "",
    "country_code":""
};

const defaultSortBy = {
    "name":"name",
    "order":"ASC"
};

const defaultColumn =   [ 
    {accessor: "sno", key: "S.NO", Header: "S.NO", minWidth: 40, sortable: false, filterable: false},
    {accessor: "name", key: "Country", Header: 'Country', minWidth: 140},
    {accessor: "country_code", key: "Country Code", Header:'Country Code', minWidth: 80},
    {accessor: "action", key: "Action", Header: "Actions", minWidth: 40, sortable: false, filterable: false},
];

const CountriesList = (props) => {
    const limit = 50;
    const [loading, setLoading] = useState(false);   
    const [countryList, setCountryList] = useState([]);
    const [totalCountries, setTotalCountries] = useState([]);
    const [column, setColumn] = useState([...defaultColumn]);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({...defaultFields});
    const [sortBy, setSortBy] = useState({...defaultSortBy});
    const [showModal, setShowModal] = useState(false);
    const [showCountryModal, setCountryModal] = useState(false);
    const [rowId, setRowId] = useState(null);
    const [resetListing, setResetListing] = useState(false);

    useEffect(() => {
        prepareTablefields();
        getCountries();
    }, [page, sortBy["name"], sortBy["order"], filters["name"], filters["country_code"]]);

    useEffect(() => {
        if(resetListing){
            prepareTablefields();
            getCountries();
            setResetListing(false);
        }
    }, [resetListing]);

    function prepareTablefields(){
        defaultColumn.map((val, idx)=>{
            const header = val.key;
            val.label = (val.label)? val.label : val.Header;
            if(!val.hasOwnProperty("sortable") || val.sortable){
                val.Header = ()=>{
                    return(
                        <>
                            <SortBy 
                                row={val} 
                                sortBy={sortBy} 
                                header_name={val.label} 
                                onSorter={onSorterChange}
                            />
                        </>
                    )    
                }
            }    

            if(val.accessor == "action"){
                val.Cell = (props) => (
                    <div className= "d-flex p-0 m-0 justify-content-around">
                        <Link to="#">
                            <img 
                                src={images.editIcon}
                                key={props.original["id"]}
                                style={{height:"18px"}}
                                onClick = {
                                    () => {
                                        setCountryModal(true);
                                        setRowId(props.original["id"])
                                    }
                                }
                            />
                        </Link>
                        <Link to="#">
                            <img 
                                src={images.deleteIcon}
                                key={props.original["id"]}
                                style={{height:"18px"}}
                                onClick = {
                                    () => {
                                        setShowModal(true);
                                        setRowId(props.original["id"])
                                    }
                                }
                            />
                        </Link>
                    </div>    
                );
            }
            val.Filter = () => (
                <input
                    className="form-control form-control-sm"
                    placeholder={`Search`}
                    defaultValue={filters[val.accessor]}
                    name={`${val.accessor}`}
                    onChange={onFilterChange}
                />
            );
        });

        setColumn([...defaultColumn]);
    }

    const onSorterChange = (sortBy, e) => {
        setSortBy({...sortBy});
    }

    const onFilterChange = (e) => {
        e.persist()
        const onFilterChangeDebounce = _.debounce((e)=>{
            const {name, value} = e.target;
            if (name) {
                setFilters({...filters, [name]: value.trim()});
            }
        }, 800);
        onFilterChangeDebounce(e);
    }

    function getCountries(){
        setLoading(true);
        const params = {
            "page": page,
            "limit": limit,
            "filters": filters,
            "sort_by": sortBy
        };

        getCountryList(params).then((response)=>{
            const { data } = response.data.success;
            if(!_.isEmpty(data)){
                let { getCountries = [],  totalResults = 0 } = data;
                if(!_.isEmpty(getCountries)){
                    getCountries.forEach((row, idx)=>{
                        row["sno"] = (idx+1)+((page-1)*limit);
                    });
                }
                setCountryList([...getCountries]);
                setTotalCountries(totalResults);
            }
        }).catch((error)=>{
            if (error.response) {
                const errorResult = error.response.data.error;
                setCountryList([]);
            } 
        }).finally(()=>{
            setLoading(false);
        });
    }

    const onDeleteCountry = () => {
        document.body.style.overflow = 'unset';
        setLoading(true);
        setShowModal(false);
        deleteCountry(rowId).then((response)=>{
            const { message } = response.data.success;
            if(message){
              successToast(message);
            }
            setResetListing(true);
          }).catch((error)=>{
            if (error.response) {
              const errorResult = error.response.data.error;
              errorToast(errorResult.message);
            } 
        }).finally(()=>{
            setRowId(null);
            setLoading(false);
        });
    }

    return (
        <>
            <div className="agenda_session_form_section agenda_padding">
                <div className="col-md-12 center_top_bar mobile-top-bar m-p-0">
                    <div className="col-md-12 agenda_session_tabbar">
                        <div className="row align-center">
                            <div 
                                className="btn blue_btn common_btn ml-auto m-ml-15 create_post" 
                                onClick={ () => { setCountryModal(true)}}
                            >
                                <span className="add"> + </span>
                                <span className="m-sm-none"> Create Country </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-scrollbar-height">
                    <div style={{height:'74vh', overflow:"auto"}} className="newfeed_section scrollbar-macos text-left col-md-12">
                        <div className="col-12 agenda_table mb-4 m-table-responsive">
                            <ReactTable
                                className="table table-bordered m-0 admin_table"
                                loading={loading}
                                pageSize={countryList.length}
                                data={countryList}
                                showPageSizeOptions= {false}
                                filterable= {true}
                                sortable={false}
                                columns= {column}
                                showPagination={false}
                                manual
                            />
                        </div>
                    </div>     
                    <div className="col-md-12 m-mb-25" style={{marginTop:"20px"}}>
                        <div className="row mb-4 admin-bottom-part m-m-0">
                            <div className="ml-auto col-md-5 d-flex col-6 m-m-0 m-p-0">
                                { (totalCountries > 0) && <label className="">Showing Results {1 + (page * limit - limit)} to {countryList.length + (page * limit - limit)} of {totalCountries}</label>}
                            </div>
                            <div className="ml-auto col-md-7 align-center d-flex pagination_section col-6 m-m-0 m-p-0">
                                <nav aria-label="Page navigation example" className="pl-4 m-p-0">
                                    <Pagination
                                        activePage={page}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={totalCountries || 0}
                                        pageRangeDisplayed={5}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination m-0 my_pagination"
                                        onChange={(customPage)=>{setPage(customPage)}}
                                        nextPageText={<img src={images.arrow} className="right-arrow" />}
                                        prevPageText={<img src={images.arrow} className="left-arrow" />}
                                        hideFirstLastPages= {true}
                                    />
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal 
                modalIsOpen = { showModal }
                closeModal = { () => { setShowModal(false); setRowId("") } }
                onAction = {onDeleteCountry}
                modalFor = { "DEL_COUNTRY" } 
            />	
            <AddCountry
                showModal = {showCountryModal}
                countryId = {rowId}
                closeModal = { () => { setCountryModal(false); setRowId("") } }
                onResetListing = { 
                    () => {
                        setResetListing(true);
                    }
                }
            />
            <Loader isLoading={loading}/>
        </>
    )
}

export default CountriesList;