const Template = {
    winning_chances: [
        { id: 1, value: "#FF0000", label: "Tight" },
        { id: 2, value: "#FDFD96", label: "High" },
        { id: 3, value: "#4AA546", label: "Very High" },
    ],

    display_on: [
        { id: 1, value: "singles", label: "Singles" },
        { id: 2, value: "lottery", label: "Lottery" },
        { id: 3, value: "free", label: "Free" },
        //     { id: 4, value: "lottery", label: "Singles/Free" },
        //     { id: 5, value: "lottery", label: "Lottery/Free" },
        { id: 4, value: "singles_free", label: "Singles/Free" },
        { id: 5, value: "lottery_free", label: "Lottery/Free" },
        // { id:6, value: "all", label: "All"},
    ],

    match_status: [
        { id: 1, value: 1, label: "Published" },
        { id: 2, value: 2, label: "Unpbulished" },
        { id: 3, value: 3, label: "Archive" },
    ],
    push_notification: [
        { id: 1, value: 1, label: "General" },
        { id: 2, value: 2, label: "Match" },
        // { id:3, value: 3, label: "Multiple Matches"},
    ],
}

export default Template;