import crypto from 'crypto';
import { CRYPTO_ALGO, CRYPTO_SECRET } from "./Config";


const debug = require('debug')('sameBrain:Encrypt');

class Crypter {
    static encrypt(token) {
        const cipher = crypto.createCipher(CRYPTO_ALGO, CRYPTO_SECRET);
        let crypted = cipher.update(token, 'utf8', 'hex');
        crypted += cipher.final('hex');
        return crypted;
    }

    static decrypt(token) {
        var decipher = crypto.createDecipher(CRYPTO_ALGO, CRYPTO_SECRET);
        let decrypted = decipher.update(token, 'hex', 'utf-8');
        decrypted += decipher.final('utf-8');
        return decrypted;
    }
}

export default Crypter;
