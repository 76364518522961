import React from "react";
import { Link } from "react-router-dom";
import InputError from "../components/Error/InputError";
import Rules from "../Helpers/FormRules";
import FormValidator from "../Helpers/FormValidator";
import { setToken, getToken, getRememberME, setRememberME, removeRememberME, setUser } from "../Helpers/Token";
import { errorToast, successToast } from '../components/Toast/Toast.js';
import { userLogin } from '../Actions/user.js';
import images from "../components/ImagesUrl/imagesUri";
import Loader from '../components/Loader/Loader.jsx';


class Login extends React.Component{
  constructor(props){
    super(props);
    this.validator = new FormValidator(Rules.login);
    this.state = {
      email : '',
      password: '',
      isLoading:false,
      remember:'',
      showPasword:false,
      validation: this.validator.valid(),
    }
    this.formsubmitted = false;
  }


  onSubmit(e){
    e.preventDefault();
    const {email , password, remember} = this.state;
    const validation = this.validator.validate(this.state);
    this.setState({ validation, isLoading: true });
    this.submitted = true;

    if (validation.isValid) {
      if(remember){
        setRememberME(JSON.stringify({
          email,
          password,
        }));
      } else {
        removeRememberME();
      }
      userLogin({
        email: email, 
        password: password,
      }).then(response => {
        if(response.data.success){
          let loginData = response.data.success;
          loginData = loginData.data;
          this.setState({
            loginsuccess:true,
            err:false,
            message:'Login successful!'
          });
          setUser(loginData);
          setToken(loginData.token);

          this.setState({
            message:'Logging you in....'
          });

          successToast('Logging you in....');

          setTimeout(() => {
            this.props.history.push("/admin/match/match-list");
          },1000);
        }else{
          this.setState({ isLoading:false });

          let message = response.data.error.message[0];
          this.setState({err:true,message});
        }
      }).catch(error => {
        if (error.response) {
          const errorResult = error.response.data.error;
          this.setState({err:true,message:'Server Error!', isLoading:false});
          errorToast(errorResult.message);
        } 
      });
    } else {
      this.setState({ isLoading:false });
    }
  }

  componentDidMount(){
    if(getToken() !== null && getToken() !== '' && getToken() !== undefined){
      this.props.history.push("/admin/match/match-list");
    }
    let remember_me = getRememberME() || null;
    if(remember_me != null){
      remember_me = JSON.parse(remember_me);
      this.setState({
        email:remember_me.email.trim(),
        password:remember_me.password.trim(),
        remember:true
      });
    }
  }

  onChange(e){
    const {name, value} = e.target;
    if(name ==='remember'){
      let {remember} = this.state;
      remember = !remember;
      this.setState({remember})
    } else {
      this.setState({[name]: value.trim()});
    }
  }
  
  togglePassword(e){
    e.preventDefault();
    let {showPasword} = this.state;
    showPasword = !showPasword;
    this.setState({showPasword})
  }

  render() {
    let { isLoading, showPasword, remember, email, password } = this.state;
    let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
    return (
      <div className="container login_page">
        <div className="wrap-login100">
          <form  method="POST" className="login100-form">
            <div className="text-center login-logo mb-4">
              <img src={images.logo} className="logo" alt="logo"/>
              {/* <p style={{fontSize:'24px'}}> Login </p> */}
            </div>
  
            <div className="form-group col-md-12 form-custom p-0 popup-custom-height">
              <input className="form-control" 
                id="time1"
                type="email"  
                placeholder="E-Mail"
                name="email" 
                onChange={this.onChange.bind(this)} 
                value={email} 
                required/>
              <InputError show={validation.email.isInvalid} message={validation.email.message} />
            </div>
            
            <div className="form-group col-md-12 form-custom p-0 popup-custom-height">
              <input className="form-control" 
                id="password" 
                placeholder="Password"
                type={showPasword?'text':'password'} name="password" 
                value={password} 
                onChange={this.onChange.bind(this)}  
                required/>
                <img className="input_eye" src={showPasword?images.eyeShow:images.eyeHide} alt="" onClick={this.togglePassword.bind(this)}></img>
              <InputError show={validation.password.isInvalid} message={validation.password.message} />
            </div>
            
            <div className="d-flex space-bw align-center">
              <div className="custom-control custom-checkbox">
                <input className="custom-control-input"
                  id="customCheck2"
                  type="checkbox" 
                  name="remember"
                  onChange={this.onChange.bind(this)}
                  checked={remember}/>
                <label className="custom-control-label" htmlFor="customCheck2">Remember me</label>
              </div>
              <div>
              <Link to="/auth/forgot-password" className="txt1">
                  Forgot Password?
              </Link>
              </div>
            </div>
  
            <div className="col-md-12 p-0 mt-30">
              <button type="submit" 
                onClick={this.onSubmit.bind(this)} 
                disabled = {isLoading} 
                className="btn common_btn blue_btn big_btn">
                  Login {isLoading && <span className='fa fa-sync fa-spin ml-10'>  </span>}
              </button>
            </div>
            
          </form>
        </div>
        <Loader isLoading={isLoading}/>
    </div>
    );
  }    
}

export default Login;