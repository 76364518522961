import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../routes";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => (
              <prop.component
                {...props}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  
  render() {
    return (
      <div className="main-content auth_content">
        <Switch>{this.getRoutes(routes)}</Switch>
      </div>
    );
  }
}

export default Auth;
