import React, { Component } from 'react';

class ImageFeed extends Component {
    constructor(props) {
        super(props)
    }
    selectImageComponent(feedData){
        let postTitle = feedData.title;
        let postImages = feedData.image;

        if(postTitle.length == 0 ) 
        {   
            if(postImages.length == 1)
            {
            return this.noTextsingleImage(feedData)
            }
        }
        if(postTitle.length < 30 )
        {
           if(postImages.length == 1 ){
               return this.singleImage(feedData)
            }
           if(postImages.length == 2 ){
                return this.doubleImage(feedData)
            }
            if(postImages.length == 3 ){
                return this.tripleImages(feedData)
            }
        } else if (postTitle.length < 100 )
        {
           return 
        } else {
            return 
        }
    }
    singleImage(feedData) {
        return (
            <div className="col-md-12 p-0 text-left pt-3 news_third_part_one">
                <img alt="img" src={feedData.post_images[0]} className="width_height_100 border-10"/>
            </div>)
    }
    doubleImage(feedData) {
        return (
            <div className="col-md-12">
            <div className="row pt-3 news_third_part news_third_part_five">
                <div className="col p-0 mr-7">
                    <img src={feedData.post_images[0]} className="border-10 width_height_100"/>
                </div>
                <div className="col p-0 ml-7">
                    <img src={feedData.post_images[1]} className="border-10 width_height_100"/>
                </div>
            </div>
        </div>)
    }
    tripleImages(feedData) {
        return (
            <div className="col-md-12">
            <div className="row pt-3 news_third_part news_third_part_three">
                <div className="col p-0 mr-7">
                    <img src={feedData.post_images[0]} className="border-10 width_height_100"/>
                </div>
                <div className="col ml-3-5 mr-3-5 p-0">
                    <img src={feedData.post_images[1]} className="border-10 width_height_100"/>
                </div>
                <div className="col p-0 ml-7">
                    <img src={feedData.post_images[2]} className="border-10 width_height_100"/>
                </div>
            </div>
        </div>)
    }
    noTextsingleImage(feedData){
        return (
            <div className="col-md-12 p-0 mt-3 news_third_part_four">
                <img alt="img" src={feedData.post_images[0]} className="width_height_100 border-10"/>
            </div>)
    }

    render() {
        // console.log("Image Feed",this.props);
        const { feed } = this.props;
        if(feed == undefined || feed ==null) {
            return 
        }
        return (
            <>
                {this.selectImageComponent(feed)}
                
            </>
        );
    }
}

export default ImageFeed;