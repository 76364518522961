import React, { Component } from 'react';
import ImageFeed from './ImageFeed';
import TextImageFeed from './TextImageFeed';

class TextFeed extends Component {
    constructor(props) {
        super(props)
    }

    selectText(matchData){
        if(matchData.title.length < 30 )
        {
           return this.singleLineText(matchData)
        } else if (matchData.title.length < 100 )
        {
           return this.threeLineText(matchData)
        } else {
            return this.fullText(matchData)
        }
    }
    
    singleLineText(matches) {
        return(
            <div className="new_second_part pt-3 new_second_part_one">
                <p className="m-0">{matches.title}</p>
            </div>
        )
        
    }
    
    threeLineText(matches) {
        return(
            <div className="new_second_part pt-3 new_second_part_two">
                <p className="m-0">{matches.title.substr(0,100)}</p>
            </div>
        )
    }

    fullText(matches) {
        let title = matches.title;
        let playerImages = matches.player_images;

        title = (title.length > 240 ) ? title.substr(0,240)+'...' : title;
        return(
            <>
               
                <div className="new_second_part pt-3 new_second_part_six">
                    <p className="m-0">{title}</p>
                </div>
    
            </>
        )
    }
   
    render() {
        const { matches } = this.props;
        if(matches == undefined || matches ==null) {
            return 
        }
        return (
            <>
                {this.selectText(matches)}
            </>
        );
    }
}

export default TextFeed;