import React, { Component } from 'react'
import { Link } from "react-router-dom";
import images from "../components/ImagesUrl/imagesUri";
import Pagination from "react-js-pagination";
import { errorToast, successToast } from '../components/Toast/Toast.js';
import * as _ from "lodash";
import DeleteModal from '../components/Modal/DeleteModal.jsx';
import Loader from '../components/Loader/Loader.jsx';
import { getUser } from 'Helpers/Token';
import { getFAQList, reactivateFaq, hardDeleteFaq, deleteFaq, changeFaqStatus, updatePosition } from '../Actions/faq.js';
require("bootstrap/less/bootstrap.less");

export default class FaqList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createFAQ: false,
      modalIsOpen:false,
      self_id:'',
      page: 1,
      limit: 10,
      question_list: [],
      total_faq: null,
      search:"",
      isLoading:false,
      pageRangeDisplayed: 5,
      order_by: "ASC",
      filter_by: "position",
      modalIsOpen:false,
      faq_ids: [],
      checkedItems: new Map(),
      bulk_ids:[],
      select_all: false,
      single_question: false,
      modal_for: "DEL_FAQ",
      deleted_faq_id: [],
    };
  };

  componentDidMount() {
    const { search, page, order_by, filter_by } = this.state;
    let userData = getUser();
    this.setState({ self_id: (userData) ? userData.id : "" }, () =>{
      this.showQuestionList( page, search, order_by, filter_by );
    });
  };
  
  onPageChange = (custompage)=> {
    const { search, order_by, filter_by , checkedItems } = this.state;
    checkedItems.clear();
    this.setState({ page: custompage, checkedItems, bulk_ids: [] },() =>{
      this.showQuestionList(custompage, search, order_by, filter_by );
    });
  }

  onSearch = (e) =>{
    const value = e.target.value.trim();
    const { order_by, filter_by, checkedItems} = this.state;
    checkedItems.clear();
    this.setState({search: value, checkedItems, bulk_ids: [], select_all: false }, () =>{
      this.showQuestionList(1, value, order_by, filter_by);
    });
  }

  onSorting(filterBy, orderBy, e){
    const {page, search, checkedItems} = this.state;
    checkedItems.clear();
    this.setState({ checkedItems, bulk_ids: [], select_all: false }, () => {
      if(orderBy === "ASC"){
        this.setState({ order_by: "DESC", filter_by: filterBy });
      } else {
        this.setState({ order_by: "ASC", filter_by: filterBy});
      }
      this.showQuestionList(page, search, orderBy, filterBy);
    });
  }

  showQuestionList(page = 1, search, orderBy, filterBy){
    let { limit, checkedItems, select_all, self_id, bulk_ids } = this.state;
    if(search === undefined || search == null || search == ""){
      this.setState({isLoading:true})
    }
    this.setState({page: page},()=>{
      getFAQList({
        search,
        page,
        limit,
        sort_by:{name: filterBy, order: orderBy},
      }).then((response)=>{
        const successResult = response.data.success;

        if(successResult.data.faqList.length > 0){
          successResult.data.faqList.map((obj) => {
            if (!obj.is_deleted && obj.is_active) {
              if(self_id != obj.id){
                checkedItems.set(obj.id, select_all);
              }
            } 
          });
        }  
        
        this.setState({
          checkedItems,
          search: search,
          question_list: successResult.data.faqList,
          total_faq: successResult.data.total_faq,
          isLoading: false,
          bulk_ids: (select_all)? bulk_ids : []
        });
      }).catch(()=>{
        this.setState({ isLoading: false, question_list: [] });
      });
    });
  }

  openModal(modalType,e){
    e.preventDefault();
    document.body.style.overflow = 'hidden';
    if(modalType == "deleted"){
      const { bulk_ids, select_all } = this.state;
      if(e.target.id || bulk_ids.length > 0 || select_all){
        let { faq_ids } = this.state;
        faq_ids = (e && e.target.id)? [e.target.id] : bulk_ids;
        this.setState({ modalIsOpen:true, faq_ids, single_question: (e && e.target.id)? true : false, modal_for:"DEL_FAQ" });
      } else {
        e.preventDefault();
      }  
    } else if(modalType == "reactivated"){
      this.setState({ modalIsOpen: true, modal_for: "REACTIVATE_FAQ", deleted_faq_id: [e.target.id] });
    } else if(modalType == "hard-deleted"){
      this.setState({ modalIsOpen: true, modal_for: "HARD_DEL_FAQ", deleted_faq_id: [e.target.id] });
    } 
  }

  onDeleteFaq = (permanentDelete) =>{
    document.body.style.overflow = 'unset';
    let { page, search, order_by, filter_by, faq_ids, select_all, single_question, checkedItems } = this.state;
    this.setState({ modalIsOpen:false });
    select_all = (single_question)? false : select_all;
    deleteFaq({ select_all, faq_ids, permanent_delete: permanentDelete }).then((response)=>{
      const result = response.data.success;
      if(result){
        successToast(result.message);
        checkedItems.clear();
        setTimeout(() => {
          this.setState({ single_question: false, select_all: false, faq_ids: [], checkedItems },() =>{
            this.showQuestionList(page, search, order_by, filter_by);
          });  
        },1000);
      } else {
        const errorResult = response.data.error;
        errorToast(errorResult.message);
      }
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  }

  onReactivateFaq = ( ) =>{
    document.body.style.overflow = 'unset';
    let { page, search, order_by, filter_by, deleted_faq_id } = this.state;
    this.setState({ modalIsOpen:false });
    reactivateFaq({ faq_ids:  deleted_faq_id }).then((response)=>{
      const result = response.data.success;
      if(result){
        successToast(result.message);
        setTimeout(() => {
          this.setState({ select_all:false, single_question: false }, () =>{
            this.showQuestionList(page, search, order_by, filter_by);
          });  
        },1000);
      } else {
        const errorResult = response.data.error;
        errorToast(errorResult.message);
      }
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  } 

  onHardDeleteFaq = ( ) =>{
    document.body.style.overflow = 'unset';
    let { page, search, order_by, filter_by, deleted_faq_id } = this.state;
    this.setState({ modalIsOpen:false });
    hardDeleteFaq(deleted_faq_id[0]).then((response)=>{
      const result = response.data.success;
      if(result){
        successToast(result.message);
        setTimeout(() => {
          this.setState({ select_all:false, single_question: false }, () =>{
            this.showQuestionList(page, search, order_by, filter_by);
          });   
        },1000);
      } else {
        const errorResult = response.data.error;
        errorToast(errorResult.message);
      }
    }).catch((error)=>{
      if (error.response) {
        const errorResult = error.response.data.error;
        errorToast(errorResult.message);
      } 
    });
  } 

  handleCheckBox = (e)=> {
    const item = e.target.name;
    let { checkedItems, bulk_ids } = this.state;
    if([...checkedItems].length > 0){
      const isChecked = e.target.checked;
      if( item == "allCheck" && isChecked ){
        [...checkedItems.keys()].map((key)=>{
          checkedItems.set(key, true);
        });
        this.setState({ checkedItems, bulk_ids:[...checkedItems.keys()], select_all: true, single_question: false });
      } else if( item == "allCheck" && !isChecked ){
        [...checkedItems.keys()].map((key)=>{
          checkedItems.set(key, false);
        });
        this.setState( { checkedItems, bulk_ids:[], select_all: false, single_question: false });
      } else {
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }),()=>{
          bulk_ids=[];
          [...checkedItems].map((obj) => {
            if(obj[1]){
              bulk_ids = [...bulk_ids, obj[0]];
            }    
          });
          this.setState({bulk_ids,select_all: false });
        });
      }
    } else {
      e.preventDefault();
    }  
  }
  
  closeModal = () =>{
    document.body.style.overflow = 'unset';
    const { createFAQ } = this.state;
    this.setState({modalIsOpen: false, createFAQ: false, single_question: false }, () =>{
      const {search, page, order_by, filter_by } = this.state;
      if(createFAQ){
        this.showQuestionList(page, search, order_by, filter_by);
      }  
    });
  }

  statusChange = (data) =>{
    this.setState({isLoading: true}, () => {
      const { page, search, order_by, filter_by } = this.state;
      changeFaqStatus({ is_active: !data.is_active }, data.id).then((response) =>{
        const result = response.data.success;
        if(result){
          successToast(result.message);
          setTimeout(() => {
            this.setState({ isLoading: false }, () =>{
              this.showQuestionList(page, search, order_by, filter_by);
            });   
          },1000);
        } else {
          const errorResult = response.data.error;
          errorToast(errorResult.message);
          this.setState({ isLoading: false });
        }
      }).catch(error => {
        if (error.response) {
          const errorResult = error.response.data.error;
          errorToast(errorResult.message);
          this.setState({ isLoading: false });
        } 
      });
    });
  }

  onPositionChange(index, e){
    const { id, value } = e.target;
    const { question_list } = this.state;
    question_list[index].position =  value;
    updatePosition({position: value}, id).then((response) => {
      this.setState({question_list});
    }).catch();
  }

  onPositionBlur(index, e){
    if(!e.target.value){
      const { question_list } = this.state;
      question_list[index].position =  0;
      this.setState({question_list});
    }
  }

  render() {
    const { bulk_ids, question_list, page, total_faq, isLoading, limit, search, pageRangeDisplayed, order_by,
      filter_by, modalIsOpen, self_id, select_all, modal_for
    } = this.state;
    let count = 0;
    return (
      <div className="agenda_session_form_section agenda_padding"  ref={ (divElement) => { this.divElement = divElement } }>
        <div className="col-md-12 center_top_bar m-p-0 mobile-top-bar">
          <div className="col-md-12 agenda_session_tabbar">
            <div className="row align-center">
               
                  <div className="col p-0 col-md-3">
                    <div className="search d-flex pr-0 form-custom m-0"> <img src={images.search}/>
                      <input className="form-control" 
                      type="text" 
                      placeholder="Search" 
                      aria-label="Search"
                      name="search" 
                      defaultValue={search}  
                      onChange={this.onSearch}
                      />
                    </div>
                  </div>
                  <Link to={ {pathname: "/admin/faq/create-faq"}} className="btn blue_btn common_btn m-ml-15 ml-auto create_post" role="button">
                    <span className="add"> + </span>
                    <span className="m-sm-none"> Create FAQ </span>
                  </Link>
            </div>
            <div className="row align-center mt-30 m-sm-none">
              <div className="col-md-6 pl-0">
                <h2 className="m-0 speakers_heading "> FAQ ({total_faq?total_faq:0})</h2>
              </div>
            </div>
          </div>
        </div>
      <div style={{height:'74vh', overflow:"auto"}} className="mobile-scrollbar-height">
      <div className="newfeed_section scrollbar-macos text-left col-md-12">
          <div className="col p-0 pt-3 mb-3 d-md-none">
              <h2 className="m-0 speakers_heading "> FAQ ({total_faq?total_faq:0})</h2>
          </div>
          <form className="col-md-12 p-0">
            <div className="row m-0">
              <div className="col-12 agenda_table mb-4 m-table-responsive">
                <table className="table table-bordered m-0 text-center admin_table ">
                  <thead>
                    <tr>
                      <th scope="col">
                      <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="customCheck1" name="allCheck" onChange={this.handleCheckBox} checked = {select_all}/>
                      <label className="custom-control-label" htmlFor="customCheck1"></label>
                      </div>
                      </th>
                      {/* <th scope="col">SL No.</th> */}
                      <th scope="col" onClick={this.onSorting.bind(this,"question", order_by) } > Question <img 
                          src={images.tableTopArrow}
                          className={"up-arrow"}
                          style={{opacity:(filter_by === "question" && order_by === "ASC")? "1" : "0.2"}}
                          alt=""
                        />
                        <img 
                          src={images.tableBottomArrow}
                          className={"down-arrow"}
                          style={{opacity:(filter_by === "question" && order_by === "DESC" )? "1" : "0.2"}}
                          alt=""
                        />
                      </th>

                      <th scope="col" onClick={ this.onSorting.bind(this,"position", order_by) } > Position <img 
                          src= {images.tableTopArrow}
                          className={"up-arrow"}
                          style={{opacity:(filter_by === "position" && order_by === "ASC")? "1" : "0.2"}}
                          alt=""
                        />
                        <img 
                          src={images.tableBottomArrow}
                          className={"down-arrow"}
                          style={{opacity:(filter_by === "position" && order_by === "DESC" )? "1" : "0.2"}}
                          alt=""
                        />
                      </th>

                      <th scope="col" onClick={ this.onSorting.bind(this,"is_active", order_by) } > Status <img 
                          src= {images.tableTopArrow}
                          className={"up-arrow"}
                          style={{opacity:(filter_by === "is_active" && order_by === "ASC")? "1" : "0.2"}}
                          alt=""
                        />
                        <img 
                          src={images.tableBottomArrow}
                          className={"down-arrow"}
                          style={{opacity:(filter_by === "is_active" && order_by === "DESC" )? "1" : "0.2"}}
                          alt=""
                        />
                      </th>

                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {question_list.map((obj,index) => {
                    count = (index+1)+((page-1)*limit);
                    return (
                      <tr key={index+1}>
                        <td className="text-left">
                          { (!obj.is_deleted) ?
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id={obj.id} key={index+1} name={obj.id} checked={this.state.checkedItems.get(obj.id)} onChange={this.handleCheckBox}/>
                              <label className="custom-control-label" htmlFor={obj.id}></label>
                            </div> :
                            <Link to="#"> 
                              <img src={images.refreshIcon} alt="" id = {obj.id} onClick = { this.openModal.bind(this,"reactivated") }/>
                            </Link>
                          }  
                        </td>
                        {/* <td>{(index+1)+((page-1)*limit)}</td> */}
                        <td> {obj.question}</td>
                        <td className = "faq_position">  
                          <input 
                            id = {obj.id} 
                            type = "number" 
                            name = "position"
                            value = { obj.position }
                            min = "1"
                            style = {{width: "60px",textAlign:'center'}}
                            onChange = { this.onPositionChange.bind(this, index) }
                            onBlur = { this.onPositionBlur.bind(this, index) }
                          />
                        </td>
                        <td>  
                            <label className="switch small">
                              <input type="checkbox" onChange = {() => this.statusChange(obj) } checked={(obj.is_active)? true : false}/>
                              <span className="slider round big_slider small"></span> 
                            </label>
                        </td>
                        <td>
                          <ul className="list-unstyled d-flex p-0 m-0 space-bw">
                            <li>
                              { (!obj.is_deleted)?
                                <Link to= {{ pathname: "/admin/faq/edit-faq",id: obj.id }}>
                                  <img src={images.editIcon} alt=""/> 
                                </Link> : 
                                <Link to="#">
                                  <img src={images.refreshIcon} alt="" id = {obj.id} onClick = { this.openModal.bind(this,"reactivated") }/>
                                </Link>
                              }
                            </li>
                            <li>
                              <Link to="#">
                                <img 
                                  src={images.deleteIcon}
                                  id = {obj.id}
                                  onClick = { (!obj.is_deleted)? this.openModal.bind(this,"deleted") : this.openModal.bind(this,"hard-deleted") } 
                                />
                              </Link>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </form>	
      </div>
      <div className="col-md-12 m-mb-25" ref={ (divElement) => { this.footerDivElement = divElement } }>
        <div className="row align-center mb-4 admin-bottom-part m-m-0">
          <div className="col-md-5 pl-0 col-6 m-p-0">
            <div className="col  col-md-10 col-xl-7 m-p-0">
              <Link 
                to ="#"
                className="btn common_btn big_btn pink_btn m-0"
                role="button"
                onClick = { this.openModal.bind(this,"deleted") } 
                style={{ cursor:(bulk_ids.length > 0 || select_all)?"pointer":"not-allowed" }}
              >
                Delete
              </Link>
            </div>
          </div>
          <div className="ml-auto col-md-7 align-center d-flex pagination_section col-6 m-m-0 m-p-0">
            { (search.length > 0) && <label className="paginationText p-0 m-0 m-sm-none">Showing Results {question_list.length} out of {total_faq}</label>}
            <nav aria-label="Page navigation example" className="pl-4 m-p-0">
              <Pagination
                activePage={page}
                itemsCountPerPage={limit}
                totalItemsCount={total_faq? total_faq:0}
                pageRangeDisplayed={pageRangeDisplayed}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination m-0 my_pagination"
                onChange={this.onPageChange}
                nextPageText={<img src={images.arrow} className="right-arrow" />}
                prevPageText={<img src={images.arrow} className="left-arrow" />}
                hideFirstLastPages= {true}
              />
            </nav>
          </div>
        </div>
      </div>
      </div>
      <DeleteModal 
        modalIsOpen = { modalIsOpen }
        closeModal = { this.closeModal }
        onAction = { (modal_for === "DEL_FAQ") ? this.onDeleteFaq : (modal_for === "HARD_DEL_FAQ")? this.onHardDeleteFaq : this.onReactivateFaq }
        modalFor = { modal_for } 
      />	
      <Loader isLoading={isLoading}/>
    </div>
    );
  }
}
