import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as _ from "lodash";
import Loader from '../components/Loader/Loader.jsx';
import { errorToast, successToast } from '../components/Toast/Toast.js';
import { addCountry, updateCountry, getCountryDetails } from '../Actions/countries.js';

const AddCountry = ({showModal = true, countryId = "", closeModal, onResetListing}) => {
    const [loading, setLoading] = useState(false);
    const [countryName, setCountryName] = useState("");
    const [countryCode, setCountryCode] = useState("");

    useEffect(()=>{
        if(countryId){
            setLoading(true);
            getCountryDetails(countryId).then((response)=>{
                const { data } = response.data.success;
                if(!_.isEmpty(data)){
                    const { country_code, name } = data;
                    setCountryName(name);
                    setCountryCode(country_code);
                }
            }).catch((error)=>{
                if (error.response) {
                    const { message } = error.response.data.error;
                    errorToast(message);
                } 
            }).finally(()=>{
                setLoading(false);
            });
        }

        return () => {
            setCountryName("");
            setCountryCode("");
        }
    }, [countryId]);

    const onSubmit = e => {
        if(countryName && countryCode){
            e.preventDefault();
            const params = {
                name: countryName.trim(),
                country_code: countryCode.trim(),
            };

            if(countryId){
                update(params, countryId);
            } else {
                add(params);
            }
        }
    }

    const add = _.debounce((params) =>{
        addCountry(params).then((response)=>{
            const { message } = response.data.success;
            if(message){
                successToast(message);
            }
            onResetListing();
        }).catch((error)=>{
            if (error.response) {
                const errorResult = error.response.data.error;
                errorToast(errorResult.message);
            } 
        }).finally(()=>{
            closeModal();
        });
    }, 300);

    const update =  _.debounce((params, id) =>{
        updateCountry(params, id).then((response)=>{
            const { message } = response.data.success;
            if(message){
                successToast(message);
            }
            onResetListing();
        }).catch((error)=>{
            if (error.response) {
                const { message } = error.response.data.error;
                errorToast(message);
            } 
        }).finally(()=>{
            closeModal();
        });
    });

    return ( 
        <>
            <Modal
                isOpen={showModal}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <form 
                    className="col-md-12 p-0 modal_from"
                    onSubmit={(e) => onSubmit(e)}
                >
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="form-group col-md-12 form-custom">
                                <div className="row mb-3">
                                    <label htmlFor="inputEmail4" className="col left_label m-0 col-4">Country Name</label>
                                </div>
                                <input
                                    className="form-control" 
                                    id="exampleFormControlTextarea1" 
                                    onChange={ (e) => { setCountryName(e.target.value); }}
                                    name="countryName" 
                                    value={countryName}
                                    placeholder="Enter Country Name"
                                    autoFocus
                                    required
                                />
                            </div>
                        </div>
                    </div>   

                     <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="form-group col-md-12 form-custom">
                                <div className="row mb-3">
                                    <label htmlFor="inputEmail4" className="col left_label m-0 col-4">Country Code</label>
                                </div>
                                <input
                                    className="form-control" 
                                    id="exampleFormControlTextarea1" 
                                    onChange={ (e) => { setCountryCode(e.target.value) }}
                                    name="countryCode" 
                                    value={countryCode}
                                    placeholder="Enter Country Code"
                                />
                            </div>
                        </div>
                    </div>    

                    <div className="row">
                        <div className="col-md-6">
                            <button
                                className="btn blue_btn common_btn big_btn" 
                                role="button"
                                type="submit"
                            >
                                {(countryId)? "Update" : "Add"}
                            </button>
                        </div>

                        <div className="col-md-6">
                            <button
                                className="btn gray_btn common_btn big_btn"
                                role="button"
                                onClick={(e) => { closeModal(); }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>
            <Loader isLoading={loading}/>
        </> 
    );
}

export default React.memo(AddCountry);